import { makeStyles } from '@material-ui/core/styles';


const useRequestItegrationFormStyles = makeStyles((theme) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: '11000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    },
    requestFromBox: {
        height: '59.5rem',
        width: '55.5rem',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0.4rem 1rem 0 rgba(76,141,235,0.1)',
        padding: '3rem',
        display: 'flex',
        flexDirection: 'column'
    },
    requestFromBoxSuccess: {
        width: '55.5rem',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0.4rem 1rem 0 rgba(76,141,235,0.1)',
    },
    requestFromHeader: {
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight: '900',
        fontSize: '2.4rem',
        letterSpacing: '-0.018rem',
        lineHeight: '3rem',
    },
    requestFromSubTitle: {
        marginTop: '1rem',
        opacity: 0.4,
        color: '#303159',
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '1.6rem',
        letterSpacing: '-0.012rem',
        lineHeight: '2rem',
    },
    requestFromInputBox: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2.9rem'
    },
    requestFromInputLabel: {
        opacity: 0.6,
        color: '#313E6F',
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '1.3rem',
        letterSpacing: '-0.13px',
        lineHeight: '1.6rem',
        marginBottom: '1rem',
    },
    requestFromInput: {
        height: '4.4rem',
        width: '50rem',
        border: '1px solid #C6CBDF',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        outline: 'none',
        paddingLeft: '1.5rem',
        color: '#2C2E4C',
        fontFamily: 'Mulish',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        letterSpacing: '-0.057rem',
        lineHeight: '2rem',
    },
    requestFromFooter: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '2rem',
        alignItems: 'center',
    },
    cancelBtn: {
        marginRight: '2rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
    },
    sendRequestBtn: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
    },
    requestFromInputTextArea: {
        minHeight: '8rem',
        paddingTop: '1rem',
    },
    successBanner: {
        width: '100%',
        height: '10rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight: '900',
        fontSize: '2.4rem',
        letterSpacing: '-0.018rem',
        lineHeight: '3rem',
    },
    successFooter: {
        width: '100%',
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '3rem',
    }
}))


export default useRequestItegrationFormStyles;