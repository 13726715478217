import { useState,useEffect } from "react";

import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';

import JsonViewer from "./JsonViewer";

import useStyles from "./style.component";

import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import LoopRoundedIcon from '@material-ui/icons/LoopRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';

import service from "../../../lib/apifuseGraphQL"

import Loader from "../../Loader";


const APIFUSE_HTTP_LINK = new HttpLink({ uri: `https://${process.env.REACT_APP_PUBLIC_API_HOST}/app/apifuse-api/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = sessionStorage.getItem('marketplace_access_token');

    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });

    return forward(operation);
})


const apifuseclient = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(authMiddleware, APIFUSE_HTTP_LINK)
});



export default function LogViewer({onCancel,log={},onViewWorkflow}){

    console.log(log)

    const classes =  useStyles();

    let [currentTab,setCurrentTab]=useState('input');


    let [initialLoad,setInitialLoad]=useState(false);
    let [loadingTasks,setLoadingTasks]=useState(false);
    let [currentStep,setCurrentStep]=useState(false);
    let [currentInput,setCurrentInput]=useState(null);
    let [currentOutput,setCurrentOutput]=useState(null);


    let [inputLoading,setInputLoading]=useState(false);

    let [steps,setSteps]=useState([]);

    useEffect(()=>{
        if(!initialLoad){
            loadTasksByTriggerId();
            setInitialLoad(true)
        }
    },[initialLoad])

    const downloadFile = async (filename,data) => {
        
        const fileName = filename;
        const json = JSON.stringify(data,null,2);
        const blob = new Blob([json],{type:'application/json'});
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }


    function getTransactionInputOuput(transactionId) {
        setInputLoading(true)
        let query = gql`{
            getInputOuput(transactionId:"${transactionId}"){
                status
            data{
                input
                output
            }
          }
        }`;
        apifuseclient.query({
            query,
            fetchPolicy:'no-cache',
            variables:{}
        }).then(res => {
            if( res.data.getInputOuput && res.data.getInputOuput.data){
                if(res.data.getInputOuput.data.input)
                setCurrentInput(JSON.parse(res.data.getInputOuput.data.input))
                if(res.data.getInputOuput.data.output)
                setCurrentOutput(JSON.parse(res.data.getInputOuput.data.output))
            }
            
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setInputLoading(false)
        });
    }
 

    function loadTasksByTriggerId() {
        setLoadingTasks(true)
        let t =log.triggerId;
        let from = parseInt(log.startedAt || 0);
        let query = gql`{
            getAllTasks(workflowId:"${log.workflowId}",triggerId:"${t}",after:${(new Date(from).getTime()) - 60000}){
            data{
                triggeredAt
              triggerId
              wokflowVersion
              userId
              orgId
              status
              steps{
                stepId
                status
                application
                triggerOrActionName
                triggerId
                startedAt
                endedAt
                correlationId
                parentCorrelationId
                transactionId
                executionTime
              }
            }
          }
        }`;
        apifuseclient.query({
            query,
            fetchPolicy:'no-cache',
            variables:{}
        }).then(res => {
            console.log(res.data.getAllTasks.data)

            if(res.data.getAllTasks && res.data.getAllTasks.data[0] && res.data.getAllTasks.data[0].steps){
                setSteps(res.data.getAllTasks.data[0].steps.map((s,idx)=>{
                    let status='success';
                    if(s.status!=='COMPLETE'){
                        status='failed'
                    }
                    return {
                        name:'Step '+(idx+1)+": "+s.application,
                        desc:s.triggerOrActionName,
                        status,
                        transactionId:s.transactionId
                    }
                }))
            }

        }).catch(err => {
            console.log(err);
        }).finally(() => {
           
            setLoadingTasks(false)
        });
    }


    // let steps = [
    //     {
    //         name:'Step 1',
    //         desc:'Some desc',
    //         status:'success'
    //     },
    //     {
    //         name:'Step 2',
    //         desc:'Some desc adfadfa ikm,asdf;  wperwersdf',
    //         status:'failed'
    //     },
    // ]

    let logDateTime = '';

    if(log.startedAt){
        let d = new Date();
        d.setTime(log.startedAt);

        let year = d.getFullYear();
        let month = "00"+(1+d.getMonth());
        month = month.substr(-2);
        let day = "00"+d.getDate();
        day = day.substr(-2);

        let min = "00"+(d.getMinutes());
        min = min.substr(-2);

        let hour = d.getHours();
        if(hour >12){
            min = min +" PM"
        }else{
            min = min +" AM"
        }
        hour = hour%12;

        logDateTime = `${year}-${month}-${day} at ${hour}:${min}`
    }

    function generateJsonFromSchema(schema) {

        let output = null;
    
        if (!schema) {
            return null;
        }
    
        if (schema.type === 'OBJECT') {
            output = {};
            let keys = Object.keys(schema.value);
            keys.map(key => {
                output[key] = generateJsonFromSchema(schema.value[key]);
            })
        } else if (schema.type === 'LIST') {
            output = [];
            if (schema.value.value && schema.value.value.length > 0) {
                for (var i = 0; i < schema.value.value.length; i++) {
                    let temp = {
                        name: '',
                        displayName: '',
                        type: schema.value.itemType,
                        value: schema.value.value[i]
                    }
                    if (schema.value.value) {
                        output.push(generateJsonFromSchema(temp))
                    }
                }
            }
        } else if (schema.type === 'FILE') {
            output = '';
        } else if (schema.type === 'DATETIME') {
            output = schema.value;
        } else if (schema.type === 'DATE') {
            output = schema.value;
        } else if (schema.type === 'BOOLEAN') {
            output = schema.value;
        } else if (schema.type === 'INTEGER') {
            output = schema.value;
        } else if (schema.type === 'NUMBER') {
            output = schema.value;
        } else if (schema.type === 'TEXT') {
            output = schema.value;
        } else {
            if(schema.value === undefined && schema instanceof Object) {
                let keys = Object.keys(schema);
                console.log(keys)
                output = {};
                if(schema[keys[0]] && schema[keys[0]].type != undefined) {
                    keys.map(key => {
                        output[key] = generateJsonFromSchema(schema[key]);
                    })
                } else {
                    keys.map(key => {
                        output[key] = schema[key];
                    })
                }
            } 
            else {
                output = schema.value;
            }
        }
    
    
        return output;
    
    }

    let jsonViewerData={}

    if(currentTab==='input'){
        if(currentInput){
            jsonViewerData = currentInput
        }
    }
    if(currentTab==='output'){
        if(currentOutput){
            jsonViewerData = generateJsonFromSchema(currentOutput)
        }
    }


    return <div className={classes.root}>
            <div className={classes.backBtn} onClick={()=>{
                onCancel()
            }}>
                <ArrowBackRoundedIcon style={{color:'#595A86',fontSize:'22px'}}></ArrowBackRoundedIcon>
                <span className={classes.backBtnText}>Back to list</span>
            </div>

            <div className={classes.box}>
                <div className={classes.header}>
                    {log.status.toLowerCase()!=='success' && log.status.toLowerCase()!=='inprogress' && <div className={classes.headerStatus}>
                        <CancelRoundedIcon style={{color:'#D01030',fontSize:'26px'}}></CancelRoundedIcon>
                        <span className={classes.headerStatusFail}>Failed</span>
                    </div>}
                    {log.status.toLowerCase()==='inprogress' && <div className={classes.statusIndicator}>
                        <span className={classes.successIndicator}>
                            <SyncRoundedIcon style={{color:'#ffac02',fontSize:'26px'}}></SyncRoundedIcon>
                        </span>
                        <span className={classes.headerStatusInProgress}>In Progress</span>
                    </div>}
                    {log.status.toLowerCase() ==='success' && <div className={classes.headerStatus}>
                        <CheckCircleRoundedIcon style={{color:'#20BB86',fontSize:'26px'}}></CheckCircleRoundedIcon>
                        <span className={classes.headerStatusSuccess}>Success</span>
                    </div>}
                    <div className={classes.workflowName}>
                        <span className={classes.workflowNameText}>{log.name}</span>
                        <LaunchRoundedIcon style={{color:'#303159',fontSize:'22px',cursor:'pointer'}} onClick={()=>{
                            onViewWorkflow && onViewWorkflow(log.workflowId)
                        }}></LaunchRoundedIcon>
                    </div>
                    <div className={classes.logDateTime}>
                        <TodayRoundedIcon  style={{color:'#303159',fontSize:'25px'}}></TodayRoundedIcon>
                        <span className={classes.logDateTimeText}>{logDateTime}</span>
                    </div>
                    <div className={classes.retriggerBtn} onClick={()=>{
                        if(window.confirm("Are you sure? Retrigger may duplicate the data in target system")){

                        }
                    }}>
                        <LoopRoundedIcon style={{color:'#FFFFFF',fontSize:'25px'}}></LoopRoundedIcon>
                    </div>
                </div>
                <div className={classes.body}>
                    <div className={classes.steps}>
                        {!loadingTasks && steps.map(step=>{
                            return <StepItem step={step} classes={classes}
                            onRetrigger={()=>{
                                if(window.confirm("Are you sure? Retrigger may duplicate the data in target system")){

                                }
                            }}
                            onStepClick={()=>{
                                setCurrentStep(step);
                                getTransactionInputOuput(step.transactionId)
                            }}></StepItem>
                        })}
                        {loadingTasks && <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <Loader style={{color:'#4166EA'}}></Loader>
                            </div>}
                    </div>
                    <div className={classes.inputOutput}>
                        <div className={classes.inputOuputHeader}>
                            <div className={classes.inputOuputBtns}>
                                <span className={classes.inputBtn} 
                                style={{backgroundColor:(currentTab==='input'?'#4166EA':'#FFFFFF'),color:(currentTab==='input'?'#FFFFFF':'#595A86')}}
                                onClick={()=>{
                                    setCurrentTab('input')
                                }}>Input</span>
                                <span className={classes.outputBtn}
                                style={{backgroundColor:(currentTab==='output'?'#4166EA':'#FFFFFF'),color:(currentTab==='output'?'#FFFFFF':'#595A86')}}
                                onClick={()=>{
                                    setCurrentTab('output')
                                }}>Output</span>
                            </div>
                            <CloudDownloadRoundedIcon style={{color:'#595A86',fontSize:'25px',cursor:'pointer'}} onClick={()=>{
                                let data=currentInput;
                                if(currentTab==='output'){
                                    data=currentOutput
                                }
                                downloadFile(currentTab,data)
                            }}></CloudDownloadRoundedIcon>
                        </div>
                        <div className={classes.inputOuputBody}>
                          {inputLoading && <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <Loader style={{color:'#4166EA'}}></Loader>
                            </div>}
                           {!inputLoading && <JsonViewer src={jsonViewerData||{}} style={{ fontSize: '1.7rem' }} iconStyle="square" name={false}
                                displayObjectSize={false} displayDataTypes={false}></JsonViewer>}
                        </div>
                    </div>
                </div>

            </div>
    </div>
}


function StepItem({classes,step={},onStepClick,onRetrigger}){

    

    return <>
        {step.status==='success' && <div className={classes.step}>
            <div className={classes.stepBody} onClick={()=>{
                onStepClick && onStepClick();
            }}>
                <div className={classes.stepName}>
                    <CheckCircleOutlineRoundedIcon style={{color:'#20BB86',fontSize:'22px'}}></CheckCircleOutlineRoundedIcon>
                    <span className={classes.stepNameSuccess}>{step.name}</span>
                </div>
                <div className={classes.stepDec}>{step.desc}</div>
            </div>
            <span className={classes.stepRetrigger} onClick={onRetrigger}>
                <LoopRoundedIcon style={{color:'#595A86',fontSize:'22px'}}></LoopRoundedIcon>
            </span>
        </div>}
        {step.status!=='success' && <div className={classes.stepError}>
            <div className={classes.stepBody}  onClick={()=>{
                onStepClick && onStepClick();
            }}>
                <div className={classes.stepName}>
                    <WarningRoundedIcon style={{color:'#FFFFFF',fontSize:'22px'}}></WarningRoundedIcon>
                   
                    <span className={classes.stepNameSuccess} style={{color:'#FFFFFF'}}>{step.name}</span>
                </div>
                <div className={classes.stepDec} style={{color:'#FFFFFF'}}>{step.desc}</div>
            </div>
            <span className={classes.stepRetrigger} onClick={onRetrigger}>
                <LoopRoundedIcon style={{color:'#FFFFFF',fontSize:'22px'}}></LoopRoundedIcon>
            </span>
        </div>}
    </>
}
