import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link, useParams } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';

import TierNotificationPopup from "../../components/TierNotificationPopup";
import RequestThisIntegrationForm from "../../components/RequestThisIntegrationForm"
import RequestItegrationForm from "../../components/RequestItegrationForm";
import MarketplaceWorkflowInstallScreen from "../../components/MarketplaceWorkflowInstallScreen"
import Loader from "../../components/Loader";
import useGetCustomerAuthContext from "../../hooks/useGetCustomerAuthContext"
import useOutsideClickAlert from "../../hooks/useOutsideClickAlert"
import Overlay from "../../components/Overlay";
import UninstallListing from "../../components/UninstallListing";
import tileLogoPng from "../../images/integration-tile-logo.png"
import useCustomerPermissions from '../../hooks/useCustomerPermissions';

import { shouldAllowNewIntegration } from "../../util"

const HTTP_LINK = new HttpLink({ uri: `https://${process.env.REACT_APP_PUBLIC_API_HOST}/app/apifuse-api/graphql` });

const AUTH_MIDDLEWARE = new ApolloLink((operation, forward) => {
    const token = sessionStorage.getItem('marketplace_access_token')
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });
    return forward(operation);
})


const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(AUTH_MIDDLEWARE, HTTP_LINK)
});


function doMutate(mutation, variables) {
    return client
        .mutate({
            mutation: mutation,
            variables: variables || undefined
        });
}

function getMarketplaceForSubdomain(subdomain, customDomain) {
    const getMarketplaceQuery = gql`
    {
        marketplacePublicBySubdomain(subdomain:"${subdomain || ''}",customDomain:"${customDomain || ''}"){
            organizationId
           categories
           plans{
             name
             connectors
             userActions{
                canViewIntegrations
                canCreateEditTemplateIntegrations
                canCreateIntegrationWithBuilder
             }
             integrationLimits{
                limitBy
                maximumIntegrations
                maximumAPICalls
             }
           }
           subdomain
           preview{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
            config{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
         }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}


function getMarketplaceAndListing(orgId, listingId) {
    const getMarketplaceQuery = gql`
    {
        marketplacePublic(orgId:"${orgId}"){
            organizationId
           categories
           plans{
            name
            connectors
            userActions{
               canViewIntegrations
               canCreateEditTemplateIntegrations
               canCreateIntegrationWithBuilder
            }
            tierPopupConfig{
               title
               infoText
               font
               actionCta
             }
            integrationLimits{
               limitBy
               maximumIntegrations
               maximumAPICalls
            }
           }
           subdomain
           preview{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
           }
            config{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
           }
         }
         getListingById(listingId:"${listingId}"){
            listingId
            name
            logo
            category
            type
            organizationId
            slug
            status
            dedicatedLandingPage
            requestInfoText
            requestButtonText
            requestTitle
            showComingSoonLabel
            settings{
                description
              screenshots
              videos
              sourceWorkflow
              description
              installCTA
              monetizationPlan
              features
              pricing
              customize
              externalLink
              authScreens{
                appId
                appName
                appLogo
                title
                description
                buttonCTA
                fieldId
                stepId
                type
                templateId
                fieldItems{
                    name
                    displayName
                }
              }
            }
          }
          getTemplateForListing(listingId:"${listingId}"){
            code
            templateId
          }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}




const useStyles = makeStyles((theme) => ({
    loaderWrap: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F3F7FD'

    },
    header: {
        width: '100%',
        height: '15.7rem',
        backgroundColor: '#4166EA',
        position: 'relative',
    },
    backtoList: {
        marginLeft: '3.5rem',
        marginTop: '3rem',
        color: '#FFFFFF',
        fontFamily: 'Mulish',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        letterSpacing: '-0.011rem',
        lineHeight: '1.7rem',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',

    },
    backtoListText: {
        marginLeft: '1rem',

    },
    backtoListArrow: {
        fontSize: '2rem',
    },
    titleBox: {
        marginLeft: "27rem",
        marginTop: '3rem',
        marginBottom: '3.2rem',
        display: 'flex',
        flexDirection: 'column'
    },
    titlePrimary: {
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: '900',
        fontSize: '2.4rem',
        letterSpacing: '-0.018rem',
        lineHeight: '3rem',
    },
    titleSecondary: {
        color: '#FFFFFF',
        fontFamily: 'Mulish',
        fontSize: '1.4rem',
        fontWeight: 'bold',
        letterSpacing: '-0.014rem',
        lineHeight: '1.8rem',
    },
    body: {
        width: '100%',
        flex: 1,
        overflow: 'auto'
    },
    sidebar: {

        width: '21.2rem',
        position: 'absolute',
        zIndex: 10,
        top: '7.2rem',
        left: '3.5rem'
    },
    sidebarContainer: {

        width: '100%',

    },
    logobox: {
        overflow: 'hidden',
        height: '21.2rem',
        width: '21.2rem',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
    },
    installBtn: {
        marginTop: '1.2rem',
        height: '4.4rem',
        width: '21.3rem',
        borderRadius: '0.4rem',
        backgroundColor: '#20BB86',
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: 'bold',
        fontSize: '1.4rem',
        letterSpacing: '-0.014rem',
        lineHeight: '1.8rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    linkBox: {
        marginTop: '2.6rem',
        height: '7.7rem',
        width: '21.3rem',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0.4rem 1rem 0 rgba(76,141,235,0.1)',
        display: 'flex',
        flexDirection: 'column',
        padding: '1.7rem'
    },
    linkHeader: {
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '1.6rem',
        letterSpacing: '-0.05rem',
        lineHeight: '2rem',
    },
    link: {
        marginTop: '1rem',
        color: '#4166EA',
        fontFamily: "Mulish",
        fontWeight: '700',
        fontSize: '1.2rem',
        letterSpacing: '-0.043rem',
        lineHeight: '1.5rem',
    },
    content: {
        marginTop: '2.4rem',
        marginLeft: '27rem',
        overflow: 'auto',
        flex: 1
    },
    descriptionBoxs: {
        minHeight: '11.8rem',
        width: '80rem',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0.4rem 1rem 0 rgba(76, 141, 235, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        padding: '2.4rem',
        marginBottom: '3.2rem',

    },
    descriptionHeader: {
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '1.6rem',
        letterSpacing: '-0.5px',
        lineHeight: '2rem',
    },
    descriptionText: {
        marginTop: '1.3rem',
        opacity: 0.7,
        color: '#303159',
        fontFamily: 'Mulish',
        fontSize: '1.6rem',
        letterSpacing: '-0.5px',
        lineHeight: '2rem',

    },
    screehshots: {
        marginBottom: '4rem',
        width: '80rem',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0.4rem 1rem 0 rgba(76, 141, 235, 0.1)',
        display: 'flex',
        flexDirection: 'column',
    },
    screehshotsHeader: {
        height: '6.4rem',
        width: '100%',
        borderRadius: '0.4rem',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '0.1rem solid #C6CBE0'
    },
    screehshotsHeaderText: {
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '1.6rem',
        letterSpacing: '-0.5px',
        lineHeight: '2rem',
        marginLeft: '2.7rem',
    },
    screehshotsContent: {
        height: '50.1rem',
        width: '80rem',
        backgroundColor: '#F9FAFF'
    },
    screehshotsSlider: {
        marginTop: '2.4rem',
        marginLeft: '3.2rem',
        marginRight: '3.2rem',
        display: 'flex',
        alignItems: 'center',
    },
    screehshotsLeftSlider: {
        height: '5.6rem',
        width: '5.6rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        boxShadow: '0 0.4rem 1rem 0 rgba(76,141,235,0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'rotate(180deg)',
        cursor: 'pointer',
        "&:active": {
            opacity: '0.5'
        },
        '&:hover': {
            boxShadow: '0 0.2rem 1rem 0 rgba(76,141,235,0.6)',
        }
    },
    screehshotsRightSlider: {
        height: '5.6rem',
        width: '5.6rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        boxShadow: '0 0.4rem 1rem 0 rgba(76,141,235,0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        "&:active": {
            opacity: '0.5'
        },
        '&:hover': {
            boxShadow: '0 0.2rem 1rem 0 rgba(76,141,235,0.6)',
        }
    },
    screehshotsView: {
        height: '41.8rem',
        width: '57.4rem',
        marginLeft: '2.5rem',
        marginRight: '2.5rem'
    },
    screehshotsPosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2.5rem'
    },
    dot: {
        height: '0.6rem',
        width: '0.6rem',
        backgroundColor: '#D8D8D8',
        borderRadius: '50%',
        marginRight: '0.1rem'
    },
    dotActive: {
        backgroundColor: '#3E61EE'
    }
}))


export default function ListingView({listing,listingId,mode,marketplace,orgId,templates}) {


    const customerAuthContext = useGetCustomerAuthContext();


    const classes = useStyles();

    const [initialLoad, setInitialLoad] = useState(false);


    const [mediaPosition, setMediaPosition] = useState(0);
    const [videoPosition, setVideoPosition] = useState(0);

    const [workflowTemplate, setWorkflowTemplate] = useState(templates);


    const { userActions, integrationLimits,tierPopupConfig } = useCustomerPermissions(marketplace)

    let mediaDisplay = [];

    if (listing && listing.settings && listing.settings.screenshots && listing.settings.videos) {
        listing.settings.screenshots.map(s => {
            mediaDisplay.push({ type: 'image', url: s });
        })
    }

    const [queryJWT, setQueryJWT] = useState(null);

    // if (listing && listing.settings && listing.settings.videos) {
    //     listing.settings.videos.map(s => {
    //         mediaDisplay.push({ type: 'video', url: s });
    //     })
    // }

    const [showAuthScreens, setShowAuthScreens] = useState(false);

    const [showIntegrationRequestForm, setShowIntegrationRequestForm] = useState(false)

    const [showTierNotification, setShowTierNotification] = useState(false);


    const [customerUsageAPIs, setCustomerUsageAPIs] = useState([])
    const [customerUsageIntegrations, setCustomerUsageIntegrations] = useState(0)

    const [workflows, setWorkflows] = useState([])
    const [showUninstallPopup, setShowUninstallPopup] = useState(false);



    useEffect(() => {
        if (customerAuthContext.isAuthenticated && !initialLoad) {
            const getCustomerUsageQuery = gql`
                {
                    getCustomerUsage{
                        status,
                        message,
                        data{
                            count
                            workflowId
                        }
                    }
                    getAllWorkflows(is_published:true){
                        workflow_id
                        listingId
                    }
                }
            `;
            client
                .query({
                    query: getCustomerUsageQuery,
                    fetchPolicy: 'no-cache'
                }).then(res => {
                    setCustomerUsageAPIs(res.data.getCustomerUsage.data || [])
                    setCustomerUsageIntegrations(res.data.getAllWorkflows.length)
                    setWorkflows(res.data.getAllWorkflows);
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setInitialLoad(true)
                });
        }else{
            setInitialLoad(true)
        }
    }, [initialLoad])


    async function sendInstallEventToAnalytics() {
        let listingName = '';
        if (listing && listing.name) {
            listingName = listing.name;
        }
        const updateString = `
        mutation addMarketplaceListingActivation{
            addMarketplaceListingActivation(listingId:"${listingId}",listingName:"${listingName}"){ 
                code,
                message
            }}
    `;

        const addMarketplaceVisit = gql(updateString);

        client
            .mutate({
                mutation: addMarketplaceVisit
            }).then(res => {
                // console.log(res.data)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                console.log("install event sent")
            }
            )
    }


    function postMessageToParentWindow(message) {
        let targetWindow = window.parent
        // console.log(targetWindow);
        if (targetWindow) {
            targetWindow.postMessage(message, "*")
        }
    }


    function onInstall(data) {
        setWorkflows([...workflows, ...data]);
        let totalCount = workflows.length + data.length;
        setCustomerUsageIntegrations(totalCount);
    }

    function onUninstall(data) {

        let newList = workflows.filter(w => !data.workflowIds.includes(w.workflow_id));

        setWorkflows(newList);
        setCustomerUsageIntegrations(newList.length)
    }


    let alreadyInstalled = false;
    let installedWorkflows = [];

    if (workflows && workflows.length > 0) {
        installedWorkflows = workflows.filter(w => listing.listingId === w.listingId);
        if (installedWorkflows && installedWorkflows.length > 0) {
            alreadyInstalled = true;
        }
    }

    return <>
        {showUninstallPopup && alreadyInstalled && <UninstallListing
            onUninstall={(data) => {
                window.parent?.postMessage(JSON.stringify({"id": "listing_uninstall_complete",listingId,listingName:listing.name}),"*")
                setShowUninstallPopup(false);
                onUninstall(data)
            }}
            installedWorkflows={installedWorkflows}
            onClose={() => setShowUninstallPopup(false)}>
        </UninstallListing>}
        {showTierNotification && <TierNotificationPopup notificationConfig={tierPopupConfig} onClose={() => { setShowTierNotification(false) }}></TierNotificationPopup>}
        {!initialLoad && <div className={classes.loaderWrap}>
            <Loader width="3px" size={"19px"}></Loader>
        </div>}
        {showIntegrationRequestForm && customerAuthContext.isAuthenticated && <RequestThisIntegrationForm listing={listing} onClose={() => setShowIntegrationRequestForm(false)}></RequestThisIntegrationForm>}
        {showIntegrationRequestForm && !customerAuthContext.isAuthenticated &&
            <RequestItegrationForm listing={listing} orgId={orgId} onCancel={() => setShowIntegrationRequestForm(false)} requestedIntegration={listing.name || ''}></RequestItegrationForm>}
        {initialLoad && marketplace && listing && <div className={classes.container}>
            <div className={classes.header} style={{ backgroundColor: marketplace.config.header.backgroundColor || undefined }}>
                {listing && !listing.dedicatedLandingPage && <Link to={`/?mode=${(mode ? mode : 'live')}&jwt=${queryJWT}`} style={{ textDecoration: 'none' }}><div className={classes.backtoList} style={{ textDecoration: 'none' }}>
                    <ArrowBackIcon className={classes.backtoListArrow} style={{ textDecoration: 'none', color: marketplace.config.header.fontColor }}></ArrowBackIcon>
                    <span className={classes.backtoListText} style={{ textDecoration: 'none', color: marketplace.config.header.fontColor, fontFamily: marketplace.config.font }}>Back to the list</span>
                </div></Link>}
                <div className={classes.titleBox}>
                    <span className={classes.titlePrimary} style={{ color: marketplace.config.header.fontColor, fontFamily: marketplace.config.font }}>{listing.name}</span>
                    <span className={classes.titleSecondary} style={{ color: marketplace.config.header.fontColor, fontFamily: marketplace.config.font }}>{listing.category}</span>
                </div>

                <div className={classes.sidebar}>
                    <div className={classes.sidebarContainer}>
                        <div className={classes.logobox}><img style={{ width: 'auto', height: '100%', borderRadius: '0.4rem' }} src={listing.logo || tileLogoPng}></img></div>

                        <div>
                            {listing.status === 'available' && customerAuthContext.isAuthenticated && !alreadyInstalled && <span className={classes.installBtn} onClick={() => {

                                let accessToken = sessionStorage.getItem("marketplace_access_token");
                                console.log(accessToken)
                                if (mode !== 'preview' && !accessToken) {
                                    postMessageToParentWindow(JSON.stringify({ id: 'user_login_required', targetUrl: window.location.pathname }))
                                } else {
                                    if (userActions.canCreateEditTemplateIntegrations && shouldAllowNewIntegration(integrationLimits, userActions, customerUsageAPIs, customerUsageIntegrations)) {
                                        sendInstallEventToAnalytics();
                                        setShowAuthScreens(true);
                                    } else {
                                        setShowTierNotification(true)
                                    }

                                }

                            }}>{(listing.settings && listing.settings.installCTA) || "Install"}</span>}
                            {listing.status === 'available' && customerAuthContext.isAuthenticated && alreadyInstalled && <span className={classes.installBtn} onClick={() => {

                                let accessToken = sessionStorage.getItem("marketplace_access_token");
                                console.log(accessToken)
                                if (mode !== 'preview' && !accessToken) {
                                    postMessageToParentWindow(JSON.stringify({ id: 'user_login_required', targetUrl: window.location.pathname }))
                                } else {
                                    setShowUninstallPopup(true);
                                }

                            }}>{"Uninstall"}</span>}
                            {(listing.status !== 'available' || !customerAuthContext.isAuthenticated) && <span className={classes.installBtn} onClick={() => { setShowIntegrationRequestForm(true) }}>
                                Request
                            </span>}
                        </div>
                        {listing.settings?.externalLink && <div className={classes.linkBox}>
                            <span className={classes.linkHeader}>Links</span>
                            <a href={listing.settings?.externalLink} target="_blank" className={classes.link}>{'>> Website'}</a>
                        </div>}
                    </div>
                </div>
            </div>
            <div className={classes.body}>
                <div className={classes.content}>
                    <div className={classes.descriptionBoxs}>
                        <span className={classes.descriptionHeader}>Description</span>
                        <span className={classes.descriptionText}>{listing.settings && listing.settings.description}</span>
                    </div>
                    {listing?.settings?.features && <div className={classes.descriptionBoxs}>
                        <span className={classes.descriptionHeader}>Features</span>
                        <span className={classes.descriptionText}>{listing.settings && listing.settings.features}</span>
                    </div>}
                    {listing?.settings?.pricing && <div className={classes.descriptionBoxs}>
                        <span className={classes.descriptionHeader}>Pricing</span>
                        <span className={classes.descriptionText}>{listing.settings && listing.settings.pricing}</span>
                    </div>}
                    {mediaDisplay && mediaDisplay.length > 0 &&
                        <div className={classes.screehshots}>
                            <div className={classes.screehshotsHeader}>
                                <span className={classes.screehshotsHeaderText}>Screenshots</span>
                            </div>
                            <div className={classes.screehshotsContent}>
                                <div className={classes.screehshotsSlider}>
                                    <div className={classes.screehshotsLeftSlider} onClick={() => {

                                        if (mediaPosition > 0) {
                                            let newPosition = mediaPosition - 1;
                                            setMediaPosition(newPosition)
                                        } else {
                                            setMediaPosition(0)
                                        }


                                    }}><ArrowForwardIosIcon></ArrowForwardIosIcon></div>
                                    {mediaDisplay && mediaDisplay.length > 0 && <div className={classes.screehshotsView}>
                                        {mediaDisplay[mediaPosition] && mediaDisplay[mediaPosition].type === "image" &&
                                            // eslint-disable-next-line jsx-a11y/alt-text
                                            <img style={{ width: '100%', height: '100%' }} src={mediaDisplay[mediaPosition].url}></img>
                                        }
                                        {mediaDisplay[mediaPosition] && mediaDisplay[mediaPosition].type === "video" &&
                                            <video style={{ width: '100%', height: '100%' }} controls>
                                                <source src={mediaDisplay[mediaPosition].url} />
                                            </video>
                                        }
                                    </div>}
                                    <div className={classes.screehshotsRightSlider} onClick={() => {
                                        if (mediaPosition < mediaDisplay.length - 1) {
                                            let newPosition = mediaPosition + 1;
                                            setMediaPosition(newPosition)
                                        }
                                    }}><ArrowForwardIosIcon></ArrowForwardIosIcon></div>
                                </div>
                                <div className={classes.screehshotsPosition}>
                                    {mediaDisplay.map((m, idx) => {
                                        return <span className={classes.dot + " " + (mediaPosition === idx ? classes.dotActive : "")}></span>;
                                    })}
                                </div>
                            </div>
                        </div>}

                    {listing && listing.settings && listing.settings.videos && listing.settings.videos.length > 0 && <div className={classes.screehshots}>
                        <div className={classes.screehshotsHeader}>
                            <span className={classes.screehshotsHeaderText}>Videos</span>
                        </div>
                        <div className={classes.screehshotsContent}>
                            <div className={classes.screehshotsSlider}>
                                <div className={classes.screehshotsLeftSlider} onClick={() => {

                                    if (videoPosition > 0) {
                                        let newPosition = videoPosition - 1;
                                        setVideoPosition(newPosition)
                                    } else {
                                        setVideoPosition(0)
                                    }


                                }}><ArrowForwardIosIcon></ArrowForwardIosIcon></div>
                                <div className={classes.screehshotsView}>
                                    {listing.settings.videos[videoPosition] &&
                                        <VideoPlayer key={listing.settings.videos[videoPosition]} mediaUrl={listing.settings.videos[videoPosition]}>
                                        </VideoPlayer>
                                    }
                                </div>
                                <div className={classes.screehshotsRightSlider} onClick={() => {
                                    if (videoPosition < listing.settings.videos.length - 1) {
                                        let newPosition = videoPosition + 1;
                                        setVideoPosition(newPosition)
                                    }
                                }}><ArrowForwardIosIcon></ArrowForwardIosIcon></div>
                            </div>
                            <div className={classes.screehshotsPosition}>
                                {listing.settings.videos.map((m, idx) => {
                                    return <span className={classes.dot + " " + (videoPosition === idx ? classes.dotActive : "")}></span>;
                                })}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
        }
        {showAuthScreens && <MarketplaceWorkflowInstallScreen template={workflowTemplate} listingId={listingId} listingName={listing.name} screens={listing.settings.authScreens.filter(s => {
            return s.appId !== customerAuthContext.orgConnector || s.type === 'custom';
        })} onClose={() => {
            setShowAuthScreens(false);
        }} onFinish={(result) => {
            if (result.status === 'success') {
                onInstall(result.workflows);
            }
            setShowAuthScreens(false);
        }} preview={mode === 'preview'}></MarketplaceWorkflowInstallScreen>}
    </>
}


function VideoPlayer({ mediaUrl }) {


    return <video style={{ width: '100%', height: '100%' }} controls>
        <source src={mediaUrl} />
    </video>

}

