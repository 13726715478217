import React, { useEffect, useState } from 'react';
import { CircularProgress, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectCustomRender from '../../components/SelectCustomRender'
import { useParams } from "react-router-dom";

import connectorService from '../../lib/connectors'
import connectionService from '../../lib/connection'

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: '70px',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '5rem'
    },
    logo: {
        height: "70px",
        marginBottom: '1rem'
    },
    headerText: {
        color: "#052944",
        fontWeight: '800',
        fontFamily: 'Mulish',
        fontSize: '2.6em',
        marginBottom: '1rem',
        textTransform: 'Capitalize',
        textAlign: 'center'
    },
    inputWrap: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    label: {
        color: "#052944",
        fontWeight: '700',
        fontFamily: 'Mulish',
        fontSize: '1.7rem',
        marginBottom: '1rem',
        textTransform: 'Capitalize',
        textAlign: 'center'
    },
    description: {
        color: "#052944",
        fontWeight: '600',
        fontFamily: 'Mulish',
        fontSize: '1.3rem',
        marginBottom: '1rem',
        textAlign: 'center',
        opacity: '0.7'
    },
    input: {
        color: '#303159',
        fontFamily: 'Mulish',
        fontSize: '1.5rem',
        letterspacing: 0,
        lineHeight: '1.6rem',
        outline: 'none',
        border: '2px solid #C6CBDF',
        paddingLeft: '5px',
        borderRadius: '4px',
        height: '40px',
    },
    inputLine: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
    },
    prefix: {
        color: "#052944",
        fontWeight: '600',
        fontFamily: 'Mulish',
        fontSize: '1.6rem',
        textAlign: 'center',
        marginRight: '0.5rem'
    },
    suffix: {
        color: "#052944",
        fontWeight: '600',
        fontFamily: 'Mulish',
        fontSize: '1.6rem',
        textAlign: 'center',
        marginLeft: '0.5rem'
    },
    buttonWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem'
    },
    continueBtn: {
        borderRadius: '4px',
        backgroundColor: '#4166EA',
        padding: '10px 25px',
        color: "#ffffff",
        fontWeight: '600',
        fontFamily: 'Mulish',
        fontSize: '1.6rem',
        textAlign: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        '&:active': {
            opacity: '0.8'
        }
    },
    selectInput: {
        height: '4.4rem',
        width: '50rem',
        border: '0.1rem solid #C6CBDF',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
        color: '#2C2E4C',
        fontFamily: 'Muli Bold',
        fontSize: '1.6rem',
        letterSpacing: '-0.057rem',
        lineHeight: '1.9rem',
        paddingLeft: '1.55rem',
        position: 'relative'
    },
    selectValue: {
        color: '#303159',
        fontFamily: 'Mulish',
        fontSize: '1.5rem',
        letterspacing: 0,
        lineHeight: '1.6rem',
        outline: 'none',
        paddingLeft: '5px',
        borderRadius: '4px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
}));

export default function PreAuthentication(props) {

    const classes = useStyles({});

    const { appId, state } = useParams();
    const [initialLoad, setInitialLoad] = useState(false);

    const [app, setApp] = useState({
        app_name: 'Application',
        app_logo: '/integration-tile-logo.png'
    })


    const [fields, setFields] = useState([]);

    const [connectionData, setConnectionData] = useState([]);


    useEffect(() => {
        if (appId) {
            connectorService.getConnectorByAppId(appId).then(connRes => {
                setApp({
                    app_name: connRes.data.connector.app_name,
                    app_logo: connRes.data.connector.app_logo,
                })
                connectionService.getPreAuthentication(appId, state).then(preAuthRes => {
                    setFields(preAuthRes.data.preAuthentication.inputs);
                    setInitialLoad(true);
                }).catch(err => {
                    console.log(err)
                })
            }).catch(err => {
                console.log(err)
            })

        }
    }, [appId, state]);


    function onContinue() {
        connectionService.completePreAuthentication(state, connectionData).then(res => {
            window.location.href = res.data.completePreAuthenticationAndGetAuthUrl.auth_url
        }).catch(err => {
            console.log(err)
            alert("Unkown error occurred")
        })
    }

    //SelectCustomRender

    function fieldRenderer(f) {

        let control = null;

        let itemIdx = connectionData.findIndex(c => c.field_name === f.name);

        let currentValue = null;

        if (itemIdx > -1) {
            currentValue = connectionData[itemIdx].field_value;
        }


        if (f.control_type && f.control_type.toLowerCase()==='select') {
            let items = f.picklist_value
            control = <SelectCustomRender items={items}
                className={classes.selectInput}
                enableSearch={true}
                filter={(item, search) => {
                    if (!search) {
                        return true;
                    } else {
                        return item.key.toLowerCase().includes(search.toLowerCase());
                    }
                }}
                valueCondition={(item, value) => {
                    return item.key === value;
                }}
                value={currentValue}
                onChange={item => {
                    let newConnectionData = [...connectionData];
                    let fidx = newConnectionData.findIndex(c => c.field_name === f.name);
                    if (fidx > -1) {
                        newConnectionData[fidx] = {
                            field_name: f.name,
                            field_value: item.key
                        }
                    } else {
                        newConnectionData.push({
                            field_name: f.name,
                            field_value: item.key
                        })
                    }
                    setConnectionData(newConnectionData)
                }}
                itemRender={(item, idx) => {
                    return <div className={classes.selectValue}>
                        <span>{item.value}</span>
                    </div>
                }}>

            </SelectCustomRender>
        } else {
            control = <input className={classes.input} onChange={e => {
                let newConnectionData = [...connectionData];
                let fidx = newConnectionData.findIndex(c => c.field_name === f.name);
                if (fidx > -1) {
                    newConnectionData[fidx] = {
                        field_name: f.name,
                        field_value: e.target.value
                    }
                } else {
                    newConnectionData.push({
                        field_name: f.name,
                        field_value: e.target.value
                    })
                }
                setConnectionData(newConnectionData)
            }}></input>
        }

        return <div className={classes.inputWrap}>
            <label className={classes.label}>{f.label}</label>
            {f.description && <span className={classes.description}>{f.description}</span>}
            <div className={classes.inputLine}>
                {f.prefix && <span className={classes.prefix}>{f.prefix}</span>}
                {control}
                {f.suffix && <span className={classes.suffix}>{f.suffix}</span>}
            </div>
        </div>
    }



    return <>
        {!initialLoad && <div className={classes.container}><CircularProgress></CircularProgress></div>}
        {initialLoad && <div className={classes.container}>
            <div className={classes.header}>
                <img className={classes.logo} src={app.app_logo} ></img>
                <span className={classes.headerText}>{app.app_name}</span>
            </div>

            {fields.map(f => {
                return fieldRenderer(f)
            })}

            <div className={classes.buttonWrap}>
                <span className={classes.continueBtn} onClick={() => {
                    onContinue()
                }}>Continue</span>
            </div>
        </div>
        }
    </>
}