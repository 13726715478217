import { makeStyles } from '@material-ui/core/styles';



const useRequestIntegraitonFormStyle = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '555px',
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        boxShadow: '0 4px 10px 0 rgba(76,141,235,0.1)',
        padding: '3.3rem',
    },
    closeBtn: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        cursor: 'pointer'
    },
    closeBtnIcon: {
        fontSize: '2rem'
    },
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '900',
        color: '#303159',
        fontFamily: "Mulish",
        fontSize: '24px',
        letterSpacing: '-0.18px',
        lineHeight: '30px',
        marginBottom: '3.1rem',

    },
    info: {
        marginBottom: '3.1rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        color: '#303159',
        fontFamily: "Mulish",
        fontSize: '16px',
        letterSpacing: '-0.18px',
        lineHeight: '30px',
        textAlign: 'center'
    },
    userInput: {
        marginBottom: '3.1rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textareaInput: {
        height: '100px',
        width: '100%',
        border: '1px solid #C6CBDF',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        color: '#303159',
        fontFamily: "Mulish",
        fontSize: '13px',
        padding: '0.2rem 1rem',
        paddingTop: '1rem'
    },
    submit: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    installBtn: {
        borderRadius: '4px',
        padding: '1.3rem 6rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#20BB86',
        "color": "#FFFFFF",
        "fontSize": "14px",
        "fontWeight": "800",
        "letterSpacing": "-0.14px",
        "lineHeight": "18px",
        cursor: 'pointer',
        '&:hover': {
            "opacity": '0.8'
        },
        '&:active': {
            "opacity": '0.4'
        },
        userSelect: 'none'
    },
}))

export default useRequestIntegraitonFormStyle;