import React, { useState,  useEffect } from 'react';

import {  useParams } from "react-router-dom";

import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';

import Loader from "../../components/Loader";
import ListingView from './ListingView';

const HTTP_LINK = new HttpLink({ uri: `https://${process.env.REACT_APP_PUBLIC_API_HOST}/app/apifuse-api/graphql` });

const AUTH_MIDDLEWARE = new ApolloLink((operation, forward) => {
    const token = sessionStorage.getItem('marketplace_access_token')
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });
    return forward(operation);
})


const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(AUTH_MIDDLEWARE, HTTP_LINK)
});


function getMarketplaceListing(orgId, listingId) {
    const getMarketplaceQuery = gql`
    {
        getListingById(listingId:"${listingId}"){
            listingId
            name
            logo
            category
            type
            organizationId
            slug
            status
            dedicatedLandingPage
            requestInfoText
            requestButtonText
            requestTitle
            showComingSoonLabel
            settings{
                description
              screenshots
              videos
              sourceWorkflow
              description
              installCTA
              monetizationPlan
              features
              pricing
              customize
              externalLink
              authScreens{
                appId
                appName
                appVersion
                appLogo
                title
                description
                buttonCTA
                fieldId
                stepId
                type
                templateId
                fieldItems{
                    name
                    displayName
                }
              }
            }
          }
          getTemplateForListing(listingId:"${listingId}"){
            code
            templateId
          }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}




export default function ListingPreivew({marketplace,mode}) {


    const { listingId } = useParams();


    const [initialLoad, setInitialLoad] = useState(false);


    const [listing, setListing] = useState({});

    const [workflowTemplate, setWorkflowTemplate] = useState(null);


    const [queryJWT, setQueryJWT] = useState(null);



    useEffect(() => {
        if (!initialLoad) {

            if (window.location.search) {
            
            }
           
        }
    }, [initialLoad]);

    useEffect(() => {
        if (!initialLoad) {
            getMarketplaceListing(marketplace.organizationId, listingId).then(res1 => {
              
                setListing(res1.data.getListingById);

                let templates = []
                res1.data.getTemplateForListing?.map(t => {
                    if (t.code) {
                        templates.push({ ...t, code: JSON.parse(t.code) })
                    }
                })
                setWorkflowTemplate(templates)

            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setInitialLoad(true);
            })
        }
    }, [initialLoad, listingId]);




    useEffect(() => {

        if (listingId && initialLoad) {
            let listingName = '';
            if (listing && listing.name) {
                listingName = listing.name;
            }
            const updateString = `
            mutation addMarketplaceListingVisit{
                addMarketplaceListingVisit(listingId:"${listingId}",listingName:"${listingName}"){ 
                    code,
                    message
                }}
        `;

            const addMarketplaceVisit = gql(updateString);

            client
                .mutate({
                    mutation: addMarketplaceVisit
                }).then(res => {
                    // console.log(res.data)
                }).catch(err => {
                    console.log(err)
                })
                
        }


    }, [initialLoad,listing])




 console.log(workflowTemplate)


    return <>
    {!initialLoad && !listing && !workflowTemplate && <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100vh',width:'100vw'}}>
        <Loader></Loader>
        </div>}
    {initialLoad &&listing && workflowTemplate &&  <ListingView marketplace={marketplace} listing={listing} listingId={listingId} mode={mode} orgId={marketplace.organizationId} templates={workflowTemplate}>
        </ListingView>}

    </>
    
    
}

