import React, { useState, useEffect } from 'react';
import {
    DialogTitle, Dialog, Grid, DialogActions, DialogContent,
    DialogContentText, Container, CircularProgress, InputLabel, FormControl,
    TextField, Select, MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from "../../components/Button"
import { useParams } from "react-router-dom";

import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";

const client = new ApolloClient({
    uri: process.env.REACT_APP_PUBLIC_CONNECTOR_ENDPOINT,
    request: (operation) => {
        const token = sessionStorage.getItem('marketplace_access_token') || sessionStorage.getItem('access_token');
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : ''
            }
        })
    }
});


const connectionClient = new ApolloClient({
    uri: process.env.REACT_APP_PUBLIC_CONNECTION_ENDPOINT,
    request: (operation) => {
        const token = sessionStorage.getItem('marketplace_access_token') || sessionStorage.getItem('access_token');
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : ''
            }
        })
    }
});

function getConnectorByAppId(appId) {
    const getConnectorQuery = gql`
    {
        connector(app_id:"${appId}"){
            app_name,
            app_id,
            app_logo,
            app_desc,
            connection{connection_type,connection_data{name,label,control_type,optional}}
        }
    }
`;

    return client
        .query({
            query: getConnectorQuery
        });

}

function getConnectorByAppIdAndVersion(appId,version) {
    const getConnectorQuery = gql`
    {
        connector_v2(app_id:"${appId}",version:"${version}"){
            app_name,
            app_id,
            has_trigger,
            app_logo,
            app_desc,
            connection{connection_type,connection_data{name,label,control_type,optional}},
            pick_lists,
            objects
        }
    }
`;

    return client
        .query({
            query: getConnectorQuery
        });

}

function getState(state){
    const getStateQuery = gql`
    {
        state(state:"${state}"){
            provider,
            provider_version,
            state
        }
    }
`;

    return connectionClient
        .query({
            query: getStateQuery
        });
}


function saveConnectionData(state, connectionData) {
    let mutationString = `mutation storeConnectionData{
        storeConnectionData(state:"${state}",connection_data:[`

    for (let i = 0; i < connectionData.length; i++) {
        let d = connectionData[i];

        mutationString += `{field_name:"${d.field_name}",field_value:"${d.field_value}"}`;

        if ((i + 1) < connectionData.length) {
            mutationString += ',';
        }

    }
    mutationString = mutationString + `]){status,error_desc}}`;


    const storeConnectionDataMutation = gql(mutationString);

    return connectionClient
        .mutate({
            mutation: storeConnectionDataMutation
        });
}


const useStyles = makeStyles((theme) => ({

    loader: {
        position: "absolute",
        top: "40%",
        left: "50%"
    },

    containerBox: {
        marginTop: "40px",
        minWidth: "400px"
    },
    logoBox: {
        height: "100px"
    },
    logo: {
        height: "50px"
    },
    formControl: {
        minWidth: 500,
        margin: theme.spacing(1),
        marginBottom: '2.4rem'
        // height: "30px"
    },
    formInputLabel: {
        color: "#052944",
        fontWeight: '600',
        fontFamily: 'Mulish',
        fontSize: '1.4rem',
        marginBottom: '1rem',
        textTransform: 'Capitalize'
    },
    input: {
        color: '#303159',
        fontFamily: 'Mulish',
        fontSize: '1.5rem',
        letterspacing: 0,
        lineHeight: '1.6rem',
    },
    inputGrid: {
        marginTop: "20px"
    },
    containedButton: {
        background: '#3D42BF',//'linear-gradient(224.43deg, #4166EA 0%, #3D42BF 100%)',
        marginRight: '2rem',
        borderRadius: '0.4rem',
        borderWidth: "0.2rem",
        borderColor: "#08DD7B",
        fontFamily: 'Mulish',
        color: "#FFFFFF",
        fontWeight: '600',
        fontSize: '1.4rem',
        paddingRight: '3rem',
        paddingLeft: '3rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        cursor: 'pointer',

    },
    outlineButton: {
        marginRight: '20px',
        borderRadius: 20,
        borderWidth: "2px",
        borderColor: "#08DD7B",
        color: "#08DD7B",
        fontWeight: '600',
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    btnText: {
        color: 'white'
    },
    selectAppItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        //   padding: "10px"
        // height: "2rem",
    },
    // selectAppItemLogoWrap: {
    //     height: "20px",
    //     width: "20px",
    // },
    selectAppItemLogo: {
        display: 'inline-block',
        height: "2rem",
        width: "2rem",
    },
    selectAppItemText: {
        display: 'inline-flex',
        marginLeft: "1.6rem",
        color: '#303159',
        fontFamily: 'Muli Bold',
        fontSize: '1.4rem',
        letterspacing: 0,
        lineHeight: '1.6rem',
        height: "2rem",
        alignItems: 'center'
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


export default function NewConnection(props) {

    const [connectorConfigLoaded, setConnectorConfigLoaded] = useState(false);
    const [connectorConfigLoadingInprogress, setConnectorConfigLoadingInprogress] = useState(false);
    const [connector, setConnector] = useState(null);
    const [connectionInputData, setConnectionInputData] = useState({});



    const [openNewParamBox, setOpenNewParamBox] = useState();
    const [newParameterName, setNewParameterName] = useState()
    //const closeWindow = useSelector(selectCloseWindow);

    const classes = useStyles();


    const { appId, state } = useParams();



    function loadConnectorConfig(app_id,state) {
        setConnectorConfigLoaded(false);
        setConnectorConfigLoadingInprogress(true);
        getState(state).then(res=>{
            getConnectorByAppIdAndVersion(res.data.state.provider,res.data.state.provider_version||'1').then(result => {
                setConnector(result.data.connector_v2)
                setConnectorConfigLoaded(true)
                setConnectorConfigLoadingInprogress(false)
            }).catch(err => {
    
            })
        }).catch(err=>{

        })
       

    }

    function storeConnectionData(storeCon) {

        const { state, connectionInputData } = storeCon;
        let fieldnames = Object.keys(connectionInputData);
        let dataAr = fieldnames.map(f => {
            return { field_name: f, field_value: connectionInputData[f] }
        })

        saveConnectionData(state, dataAr).then(result => {

            window.opener?.postMessage("workflow_new_connection_completed", "*");
            console.log(result)
            window.close();
            //  alert("Authenticated successfully");
        }).catch(err => {

        }).finally(() => {

        })
    }


    useEffect(() => {
        if (!connectorConfigLoaded && !connectorConfigLoadingInprogress && appId) {
            loadConnectorConfig(appId,state)
        }
        return () => {
        };
    });

    let buttonDisabled = false;

    let fieldRender = []

    if (connector && connector.app_id === 'http') {
        //connection

        fieldRender.push(<Grid className={classes.inputGrid} item xl={1}>
            <InputLabel className={classes.formInputLabel}>Connection Type</InputLabel>
            <FormControl variant="outlined" className={classes.formControl}>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={connectionInputData['connectionType'] || ''}
                    onChange={(event) => {
                        setConnectionInputData({ 'connectionType': event.target.value });
                    }}>
                    <MenuItem value={'QUERYSTRING'} className={classes.selectAppItemWrap}>
                        {/* <div className={classes.selectAppItem}> */}
                        <span className={classes.selectAppItemText}>{'Query String'}</span>
                        {/* </div> */}
                    </MenuItem>
                    <MenuItem value={'BASICAUTH'} className={classes.selectAppItemWrap}>
                        {/* <div className={classes.selectAppItem}> */}
                        <span className={classes.selectAppItemText}>{'Basic Auth'}</span>
                        {/* </div> */}
                    </MenuItem>
                    <MenuItem value={'HEADER'} className={classes.selectAppItemWrap}>
                        {/* <div className={classes.selectAppItem}> */}
                        <span className={classes.selectAppItemText}>{'Header'}</span>
                        {/* </div> */}
                    </MenuItem>
                </Select>
            </FormControl>
        </Grid>)

        if (connectionInputData['connectionType'] === 'BASICAUTH') {
            fieldRender.push(<Grid className={classes.inputGrid} item xl={1}>
                <InputLabel className={classes.formInputLabel}>Username</InputLabel>
                <FormControl variant="outlined" className={classes.formControl}>
                    <TextField variant="outlined"
                        value={connectionInputData['username'] || ''}
                        onChange={(event) => {
                            let newConnectionInputData = { ...connectionInputData };
                            newConnectionInputData['username'] = event.target.value;
                            setConnectionInputData(newConnectionInputData);
                        }} />
                </FormControl>
            </Grid>)
            fieldRender.push(<Grid className={classes.inputGrid} item xl={1}>
                <InputLabel className={classes.formInputLabel}>Password</InputLabel>
                <FormControl variant="outlined" className={classes.formControl}>
                    <TextField variant="outlined"
                        type="password"
                        value={connectionInputData['password'] || ''}
                        onChange={(event) => {
                            let newConnectionInputData = { ...connectionInputData };
                            newConnectionInputData['password'] = event.target.value;
                            setConnectionInputData(newConnectionInputData);
                        }} />
                </FormControl>
            </Grid>)
        } else if (connectionInputData['connectionType'] === 'QUERYSTRING' || connectionInputData['connectionType'] === 'HEADER') {

            let keys = Object.keys(connectionInputData);
            keys.map(key => {
                if (key !== 'connectionType') {
                    fieldRender.push(<Grid className={classes.inputGrid} item xl={1}>
                        <InputLabel className={classes.formInputLabel}>{key}</InputLabel>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField variant="outlined"
                                value={connectionInputData[key] || ''}
                                onChange={(event) => {
                                    let newConnectionInputData = { ...connectionInputData };
                                    newConnectionInputData[key] = event.target.value;
                                    setConnectionInputData(newConnectionInputData);
                                }} />
                        </FormControl>
                    </Grid>)
                }
                return null;
            })

            fieldRender.push(<Grid className={classes.inputGrid} item xl={1}>
                {<Button disabled={buttonDisabled} type={"secondary"} onClick={() => {
                    setOpenNewParamBox(true);
                    setNewParameterName('');
                }} text={(connectionInputData['connectionType'] === 'HEADER' && 'Add Header Parameter') || 'Add Query Parameter'}></Button>}
            </Grid>)


        }



    } else if (connector && connector.connection) {
        const config = connector.connection;
        if (config.connection_type === 'OAUTH2') {
            window.opener && window.opener.postMessage("workflow_new_connection_completed", "*");
            //console.log(result)
            window.close();
        } else {
            fieldRender = config.connection_data.map((field, idx) => {

                if (!field.optional && !connectionInputData[field.name]) {
                    buttonDisabled = true;
                }

                let type = "text";
                if (field.label && (field.label.toLowerCase().includes("password") || field.label.toLowerCase().includes("secret"))) {
                    type = "password"
                }

                return <Grid className={classes.inputGrid} key={idx} item xl={1}>
                    <InputLabel className={classes.formInputLabel}>{field.label}</InputLabel>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField className={classes.input} style={{ fontSize: '2rem' }} variant="outlined"
                            type={type}
                            inputProps={{
                                className: classes.input
                            }}
                            value={connectionInputData[field.name] || ''}
                            onChange={(event) => {
                                let newConnectionInputData = { ...connectionInputData };
                                newConnectionInputData[field.name] = event.target.value;
                                setConnectionInputData(newConnectionInputData);
                            }} />
                    </FormControl>
                </Grid>;
            })
        }
    }


    return (
        <>
            <style jsx>
                {`
           @font-face {
            font-family: 'Muli Regular';
            src: url('/fonts/Mulish-Regular.ttf');
        }
        @font-face {
            font-family: 'Muli';
            src: url('/fonts/Mulish-Regular.ttf');
        
        }
        @font-face {
            font-family: 'Muli Bold';
            src: url('/fonts/Mulish-Bold.ttf');
        
        }
        @font-face {
            font-family: 'Muli Black';
            src: url('/fonts/Mulish-BlackItalic.ttf');
            src: url('/fonts/Mulish-Black.ttf');
        }
        @font-face {
            font-family: 'Muli BlackItalic';
            src: url('/fonts/Mulish-Black.ttf');
        }
        @font-face {
            font-family: 'Muli ExtraBold';
            src: url('/fonts/Mulish-ExtraBold.ttf');
        }

        @font-face {
            font-family: 'Muli SemiBold';
            src: url('/fonts/Mulish-SemiBold.ttf');
        }
        @font-face {
            font-family: 'Sofia Pro';
            src: url('/fonts/sofiapro-light.otf');
        }

        .containerFont{
            font-size:1.6rem;
            line-height:1.9rem;
            color:#303159;
        }

        .overlay{
            width:100vw;
            height:100vh;
            opacity: 0.9;
            background-color: #526E98;
            display:flex;
            align-items:center;
            justify-content:center;
            position:fixed;
            top:0;
            left:0;
            z-index:10000;
            overlflow:scroll;
        }
        .container{
            height: 70.4rem;
            width: 100.6rem;
            border-radius: 8px;
            opacity: 1;
            background-color: #FFFFFF;
            box-shadow: 0 4px 10px 0 rgba(76,141,235,0.1);
            z-index:10001;
            display:flex;
            flex-direction:column;
        }
        .header{
            width:100%;
            height:10rem;
            border-bottom:1px solid #C6CBE0;
            display:flex;
            align-items:center;
            padding-left:3.05rem;
        }
        .header-text{
            color: #303159;
            font-family: "Muli ExtraBold";
            font-size: 24px;
            letter-spacing: -0.18px;
            line-height: 30px;
        }
        .body{
            flex:1;
            width:100%;
            display:flex;
            flex-direction:row;
        }
        .formulas-sidbar{
            width:25rem;
            height:100%;
            border-right:1px solid #C6CBE0;
            overflow:scroll;
            padding:1.85rem;
            background-color:#F9FAFF;
        }
        .formula-editor{
            flex:1;
            height:100%;
            overflow:scroll;
            display:flex;
            flex-direction:column;

        }
        .fields-sidebar{
            width:25rem;
            height:100%;
            border-left:1px solid #C6CBE0;
            overflow:scroll;
            background-color:#F9FAFF;
            font-size:1.6rem;
            line-height:1.9rem;
            color:#303159;
            padding:1.85rem;
        }
        .editor-box{
            flex:1;
            width:100%;
            border-bottom:1px solid #C6CBE0;
            font-size:1.6rem;
            line-height:1.6rem;
            color:#303159;
            padding:1rem;
        }
        .action-box{
            height:10rem;
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
           
        }

        .cancel-btn{
            display:flex;
            height: 3.4rem;
            width: 16rem;
            border-radius: 0.4rem;
            background-color: #E3E8F9;
            align-items:center;
            justify-content:center;
            color: #486CBB;
            font-family: "Muli Bold";
            font-size: 1.4rem;
            letter-spacing: -0.014rem;
            line-height: 1.8rem;
            text-align: center;
            cursor:pointer;
        }
        .save-btn{
            margin-left:1rem;
            display:flex;
            height: 3.4rem;
            width: 16rem;
            border-radius: 0.4rem;
            background: linear-gradient(224.43deg, #4166EA 0%, #3D42BF 100%);
            align-items:center;
            justify-content:center;
            color: #FFFFFF;
            font-family: "Muli Bold";
            font-size: 14px;
            letter-spacing: -0.14px;
            line-height: 1.8rem;
            text-align: center;
            cursor:pointer;
        }
        .formula-header-text{
            flex:1;
        }
        .formula-header-arrow{
            
        }
        .formula-header-logo{

        }
    `}
            </style>

            <Dialog open={openNewParamBox} onClose={() => { setOpenNewParamBox(false); }} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ width: '200rem' }} id="form-dialog-title">{(connectionInputData['connectionType'] === 'HEADER' && 'New Header Parameter') || 'New Query Parameter'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <TextField
                        autoFocus
                        inputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.formInputLabel
                        }}
                        margin="dense"
                        id="connection_name"
                        label="Parameter Name"
                        type="text"
                        value={newParameterName}
                        onChange={(event) => {
                            setNewParameterName(event.target.value);
                        }}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions style={{ marginBottom: '3rem' }}>
                    <Button onClick={() => {
                        setOpenNewParamBox(false)
                        setNewParameterName('');
                    }} text={"Cancel"} type="secondary">

                    </Button>
                    <Button onClick={() => {
                        //setNewConnectionName('');
                        if (newParameterName) {
                            let newConnectionInputData = { ...connectionInputData }
                            newConnectionInputData[newParameterName] = '';
                            setConnectionInputData(newConnectionInputData)
                            setNewParameterName('');
                            setOpenNewParamBox(false);
                        } else {
                            alert("Provid valid name")
                        }

                    }} text={"Add"}>

                    </Button>
                </DialogActions>
            </Dialog>



            <Container maxWidth="sm">
                {connectorConfigLoadingInprogress && <CircularProgress className={classes.loader}></CircularProgress>}
                {connectorConfigLoaded && <>
                    <Grid className={classes.containerBox} container direction="column">
                        <Grid container alignItems="center" justify="center">
                            <Grid className={classes.logoBox} item xl={1}>
                                <img className={classes.logo} src={connector.app_logo} alt="logo"></img>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" alignItems="center" justify="center">
                            {fieldRender}
                            <Grid className={classes.inputGrid} item xl={1}>
                                {connector && connector.connection
                                    && connector.connection.connection_type !== 'OAUTH2' &&
                                    <Button type="primary"
                                        style={{ paddingLeft: '4rem', paddingRight: '4rem', paddingTop: '1.3rem', paddingBottom: '1.3rem', fontFamily: 'Mulish', fontSize: '1.5rem', fontWeight: '700' }} disabled={buttonDisabled} onClick={() => {
                                            storeConnectionData({ state, connectionInputData })
                                        }} text="Authenticate"></Button>}
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
            </Container>
        </>
    )


}