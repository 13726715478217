import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import useOutsideClickAlert from '../../hooks/useOutsideClickAlert';


function DownIcon({ color }) {

    return <svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="6.414" viewBox="0 0 10.828 6.414">
        <path id="Path_5_Copy" dataname="Path 5 Copy" d="M0,0,4,4,8,0" transform="translate(1.414 1.414)" fill="none" stroke={color || "#595b85"} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
}



const useStyles = makeStyles((theme) => ({
    root: {
        height: '4.1rem',
        width: '100%',
        border: '1px solid #DCE4F8',
        borderRadius: '2px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        position: 'relative'
    },
    input: {
        flex: 1,
        paddingLeft: '1rem',
        display: 'flex',
        height: '100%',
        width: '100%',
        color: '#313E6F',
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '-0.013rem',
        lineHeight: '16px',
        justifyContent: 'flex-end',
        paddingRight: '1rem',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        alignItems:'center'
    },
    dropIcon: {
        height: '100%',
        width: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '1rem',
        cursor: 'pointer',
    },
    dropIconRotate: {
        transform: 'rotate(180deg)'
    },
    tray: {
        position: 'absolute',
        top: '102%',
        left: 0,
        width: '100%',
        display: 'flex',
        borderRadius: '2px',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        boxShadow: '0 4px 10px 0 rgba(76,141,235,0.1)',
        zIndex: '5',
        overflowY:'scroll',
        maxHeight:'400px',
        paddingBottom:'20px'
    },
    item: {
        height: '4.1rem',
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '-0.013rem',
        lineHeight: '16px',
        textAlign: 'right',
        paddingRight: '1rem',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingLeft: '1rem',
    }
}))

export default function Select({ onChange, items, className, value, inputClass, itemClass,enableSearch,trayClass,trayWidth}) {

    const classes = useStyles();
    const trayRef = useRef(null);

 
    const [showTray, setShowTray] = useState(false);


    let selectedValue = '';

    if (items) {
        let s = items.filter(item => { return item.key === value })[0];
        if (s) {
            selectedValue = s.value;
        }
    }

    const [searchText,setSearchText]= useState(selectedValue)


    let defaultInputClass = inputClass || classes.input;
    let defaultItemClass = itemClass || classes.item;

    let additionalTrayClass = trayClass || '';


  
    useOutsideClickAlert(trayRef,()=>{
        setShowTray(false)
    })

    return (
        <>
            <div className={classes.root + " " + (className || '')} >
               {enableSearch && <input className={defaultInputClass} value={searchText} style={{ width: '100%', border: 'none' }} onChange={(e)=>{
                   setSearchText(e.target.value)
               }} onClick={()=>{
                     setShowTray(true) 
                }}></input>}
                 {!enableSearch && <div className={defaultInputClass}  style={{ width: '100%', border: 'none' }} onClick={()=>{
                     setShowTray(true) 
                }}>{selectedValue}</div>}
                <span className={classes.dropIcon + " " + (showTray && classes.dropIconRotate)} onClick={(e) => {
                                         setShowTray(true) 
                   //  setShowTray(!showTray);e.preventDefault(); 
                    // e.stopPropagation();
                     }}><DownIcon></DownIcon></span>
                {showTray && <div className={classes.tray+" "+additionalTrayClass} ref={trayRef} style={{width:(trayWidth||undefined)}}>
                    {items && items.filter(item=>{
                        if(enableSearch && searchText ){
                            
                            return item.value.toLowerCase().includes(searchText.toLowerCase()) || selectedValue.toLowerCase()===searchText.toLowerCase()
                        }
                        return true;
                    }).map(item => {
                        return <div className={defaultItemClass} style={{ cursor: 'pointer' }} key={item.key} onClick={() => {
                            onChange && onChange({ target: { value: item.key } });
                           
                            setShowTray(false);
                            setSearchText(item.value);
                        }}>
                            <span>{item.value}</span>
                        </div>
                    })}
                </div>}
            </div>
        </>
    )
}