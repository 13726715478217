import React, { useEffect } from 'react';
import { Container, CircularProgress, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useParams} from "react-router-dom";


const useStyles = makeStyles((theme) => ({

    loader: {
        position: "absolute",
        top: "40%",
        left: "50%"
    },

    containerBox: {
        marginTop: "40px",
        minWidth: "400px"
    },
    logoBox: {
        height: "100px"
    },
    logo: {
        height: "50px"
    },
    formControl: {
        minWidth: 400,
        // height: "30px"
    },
    formInputLabel: {
        color: "#052944",
        fontWeight: '600',
        fontSize: '14px',
    },
    inputGrid: {
        marginTop: "20px"
    },
    containedButton: {
        background: 'linear-gradient(45deg, #08DD7B 30%, #08DD7B 90%)',
        marginRight: '20px',
        borderRadius: 20,
        borderWidth: "2px",
        borderColor: "#08DD7B",
        color: "#FFFFFF",
        fontWeight: '600',
        paddingRight: '20px',
        paddingLeft: '20px',
    }
}));


function getAuthUrlForState(state) {
    const token = sessionStorage.getItem('access_token') || sessionStorage.getItem('marketplace_access_token');
    const op = {
        headers: {
            Authorization: token ? `Bearer ${token}` : ''
        }
    }
    return axios.post(process.env.REACT_APP_PUBLIC_CONNECTION_STATE_ENDPOINT, { state }, op);
}


export default function NewConnectionLanding() {

    const { connectionId } = useParams();


    const classes = useStyles();


    useEffect(()=> {
        if (connectionId) {
           getAuthUrlForState(connectionId).then(result => {
                window.location.href = result.data.auth_url;
            }).catch(err => { console.log(err); alert("Unknown error occurred. Please try again later") })
        }
        return ()=> {
        };
    });







    return (
        <>
                <Container maxWidth="sm">
                    <div>
                        <CircularProgress className={classes.loader}></CircularProgress>
                    </div>
                </Container>
        </>
    )


}