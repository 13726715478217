import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';

import useGetUserRole from '../../hooks/useGetUserRole';
import useMontorStyles from "./style.component";
import Select from "../Select";
import Loader from "../Loader"

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import { useState,useEffect } from "react";
import LogViewer from "./LogViewer";

import service from "../../lib/apifuseGraphQL"

const APIFUSE_HTTP_LINK = new HttpLink({ uri: `https://${process.env.REACT_APP_PUBLIC_API_HOST}/app/apifuse-api/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = sessionStorage.getItem('marketplace_access_token');

    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });

    return forward(operation);
})


const apifuseclient = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(authMiddleware, APIFUSE_HTTP_LINK)
});


export default function Monitor({ setSelectedTab }) {

    const [initialLoad,setInitialLoad]=useState(false)
    
    const role = useGetUserRole('');


    const classes = useMontorStyles();

    const [showViewer,setShowViewer]=useState(false);

    const [logViewed, setLogViewed] = useState(null);

    const [workflows,setWorkflow]=useState([]);
    const [customers, setCustomers]=useState([]);

    const [workflowFilter,setWorkflowFilter]=useState('all');
    const [statusFilter,setStatusFilter]=useState('all');
    const [startDateFilter,setStartDateFilter]=useState(new Date('2021-07-01T00:00:00'));
    const [endDateFilter,setEndDateFilter]=useState(new Date());
    const [customerFilter,setCustomerFilter]=useState('all');
    const [currentPage,setCurrentPage]=useState('1');

    const [currentLogs, setCurrentLogs]=useState([]);

    const [totalLogCount,setTotalLogCount]= useState(0);

    const [loadingLogs, setLoadingLogs]=useState(false);

    function loadLogs(
        workflowF,
        endDateF,
        startDateF,
        statusF,
        currentPageF,
        customerF
    ){
        setLoadingLogs(true);
        const getLogsQuery = gql`
        {
            getAllLogs(workflowId:"${workflowF}",before:${endDateF.getTime()},after:${startDateF.getTime()},status:"${statusF}",
             page:${currentPageF},limit:10,customerId: "${customerF}"){
                message
                status
                data{
                    count
                    data{
                        triggerId,
                        workflowName,
                        workflowId,
                        startedAt,
                        status,
                        userId
                    }
                }
            }
        }
        `

        apifuseclient.query({
            query:getLogsQuery,
            fetchPolicy: 'no-cache',
            variables:{}
        }).then(res=>{
            if(res.data.getAllLogs && res.data.getAllLogs.data && res.data.getAllLogs.data.data){
                setCurrentLogs(res.data.getAllLogs.data.data);
                setTotalLogCount(res.data.getAllLogs.data.count)
            }
        }).catch(err=>{

        }).finally(()=>{
            setLoadingLogs(false)
        })
    }

 

    useEffect(()=>{
        if(!initialLoad){
            loadLogs(
                workflowFilter,
                endDateFilter,
                startDateFilter,
                statusFilter,
                currentPage,
                customerFilter
            );
            const initialloadQuery = gql`
            {
                getAllWorkflows{
                    workflow_id
                    workflow_name
                    updated_at
                    is_deleted
                }
            }
        `;
        apifuseclient.query({
            query:initialloadQuery,
            fetchPolicy: 'no-cache',
            variables:{}
        }).then(res=>{
                setWorkflow(res.data.getAllWorkflows);
                setCustomers(res.data.getAllCustomersForOrg);
               
            }).catch(err=>{

            }).finally(()=>{
                setInitialLoad(true)
            })
        }
    },[initialLoad])

/*
{
            status:'success',
            name:'google sheet integration customer',
            workflowId:'',
            startedAt:new Date().getTime()
        },

*/


    let status = [
        {key:'all',value:'All'},
        {key:'COMPLETE',value:'Success'},
        {key:'ERROR',value:'Failed'}
    ]

    let formattedEndDate ='';

    if(endDateFilter){
        let month = '00'+(1+endDateFilter.getMonth());
        month = month.substr(-2);
        let year = endDateFilter.getFullYear();
        let date = '00'+endDateFilter.getDate();
        date=date.substr(-2)
        formattedEndDate = `${year}-${month}-${date}`
    }

    let formattedStartDate ='';

    if(startDateFilter){
        let month = '00'+(1+startDateFilter.getMonth());
        month = month.substr(-2);
        let year = startDateFilter.getFullYear();
        let date = '00'+startDateFilter.getDate();
        date=date.substr(-2)
        formattedStartDate = `${year}-${month}-${date}`
    }


    let totalPages=0;
    
    let pages = [];

    console.log(totalLogCount)

    if(totalLogCount >0){
        totalPages = parseInt(totalLogCount/10)+1;
        console.log(totalPages)
        for(let i=0;i<totalPages && i<5;i++){
            pages.push({
                page:i+1
            })
        }
    }


    return <>
            <style jsx>{`
                .header-menu{
                    display:flex;
                   justify-content:space-between;
                   align-items:center;
                   margin-left:2rem;
                   flex:1;
                }
            `}</style>
               
                {!showViewer  && <div className={classes.monitor}>
                    <div className={classes.searchbar}>
                        <span className={classes.searchLabel}>Show</span>
                        <Select value={workflowFilter} className={classes.selectWorkflow} items={[{key:'all',value:'All'},...workflows.map(w=>{
                            return {
                                key:w.workflow_id,
                                value:w.workflow_name
                            }
                        })]} itemClass={classes.itemClass} trayClass={classes.trayClass} trayWidth="300px" onChange={e=>{
                            setWorkflowFilter(e.target.value)
                            setCustomerFilter('all')
                            setCurrentPage(1)
                            loadLogs(  e.target.value,
                                endDateFilter,
                                startDateFilter,
                                statusFilter,
                                1,
                                'all');
                        }}></Select>
                        <Select className={classes.select} value={statusFilter} itemClass={classes.itemClassStatus} items={status} 
                        onChange={e=>{
                            console.log(e.target.value)
                            setStatusFilter(e.target.value)
                            setCurrentPage(1)
                            loadLogs(  workflowFilter,
                                endDateFilter,
                                startDateFilter,
                                e.target.value,
                                1,
                                customerFilter);}}></Select>
                       <div  className={classes.selectDate}>
                            <span className={classes.selectDateTextLeft}>{formattedStartDate}</span>
                            <input className={classes.selectDateInput} type="date" value={formattedStartDate} onChange={e=>{
                            setStartDateFilter(new Date(e.target.value))
                            setCurrentPage(1)
                            loadLogs(  workflowFilter,
                                endDateFilter,
                                new Date(e.target.value),
                                statusFilter,
                                1,
                                customerFilter);
                        }}></input>
                        <span className={classes.selecteDateToText}>To</span>
                        <span className={classes.selectDateTextRight}>{formattedEndDate}</span>
                        <input className={classes.selectDateInput}  type="date" value={formattedEndDate} onChange={e=>{
                            setEndDateFilter(new Date(e.target.value))
                            setCurrentPage(1)
                            loadLogs(  workflowFilter,
                                new Date(e.target.value),
                                startDateFilter,
                                statusFilter,
                                1,
                                customerFilter);
                        }}></input>
                    </div>
                    </div>
                    {loadingLogs && <div className={classes.resultLogItems}>
                        <div style={{display:'flex',flex:1, alignItems:'center',justifyContent:'center'}}>
                        <Loader style={{color:'#4166EA'}}></Loader>
                        </div>
                    
                    </div>}
                    {!loadingLogs && <div className={classes.resultLogItems}>
                        
                        {currentLogs.map(log=>{
                            return <LogItem classes={classes} log={log} onViewLogDetail={()=>{
                                setLogViewed(log);
                                setShowViewer(true);
                            }} onViewWorkflow={()=>{
                                window.open('https://www.apifuse.io/builder/'+log.workflowId,'_blank')
                            }}></LogItem>
                        })}
                    </div>}
                
                    {!loadingLogs &&  <div className={classes.pagesWrap}>
                        <div className={classes.pages}>
                            <div className={classes.pagesNavigation}>
                                <ArrowBackRoundedIcon style={{color:'#595A86',fontSize:'22px'}} onClick={e=>{
                                    if(currentPage>1){
                                        setCurrentPage(parseInt(currentPage)-1)
                                        loadLogs(  workflowFilter,
                                        endDateFilter,
                                        startDateFilter,
                                        statusFilter,
                                        parseInt(currentPage)-1,
                                        customerFilter);
                                    }
                                }}></ArrowBackRoundedIcon>
                            </div>
                            {pages.map(p=>{
                                return <>
                                     <div className={classes.page+" "+(p.page==currentPage?classes.pageActive:'')} onClick={()=>{

                                            setCurrentPage(p.page)
                                            loadLogs(  workflowFilter,
                                            endDateFilter,
                                            startDateFilter,
                                            statusFilter,
                                            parseInt(p.page),
                                            customerFilter);
                                     }}>{p.page}</div>
                                </>
                            })}
                          
                            <div className={classes.pagesNavigation}>
                                <ArrowForwardRoundedIcon style={{color:'#595A86',fontSize:'22px'}} onClick={e=>{
                                    if(currentPage<totalPages){
                                        setCurrentPage(parseInt(currentPage)+1)
                                        loadLogs(  workflowFilter,
                                            endDateFilter,
                                            startDateFilter,
                                            statusFilter,
                                            parseInt(currentPage)+1,
                                            customerFilter);
                                    }
                                }}></ArrowForwardRoundedIcon>
                            </div>
                        </div>
                    </div>}
                </div>}

                {showViewer && logViewed && <LogViewer onViewWorkflow={(workflowId)=>{
                                window.open('https://www.apifuse.io/builder/'+workflowId,'_blank')
                            }} log={logViewed} onCancel={()=>{
                    setShowViewer(false);
                    setLogViewed(null);
                }}></LogViewer>}

            
    </>
}

function LogItem({classes={},log={},onViewLogDetail,onViewWorkflow,}){


    let logDateTime = '';

    if(log.startedAt){
        let d = new Date();
        d.setTime(log.startedAt);

        let year = d.getFullYear();
        let month = "00"+(1+d.getMonth());
        month = month.substr(-2);
        let day = "00"+d.getDate();
        day = day.substr(-2);

        let min = "00"+(d.getMinutes());
        min = min.substr(-2);

        let hour = d.getHours();
        if(hour >12){
            min = min +" PM"
        }else{
            min = min +" AM"
        }
        hour = hour%12;

        logDateTime = `${year}-${month}-${day} at ${hour}:${min}`
    }

    return <>
        <div className={classes.resultLogItem}>
            {log.status.toLowerCase()==='success' && <div className={classes.statusIndicator}>
                <span className={classes.successIndicator}>
                    <CheckCircleRoundedIcon style={{color:'#20BB86',fontSize:'26px'}}></CheckCircleRoundedIcon>
                </span>
                <span className={classes.successIndicatorText}>Success</span>
            </div>}
            {log.status.toLowerCase()==='inprogress' && <div className={classes.statusIndicator}>
                <span className={classes.successIndicator}>
                    <SyncRoundedIcon style={{color:'#ffac02',fontSize:'26px'}}></SyncRoundedIcon>
                </span>
                <span className={classes.inprogressIndicatorText}>In Progress</span>
            </div>}
            {log.status.toLowerCase()!=='success' && log.status.toLowerCase()!=='inprogress' && <div className={classes.statusIndicator}>
                <span className={classes.failureIndicator}>
                    <CancelRoundedIcon style={{color:'#D01030',fontSize:'26px'}}></CancelRoundedIcon>
                </span>
                <span className={classes.failureIndicatorText}>Failed</span>
            </div>}
            <div className={classes.workflowName}>
                <span className={classes.workflowNameText}>{log.name}</span>
                <LaunchRoundedIcon style={{color:'#303159',fontSize:'22px',cursor:'pointer'}} onClick={()=>{
                    onViewWorkflow(log)
                }}></LaunchRoundedIcon>
            </div>
            <div className={classes.logDateTime}>
                <TodayRoundedIcon  style={{color:'#303159',fontSize:'25px'}}></TodayRoundedIcon>
                <span className={classes.logDateTimeText}>{logDateTime}</span>
            </div>
            <div className={classes.viewLogBtn} onClick={()=>{
                    onViewLogDetail(log)
                }}>
                <VisibilityRoundedIcon style={{color:'#486CBB',fontSize:'20px',cursor:'pointer'}}></VisibilityRoundedIcon>
                <span className={classes.viewLogBtnText}>View</span>
            </div>
        </div>
    </>

}