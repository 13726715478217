import React, { useState, useEffect, useRef } from 'react';
import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import gitPullReqImg from "../../images/github-pull-req.svg"
import tileLogoPng from "../../images/integration-tile-logo.png"

import TierNotificationPopup from "../../components/TierNotificationPopup"
import RequestItegrationForm from "../../components/RequestItegrationForm"
import RequestThisIntegrationForm from "../../components/RequestThisIntegrationForm"

import Loader from "../../components/Loader"
import { shouldAllowNewIntegration } from "../../util"

import { Link } from "react-router-dom";


import useOutsideClickAlert from "../../hooks/useOutsideClickAlert";
import Button from "../../components/Button";
import Grid from "../../components/Grid";

import Monitor from "../../components/Monitor"

import service from "../../lib/apifuseGraphQL";
import useCustomerPermissions from '../../hooks/useCustomerPermissions';
import useOutsideAlerter from '../../hooks/useOutsideClickAlert';
import NewIntegrationModal from "../../components/NewIntegrationModal"


import MarketplaceWorkflowInstallScreen from "../../components/MarketplaceWorkflowInstallScreen"
import useGetCustomerAuthContext from "../../hooks/useGetCustomerAuthContext"
import Overlay from '../../components/Overlay';
import UninstallListing from "../../components/UninstallListing";


const httpLink = new HttpLink({ uri: process.env.REACT_APP_PUBLIC_WOKFLOW_ENDPOINT });
const APIFUSE_HTTP_LINK = new HttpLink({ uri: `https://${process.env.REACT_APP_PUBLIC_API_HOST}/app/apifuse-api/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = sessionStorage.getItem('marketplace_access_token');

    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });

    return forward(operation);
})


const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(authMiddleware, httpLink)
});

const apifuseclient = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(authMiddleware, APIFUSE_HTTP_LINK)
});


function getListingById(listingId, orgId) {
    const geListingByIdQuery = gql`
    {
        getListingById(listingId:"${listingId}"){
            listingId
            name
            logo
            category
            type
            organizationId
            status
            requestInfoText
            requestButtonText
            requestTitle
            showComingSoonLabel
            slug
            settings{
                description
              screenshots
              videos
              sourceWorkflow
              description
              installCTA
              monetizationPlan
              pricing
              customize
              externalLink
              authScreens{
                appId
                appName
                appLogo
                title
                description
                buttonCTA
                fieldId
                stepId
                templateId
                type
                fieldItems{
                    name
                    displayName
                }
              }
            }
          }
          getTemplateForListing(listingId:"${listingId}"){
            code
            templateId
          }
    }
`;
    return apifuseclient
        .query({
            query: geListingByIdQuery
        });
}


async function sendInstallEventToAnalytics(listing) {
    let listingName = '';
    if (listing && listing.name) {
        listingName = listing.name;
    }
    const updateString = `
    mutation addMarketplaceListingActivation{
        addMarketplaceListingActivation(listingId:"${listing.listingId}",listingName:"${listingName}"){ 
            code,
            message
        }}
`;

    const addMarketplaceVisit = gql(updateString);

    apifuseclient
        .mutate({
            mutation: addMarketplaceVisit
        }).then(res => {
            // console.log(res.data)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            console.log("install event sent")
        }
        )
}




const useStyles = makeStyles((theme) => ({
    marketplaceWrap: {
        height: '100vh',
        width: '100vw',
    },
    marketplace: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    marketplaceHeader: {
        width: '100%',
        height: '22.8rem',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '5rem',
        paddingRight: '5rem',
        position: 'relative'
    },
    marketplaceLogoBox: {
        height: '12.2rem',
        width: '23.2rem',
        borderRadius: '0.4rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    marketplaceTitleBox: {
        height: '5.3rem',
        display: 'flex',
        marginLeft: '4.45rem',
        marginRight: 'auto',
        alignItems: 'center',
        cursor: 'pointer'
    },
    marketplaceTitleInput: {
        outline: 'none',
        border: 'none',
        display: 'inline-block',
        height: '100%',
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight: '900',
        fontSize: '4.2rem',
        letterSpacing: '-0.131rem',
        lineHeight: '5.3rem',
        marginLeft: '0.5rem',
    },
    marketplaceTitleIcon: {
        fontSize: '2.5rem',
        marginRight: '1rem',
        marginLeft: '1rem',
        cursor: 'pointer',
    },
    marketplaceFontsBox: {
        height: '57px',
        width: '232px',
        border: '2px dashed #8B8CB3',
        borderRadius: '4px',
        marginRight: '0',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    marketplaceFontsIcon: {
        height: '2rem',
        marginRight: '1.41rem'
    },
    marketplaceFontsText: {
        opacity: '0.7',
        color: '#000000',
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '1.3rem',
        letterSpacing: '-0.01rem',
        lineHeight: '1.6rem',
    },
    marketplaceBody: {
        flex: 1,
        position: 'relative',
        overflow: 'auto',
        display: 'flex',
        paddingTop: '4.8rem',

    },
    sidebar: {
    },
    squarBullet: {

        borderRadius: '0.1rem',
        backgroundColor: '#545B6F'
    },
    sidebarCategoriesSubItemBullet: {
        height: '0.5rem',
        width: '0.5rem',
        marginLeft: '0.8rem'
    },
    sidebarCategoriesSubItemText: {
        color: '#303159',
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '1.6rem',
        letterSpacing: '-0.05rem',
        lineHeight: '1.9rem',
        marginLeft: '0.8rem'
    },
    marketplaceMain: {
        flex: 1,
        paddingLeft: '4.8rem',

        display: 'flex',
        flexDirection: 'column',
    },
    marketplaceMainHeader: {
        color: '#303159',
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '2.4rem',
        letterSpacing: '-0.018rem',
        lineHeight: '2.9rem',
    },
    marketplaceMainReqIntegration: {
        marginTop: '2rem',
    },
    marketplaceMainReqIntegrationText: {
        opacity: '0.7',
        color: '#595A86',
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '1.3rem',
        letterSpacing: '-0.01rem',
        lineHeight: '1.6rem',
    },
    marketplaceMainReqIntegrationLink: {
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '1.3rem',
        letterSpacing: '-0.01rem',
        lineHeight: '1.6rem',
        color: '#4166EA',
        opacity: '1',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    marketplaceListing: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: '3.3rem'
    },
    allActive: {
        marginTop: '2rem',
        display: 'flex',
        maxWidth: '900px',
        minWidth: '600px'
    },
    overallIntegrationUsageWrap: {
        flex: '1'
    },

    //BACK TO ADMIN
    backToAdminPanel: {
        position: 'absolute',
        bottom: '-5.4rem',
        maxWidth: '23rem',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    backToAdminPanelArrow: {
        fontSize: '2rem',
        color: '#595B85',
    },
    backToAdminPanelText: {
        marginLeft: '1rem',
        color: '#595B85',
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '1.4rem',
        letterSpacing: '-0.011rem',
        lineHeight: '1.7rem'
    },

    //REQUEST FORM
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: '11000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    },
    requestFromBox: {
        height: '59.5rem',
        width: '55.5rem',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0.4rem 1rem 0 rgba(76,141,235,0.1)',
        padding: '3rem',
        display: 'flex',
        flexDirection: 'column'
    },
    requestFromHeader: {
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight: '900',
        fontSize: '2.4rem',
        letterSpacing: '-0.018rem',
        lineHeight: '3rem',
    },
    requestFromSubTitle: {
        marginTop: '1rem',
        opacity: 0.4,
        color: '#303159',
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '1.6rem',
        letterSpacing: '-0.012rem',
        lineHeight: '2rem',
    },
    requestFromInputBox: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2.9rem'
    },
    requestFromInputLabel: {
        opacity: 0.6,
        color: '#313E6F',
        fontFamily: 'Mulish',
        fontWeight: '700',
        fontSize: '1.3rem',
        letterSpacing: '-0.13px',
        lineHeight: '1.6rem',
        marginBottom: '1rem',
    },
    requestFromInput: {
        height: '44px',
        width: '500px',
        border: '1px solid #C6CBDF',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        outline: 'none',
        paddingLeft: '1.5rem',
        color: '#2C2E4C',
        fontFamily: 'Mulish',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        letterSpacing: '-0.057rem',
        lineHeight: '2rem',
    },
    requestFromFooter: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    sendRequestBtn: {
        marginTop: '2.8rem',
        height: '4.4rem',
        width: '21.3rem',
        borderRadius: '0.4rem',
        backgroundColor: '#20BB86',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '1.4rem',
        letterSpacing: '-0.014rem',
        lineHeight: '1.8rem',
        cursor: 'pointer',
    }
}));


export default function Marketplace(props) {

    const classes = useStyles();

    const { preview, categories, listings, marketplace, orgId } = props;


    const [initialLoad, setInitialLoad] = useState(false);
    const [createWorkflowLoading, setCreateWorkflowLoading] = useState(false);


    const [showRequestForm, setShowRequestForm] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);

    const [queryJWT, setQueryJWT] = useState(null);

    const { userActions, integrationLimits } = useCustomerPermissions(marketplace);




    const requestIntegrationFormRef = useRef(null);

    const [selectedCategory, setSelectedCategory] = useState("All");
    const [listingStatus, setListingStatus] = useState(marketplace.disableTemplatableIntegrations ? "Active" : "All");


    let config = { header: {}, contentSection: {}, sideBar: {} }

    if (props.marketplace) {
        if (preview && props.marketplace.preview) {
            config = props.marketplace.preview;
        } else if (!preview && props.marketplace.config) {
            config = props.marketplace.config;
        }
    }

    const [showCreateNewIntegration, setShowCreateNewIntegration] = useState(false);

    const [pageVisit, setPageVisit] = useState(true);
    const [pageVisitLoading, setPageVisitLoading] = useState(false);

    const [customerUsageAPIs, setCustomerUsageAPIs] = useState([])
    const [customerUsageIntegrations, setCustomerUsageIntegrations] = useState(0)

    const [workflowLoaded, setWorkflowLoaded] = useState(false);
    const [workflows, setWorkflows] = useState([]);
    const [deletedWorkflows, setDeletedWorkflows] = useState([]);
    const [unpublishLoading, setUnpublishLoading] = useState(false);

    const customerAuthContext = useGetCustomerAuthContext();

    useEffect(() => {

        if (pageVisit && !pageVisitLoading) {
            setPageVisitLoading(true)
            const updateString = `mutation addMarketplaceVisit{
                addMarketplaceVisit(viewedAt:"${new Date().getTime()}"){ 
                    code,
                    message
            }}`;

            const addMarketplaceVisit = gql(updateString);

            apifuseclient
                .mutate({
                    mutation: addMarketplaceVisit
                }).then(res => {
                    console.log(res.data)
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setPageVisit(false);
                }
                )
        }

    }, [pageVisit])



    

    function handleUninstall(data) {

        let newList = workflows.filter(w => !data.workflowIds.includes(w.workflow_id));

        setWorkflows(newList);
        setCustomerUsageIntegrations(newList.length)
    }


    function handleWorkflowInstall(data) {
        setWorkflows([...workflows, ...data]);
        let totalCount = workflows.length + data.length;
        setCustomerUsageIntegrations(totalCount);
    }

    useEffect(() => {
        if (initialLoad && !workflowLoaded) {

            if(customerAuthContext.isAuthenticated){
                const getAllWorkflowsQuery = gql`
                    {
                        workflows{
                            workflow_id,
                            workflow_name,
                            user_id,
                            created_at,
                            is_published,
                            listingId
                        }
                    }
                `;
                client
                .query({
                    query: getAllWorkflowsQuery,
                    fetchPolicy: 'no-cache'
                }).then(res => {
                    setWorkflows(res.data.workflows)
                    setCustomerUsageIntegrations(res.data.workflows?.length || 0)
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setWorkflowLoaded(true)
                });
            }else{
                setWorkflowLoaded(true)
            }
        }


        const getCustomerUsageQuery = gql`
            {
                getCustomerUsage{
                    status,
                    message,
                    data{
                        count
                        workflowId
                    }
                }
            }
        `;
        apifuseclient
            .query({
                query: getCustomerUsageQuery,
              //  fetchPolicy: 'no-cache'
            }).then(res => {
                setCustomerUsageAPIs(res.data.getCustomerUsage.data || [])
            }).catch(err => {
                console.log(err)
            }).finally(() => {
            });

    }, [initialLoad, listingStatus])


    useEffect(() => {
        if (!initialLoad) {

            //sessionStorage.removeItem("marketplace_access_token");

            if (window.location.search) {
                let qs = window.location.search;
                qs = qs.substr(1);
                let parts = qs.split("&");
                let params = {};
                parts.map(p => {
                    let kv = p.split("=");
                    if (kv.length > 0) {
                        if (kv.length > 1) {
                            params[kv[0]] = kv[1];
                        } else {
                            params[kv[0]] = '';
                        }
                    }
                })

                Object.keys(params).map(key => {
                    if (key === 'mode') {

                    }
                    if (key === 'jwt') {
                        setQueryJWT(params[key])

                    }
                })

            }
            setInitialLoad(true);
        }

    }, [initialLoad]);

    function deleteWorkflow(workflowId) {
        const deleteWorkflowQuery = `
        mutation deleteWorkflow{
            deleteWorkflow(workflow_id:"${workflowId}"){status,message}}
    `;

        setUnpublishLoading(true)

        return client
            .mutate({
                mutation: gql(deleteWorkflowQuery)
            }).then(res => {
                setDeletedWorkflows([...deletedWorkflows, workflowId])
                setUnpublishLoading(false)
            }).catch(err => {
                setUnpublishLoading(false)
            });
    }

    async function unpublishWorkflow(workflowId) {
        const unpublishWorkflowQuery = `
        mutation unpublishWorkflow{
            unpublishWorkflow(workflow_id:"${workflowId}"){status,message}}
    `;

        setUnpublishLoading(true)
    
        await client
            .mutate({
                mutation: gql(unpublishWorkflowQuery)
            }).then(res => {
                console.log('Completed unpublish event');
                let newList = workflows.map(l => {
                    if(l.workflow_id === workflowId) {
                        l.is_published = false;
                    }
                    return l;
                })
                setWorkflows(newList)
                setUnpublishLoading(false)
            }).catch(err => {
                setUnpublishLoading(false)
                console.log(err)
            });
    }

    function createWorkflow(name) {
        setCreateWorkflowLoading(true);

        const updateString = `
        mutation createWorkflow{
            createWorkflow(workflow:{workflow_name:"${name}",user_id:"1"}){ 
                workflow_id,
                created_at,
                workflow_name
            }}
    `;

        const createWorkflowQuery = gql(updateString);

        return client
            .mutate({
                mutation: createWorkflowQuery
            }).then(res => {
                //process.env.REACT_APP_PUBLIC_BUILDER_URL+
                window.location = "/builder/" + res.data.createWorkflow.workflow_id
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setCreateWorkflowLoading(false);
            }
            )
    }


    useOutsideClickAlert(requestIntegrationFormRef, () => {
        setShowRequestForm(false);
    })


    let activeListings = [];

    activeListings.push({ listingId: 'create_new' });

    if (workflows) {
        workflows.filter(w => {
            let index = deletedWorkflows.findIndex(d => d === w.workflow_id)
            return index === -1;
        }).map(w => {
            let index = listings.findIndex(l => l.listingId === w.listingId);
            if (index > -1) {
                activeListings.push({ ...listings[index], workflow_id: w.workflow_id, is_published: w.is_published })
            } else {
                activeListings.push({ listingId: 'custom', name: w.workflow_name, workflow_id: w.workflow_id, is_published: w.is_published })
            }
        })
    }

    return (
        <>
            {showCreateNewIntegration && <NewIntegrationModal onCancel={() => {
                setShowCreateNewIntegration(false);
            }} onSave={(name) => {
                createWorkflow(name)
            }
            } loading={createWorkflowLoading}></NewIntegrationModal>}

            <div className={classes.marketplaceWrap}>
                <div className={classes.marketplace} style={{ fontFamily: config.font }}>
                    {config.showHeader && <div className={classes.marketplaceHeader} style={{ backgroundColor: config.header.backgroundColor }}>
                        <div className={classes.marketplaceLogoBox} style={
                            {
                                backgroundImage: 'url("' + (config.logo || '') + '")',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain'
                            }
                        } >
                        </div>
                        <div className={classes.marketplaceTitleBox}>
                            <h1 className={classes.marketplaceTitleInput} style={{ backgroundColor: config.header.backgroundColor, color: config.header.fontColor, fontFamily: config.font, fontWeight: 'bold' }}>{config.title}</h1>
                        </div>


                    </div>}
                    <div className={classes.marketplaceBody} style={{ fontFamily: config.font, }}>
                        <div className={classes.sidebar}>
                            <CategoriesSideBar
                                categories={categories}
                                listings={listings}
                                showSidebar={config.showSidebar}
                                isConfigScreen={false}
                                onChange={(cat) => {
                                    setSelectedCategory(cat);
                                }}
                                selectedCategory={selectedCategory}
                                font={config.font}
                                color={config.sideBar.fontColor}
                            >

                            </CategoriesSideBar>
                        </div>
                        <div className={classes.marketplaceMain}>
                            <div className={classes.marketplaceMainHeader}>
                                <span style={{ fontFamily: config.font, color: config.contentSection?.fontColor }}>Integrations</span>
                            </div>
                            {!marketplace.disableTemplatableIntegrations &&  <div className={classes.allActive}>
                                {customerAuthContext?.isAuthenticated && <Button type={listingStatus === "All" ? "primary" : "secondary"} style={{ marginRight: '1rem', width: '10rem', fontFamily: config.font }} background={config.header.backgroundColor} text={"All"} onClick={() => { setListingStatus("All") }}></Button>}

                                {customerAuthContext?.isAuthenticated && userActions.canViewIntegrations && <Button type={listingStatus === "Active" ? "primary" : "secondary"} text={"Active"} style={{ width: '10rem', fontFamily: config.font }} background={config.header.backgroundColor} onClick={() => { setListingStatus("Active") }}></Button>}
                                {customerAuthContext?.isAuthenticated && userActions.canViewLogs && <Button type={listingStatus === "Logs" ? "primary" : "secondary"} text={"Logs"} style={{ width: '10rem',marginLeft:'10px', fontFamily: config.font }} background={config.header.backgroundColor} onClick={() => { setListingStatus("Logs") }}></Button>}

                                {customerAuthContext?.isAuthenticated && listingStatus === "Active" && <div className={classes.overallIntegrationUsageWrap}>
                                    <OverallIntegrationUsageBar limitBy={integrationLimits.limitBy} apiCallsLimit={integrationLimits.maximumAPICalls} integrationLimit={integrationLimits.maximumIntegrations} apisConsumed={customerUsageAPIs} integrationsConsumed={customerUsageIntegrations}></OverallIntegrationUsageBar>
                                </div>}
                            </div>}
                            {marketplace.disableTemplatableIntegrations &&  <div className={classes.allActive}>
                            {customerAuthContext?.isAuthenticated && listingStatus === "Active" && <div className={classes.overallIntegrationUsageWrap}>
                                    <OverallIntegrationUsageBar limitBy={integrationLimits.limitBy} apiCallsLimit={integrationLimits.maximumAPICalls} integrationLimit={integrationLimits.maximumIntegrations} apisConsumed={customerUsageAPIs} integrationsConsumed={customerUsageIntegrations}></OverallIntegrationUsageBar>
                                </div>}
                            </div>}
                            {config.requestIntegration && <div className={classes.marketplaceMainReqIntegration}>
                                <span className={classes.marketplaceMainReqIntegrationText} style={{ fontFamily: config.font, color: config.contentSection.fontColor }}>Can't find what you're looking for? </span><span style={{ fontFamily: config.font }} className={classes.marketplaceMainReqIntegrationLink} onClick={() => {
                                    setShowRequestForm(true);
                                }}>Request Integration</span>
                            </div>}
                            <div className={classes.marketplaceListing}>
                                {listingStatus === 'All' && <ListingsView
                                    workflows={workflows}
                                    config={config}
                                    listingOrder={config.listingOrder}
                                    viewType={config.listingViewType || 'list'}
                                    orgId={marketplace.organizationId}
                                    mode={preview ? 'preview' : 'live'}
                                    jwt={queryJWT}
                                    marketplace={marketplace}
                                    apiCallsUsage={customerUsageAPIs}
                                    integrationUsage={customerUsageIntegrations}
                                    onUninstall={handleUninstall}
                                    onInstall={handleWorkflowInstall}
                                    listings={listings?.filter(listing => {
                                        if (listingStatus !== 'All') {
                                            return false;
                                        } else if (selectedCategory === "All") {
                                            return true;
                                        } else {
                                            return selectedCategory === listing.category
                                        }
                                    })}></ListingsView>}
                                {/* {listingStatus === 'All' && <Grid list={listings.filter(listing => {
                                    if (listingStatus !== 'All') {
                                        return false;
                                    } else if (selectedCategory === "All") {
                                        return true;
                                    } else {
                                        return selectedCategory === listing.category
                                    }
                                })} renderFunction={(listing, idx) => {
                                    return <Link key={idx} href={`/marketplace/${marketplace.organizationId}/listing/${listing.listingId}?mode=${preview ? 'preview' : 'live'}&jwt=${queryJWT}`}>
                                        <div key={idx} className={classes.listingCard}><ListingCard key={idx} listing={listing} onAddNew={() => {
                                            setShowCreateNewIntegration(true);
                                        }}></ListingCard>
                                        </div></Link>
                                }}>
                                </Grid>} */}

                                {listingStatus === 'Logs' && <Monitor></Monitor>}

                                {listingStatus === 'Active' && <Grid list={activeListings} renderFunction={(listing, idx) => {

                                    return <ListingCard customerUsage={customerUsageAPIs} key={idx}
                                        integrationsUsage={customerUsageIntegrations}
                                        apiCallsUsage={customerUsageAPIs}
                                        listing={listing} isActive={true}
                                        isCustom={(listing.listingId === 'custom')}
                                        marketplace={marketplace}
                                        onAddNew={() => {
                                            setShowCreateNewIntegration(true);
                                        }} onDelete={(l) => {
                                            deleteWorkflow(l.workflow_id);
                                        }} onEdit={l => {
                                            // if(l.listingId=='custom'){
                                            //process.env.REACT_APP_PUBLIC_BUILDER_URL+
                                            window.location = "/builder/" + l.workflow_id
                                            //  }
                                        }} onUnPublish={(l) => {
                                            unpublishWorkflow(l.workflow_id);
                                        }}
                                        unpublishLoading={unpublishLoading}
                                        listingStatus={listingStatus}
                                        workflows={workflows}
                                        ></ListingCard>
                                }}>
                                </Grid>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showRequestForm && <RequestItegrationForm orgId={orgId} leading={sendingRequest} onSend={(request) => {
                //setShowRequestForm(false);
                //   sendRequestIntegration(request);
            }} onCancel={() => {
                setShowRequestForm(false);
            }}></RequestItegrationForm>
            }
        </>
    )
}


const useListingCardStyle = makeStyles((theam) => ({
    listingCard: {
        height: '19.3rem',
        width: '16.6rem',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0.4rem 1rem 0 rgba(76,141,235,0.1)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginRight: '3rem',
        fontFamily: props => props.font || 'Mulish',
        "&:hover": {
            "border": "1px solid #4166EA",
            "borderRadius": "4px 4px 0 0",
            "backgroundColor": "#FFFFFF",
            "boxShadow": "0 6px 12px 0 rgba(76,141,235,0.2)"
        }
    },
    listingCardMoreIconBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        height: '1rem',
        marginTop: '0.8rem',
        paddingRight: '0.2rem'
    },
    listingCardMoreIconBox: {
        display: 'flex',
        width: '2rem',
        justifyContent: 'flex-end',
        top: '0',
        right: '0',
        height: '1rem',
        marginTop: '0.8rem',
        paddingRight: '0.2rem',
        position: 'absolute',
        zIndex: '100'
    },
    listingCardMoreBox: {
        position: 'absolute',
        top: '3.8rem',
        right: '-3.1rem',
        zIndex: '101',
        //  height: '7rem',
        width: '81px',
        border: '1px solid #D9D9F4',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 12px 15px 0 rgba(223,223,243,0.34)',
        display: 'flex',
        flexDirection: 'column',
        "&::after": {
            left: '3rem',
            top: '-0.75rem',
            height: '1.5rem',
            width: '1.5rem',
            position: 'absolute',
            backgroundColor: '#FFFFFF',
            zIndex: '102',
            content: '""',
            borderTop: '1px solid #D9D9F4',
            borderRight: '1px solid #D9D9F4',
            transform: 'rotate(-45deg)'
        }
    },
    listingCardMoreEdit: {
        height: '3.5rem',
        width: '100%',
        borderBottom: '1px solid #D9D9F4',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    listingCardMoreEditIcon: {
        marginLeft: '1rem',
        marginRight: '0.6rem'
    },
    listingCardMoreEditText: {
        color: '#888AA9',
        fontWeight: '600',
        fontSize: '10px',
        letterSpacing: '-0.36px',
        lineHeight: '13px',
    },
    listingCardMoreEnable: {
        height: '3.5rem',
        width: '100%',
        borderBottom: '1px solid #D9D9F4',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    listingCardMoreEnableText: {
        color: '#888AA9',
        fontWeight: '600',
        fontSize: '10px',
        letterSpacing: '-0.36px',
        lineHeight: '13px',
    },
    listingCardMoreDelete: {
        height: '3.5rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    listingCardMoreDeleteIcon: {
        marginLeft: '1rem',
        marginRight: '0.6rem'
    },
    listingCardMoreDeleteText: {
        color: '#A0001B',
        fontWeight: '600',
        fontSize: '10px',
        letterSpacing: '-0.36px',
        lineHeight: '13px',
    },
    listingCardMoreIcon: {
        fontSize: '2rem',
        color: '#B3B3B4',
        cursor: 'pointer'
    },
    listingCardLogBox: {
        width: '100%',
        height: '11.3rem',
        borderBottom: '0.1rem solid #DCE4F8',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    listingCardLogo: {
        width: '9rem',
        height: '9rem',
    },
    listingCardNameBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        cursor: 'pointer'
    },
    listingCardNameText: {
        color: '#303159',
        fontWeight: '800',
        fontSize: '1.5rem',
        letterSpacing: '-0.063rem',
        lineHeight: '1.6rem',
    },
    listingCardNameCategory: {
        opacity: 0.4,
        color: '#303159',
        fontWeight: '700',
        fontSize: '11px',
        letterSpacing: '-0.41px',
        lineHeight: '20px',
        marginTop: '0.5rem'
    },
    listingCardAddNewLogo: {
        height: '8.2rem',
        width: '8.2rem',
        borderRadius: '0.8rem',
        backgroundColor: '#4166EA',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    listingCardAddNewLogoImg: {
        height: '8.2rem',
        width: '8.2rem',
        transform: 'rotate(180deg)',
        height: '80%',
        width: '80%'
    },
    listingCardAddNewLogoBox: {
        width: '100%',
        height: '11.3rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    addNewBtn: {
        paddingTop: '1.3rem',
        paddingBottom: '1.3rem',
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',
        fontSize: '1.4rem',
        fontFamily: props => props.font || 'Mulish'
    },
    ribbon: {
        width: '100px',
        height: '124px',
        overflow: 'hidden',
        position: 'absolute',
        top: '0px',
        left: '0px',
        "&::after, &::before": {
            position: 'absolute',
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        "& span": {
            position: 'absolute',
            display: 'block',
            width: '14.5rem',
            right: '0px',
            top: '1rem',
            padding: '0.5rem 0',
            font: 'Mulish',
            fontWeight: 'bold',
            fontSize: '1.3rem',
            backgroundColor: props => props.background ? props.background : "#4166EA",
            color: '#fff',
            textAlign: 'center',
            transform: 'rotate(-45deg)'
        }
    },
    usageBar: {
        height: '2px',
        width: 'calc(100% - 10px)',
        backgroundColor: '#D8D8D8',
        position: 'absolute',
        left: '5px',
        bottom: '1px'
    },
    usedBar: {
        height: '100%',
        width: '50%',
        backgroundColor: '#D01030',
    },
    usageText: {
        position: 'absolute',
        bottom: '4px',
        color: '#D01030',
        fontFamily: 'Mulish',
        fontSize: '10px',
        fontWeight: 'bold',
        letterSpacing: '-0.08px',
        left: '50%',
        transform: 'translate(-50%)'
    },
    listingCardActiveIndicator: {
        position: 'absolute',
        top: '0.8rem',
        left: '1.0rem',
        zIndex: '101',
        display: 'flex',
        flexDirection: 'column',

        height: '18px',
        width: '50px',
        borderRadius: '9px',
    },
    listingCardActiveLable: {
        fontWeight: 'bold',
        marginLeft: '7px',
        marginTop: '2px'
    },
    connectionIndicator: {
        color: '#20bb74',
        fontFamily: 'Mulish',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '-0.5px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        left: '0.2rem'
    }

}))

function ListingCard({
    listing,
    onAddNew,
    isActive, isCustom, onEdit, onDelete, config, marketplace, customerUsage, apiCallsUsage, integrationsUsage, onUnPublish, unpublishLoading, listingStatus, workflows = [] }) {

    const classes = useListingCardStyle({ font: config?.font, background: config?.contentSection?.backgroundColor });

    const moreIconsTrayRef = useRef(null);

    const [showMoreIconTray, setMoreIconTray] = useState(false);

    const { userActions, tierPopupConfig, integrationLimits } = useCustomerPermissions(marketplace);

    const [showTierUpgradeNotification, setShowTierUpgradeNotification] = useState(false);

    const [publishedIntegration, setPublishedIntegration] = useState(listing.is_published)

    useOutsideAlerter(moreIconsTrayRef, () => {
        setMoreIconTray(false);
    });

    useEffect(() => {
        if (!unpublishLoading) {
            setPublishedIntegration(listing.is_published)
        }
    }, [unpublishLoading])

    let alreadyInstalled = false;
    let installedWorkflows = [];

    if (workflows && workflows.length > 0) {
        installedWorkflows = workflows.filter(w => listing.listingId === w.listingId);
        if (installedWorkflows && installedWorkflows.length > 0) {
            alreadyInstalled = true;
        }
    }

    if (listing.listingId === "create_new") {
        return <>
            {showTierUpgradeNotification && <TierNotificationPopup onClose={() => setShowTierUpgradeNotification(false)} notificationConfig={tierPopupConfig} uiConfig={config}></TierNotificationPopup>}
            <div className={classes.listingCard}>

                <div className={classes.listingCardAddNewLogoBox}>
                    <div className={classes.listingCardAddNewLogo}>
                        <img className={classes.listingCardAddNewLogoImg} src={gitPullReqImg}></img>
                    </div>
                </div>
                <div className={classes.listingCardNameBox} >
                    <Button text="+ Add" className={classes.addNewBtn} style={{ fontFamily: config?.font }} onClick={() => {
                        if (userActions?.canCreateIntegrationWithBuilder && shouldAllowNewIntegration(integrationLimits, userActions, apiCallsUsage, integrationsUsage)) {
                            onAddNew && onAddNew();
                        } else {
                            setShowTierUpgradeNotification(true)
                        }

                    }}></Button>
                </div>
            </div>
        </>
    } else {
        let listingName = ''
        if (listing.name) {
            if (listing.name.length > 45) {
                listingName = listing.name.substr(0, 45) + "..."
            } else {
                listingName = listing.name
            }
        }

        let usageIndex = apiCallsUsage.findIndex(c => c.workflowId === listing.workflow_id);


        let usage = 0;

        let usageWidth = 0;

        if (usageIndex > -1) {
            usage = apiCallsUsage[usageIndex].count;

            if (integrationLimits.maximumAPICalls == 0) {
                usageWidth = '100'
            } else {
                usageWidth = parseInt((apiCallsUsage[usageIndex].count / integrationLimits.maximumAPICalls) * 100);
            }
        }


        return <>
            {unpublishLoading && <div style={{width:'100vw',height:'100vh'}}>
                                    <Loader></Loader>
                                </div>}
            {showTierUpgradeNotification && <TierNotificationPopup onClose={() => setShowTierUpgradeNotification(false)}></TierNotificationPopup>}
            { !unpublishLoading && <div className={classes.listingCard} onClick={() => {
                  window.parent?.postMessage(JSON.stringify({"id": "user_listing _click",listingId:listing.listingId}),"*")
                    if(listingStatus==='Active') {
                        setMoreIconTray(true);
                    }
                }}>
                {(listing.showComingSoonLabel && listing.status !== 'available') && !isActive && <div className={classes.ribbon}>
                    <span>Soon</span>
                </div>}
                {/*(listing.listingId !== "create_new") && <div className={classes.listingCardMoreIconBox}>
                    {isActive && <MoreVertIcon className={classes.listingCardMoreIcon} onClick={() => {
                        setMoreIconTray(true);
                    }}></MoreVertIcon>}
                </div>*/}
                {listingStatus==='Active' && <div className={classes.listingCardActiveIndicator} style={{ backgroundColor: ( publishedIntegration ? '#65c466' : '#aab4be94')}}>
                    <span className={classes.listingCardActiveLable} style={{opacity:(publishedIntegration ? '' :'0.6')}}>{publishedIntegration?'Active':'Inactive'}</span>
                </div>}
                {listingStatus ==='All' && alreadyInstalled && <span className={classes.connectionIndicator}><CheckIcon style={{ marginRight: '5px', fontSize: '14px' }}></CheckIcon>Connected</span>}
                {(listing.listingId !== "create_new") && showMoreIconTray && <div ref={moreIconsTrayRef} className={classes.listingCardMoreBox}>
                    {userActions?.canCreateIntegrationWithBuilder && <> <div className={classes.listingCardMoreEdit} style={{ color: '#888AA9' }} onClick={(e) => {
                        //listing.listingId==='custom' && 
                          window.parent?.postMessage(JSON.stringify({"id": "user_listing _edit",listingId:listing.listingId}),"*")
                        onEdit && onEdit(listing);
                        setMoreIconTray(false);
                    }}>
                        <EditIcon className={classes.listingCardMoreEditIcon} ></EditIcon>
                        <span className={classes.listingCardMoreEditText}>Edit</span>
                    </div></>}
                    {publishedIntegration && <div className={classes.listingCardMoreEnable} onClick={(e) => {
                        if(publishedIntegration) {
                            if(window.confirm("This action will unpublish the integration. New events will not be processed until you publish it again.")) {
                                onUnPublish(listing)
                            } else {

                            }
                        } else {
                            alert('This workflow is already unpublished');
                        }
                        setMoreIconTray(false);
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        
                        <CloudOffIcon className={classes.listingCardMoreEditIcon}></CloudOffIcon>
                        <span className={classes.listingCardMoreEnableText}>Disable</span>
                    </div>}
                    <div className={classes.listingCardMoreDelete} onClick={(e) => {
                        onDelete && onDelete(listing)
                        setMoreIconTray(false);
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <DeleteOutlineIcon className={classes.listingCardMoreDeleteIcon} style={{ color: '#A0001B' }}></DeleteOutlineIcon>
                        <span className={classes.listingCardMoreDeleteText}>Remove</span>
                    </div>
                </div>}
                <div className={classes.listingCardLogBox}>
                    <div className={classes.listingCardLogo}>
                        <img style={{ width: '100%', height: '100%' }} src={listing.logo || tileLogoPng}></img>
                    </div>
                </div>
                <div className={classes.listingCardNameBox}>
                    <span className={classes.listingCardNameText}>{listingName}</span>
                    <span className={classes.listingCardNameCategory}>{listing.category}</span>
                </div>
                {(!integrationLimits?.limitBy || integrationLimits?.limitBy === 'api_calls')
                    && integrationLimits.maximumAPICalls !== ''
                    && integrationLimits.maximumAPICalls !== -1 && isActive && <div className={classes.usageBar}>
                        <span className={classes.usageText}>{usage}/{integrationLimits.maximumAPICalls}</span>
                        <div className={classes.usedBar} style={{ width: (usageWidth < 100 ? (usageWidth + "%") : '100%') }}></div>
                    </div>}
            </div>}
        </>
    }


}



function ListingsView({ listings, viewType, configScreen, categories, mode, orgId, jwt, listingOrder,
    config, marketplace, apiCallsUsage, integrationUsage, workflows, onUninstall, onInstall }) {

    return <>
        {viewType === 'list' && <ListingsListView key={"listingListView"} workflows={workflows} listings={listings} config={config} configScreen={configScreen}
            mode={mode} orgId={orgId} jwt={jwt} listingOrder={listingOrder} marketplace={marketplace} apiCallsUsage={apiCallsUsage} integrationsUsage={integrationUsage}
            onUninstall={onUninstall} onInstall={onInstall}></ListingsListView>}
        {viewType === 'tile' && <ListingTileView listings={listings}  workflows={workflows} config={config} configScreen={configScreen} categories={categories}
            mode={mode} orgId={orgId} jwt={jwt} marketplace={marketplace}  listingOrder={listingOrder} apiCallsUsage={apiCallsUsage} integrationsUsage={integrationUsage}></ListingTileView>}
    </>

}

const useListingsListView = makeStyles((theme) => ({
    root: {
        width: '855px',
        background: '#FFFFFF',
        boxShadow: '0px 4px 10px rgba(76, 141, 235, 0.1)',
        borderRadius: '4px',
        fontFamily: 'Mulish'
    },
    separator: {
        height: '1px',
        width: '100%',
        border: '1px solid #DCE4F8',
    },

}))


function ListingsListView({ listings = [], configScreen = false, mode = 'live', jwt, orgId, listingOrder = '[]', config, marketplace,
    apiCallsUsage, integrationsUsage, workflows = [], onUninstall, onInstall }) {

    const classes = useListingsListView({ configScreen });

    const listingOrderJ = JSON.parse(listingOrder);

    return <div className={classes.root}>
        {listingOrderJ.map((lo, idx) => {
            let listingIdx = listings.findIndex(li => li.listingId === lo);
            let listing = listings[listingIdx]
            if (listing) {
                return <>
                    <ListViewItem workflows={workflows} config={config} key={listing.listingId}
                        apiCallsUsage={apiCallsUsage} integrationUsage={integrationsUsage} listing={listing}
                        configScreen={configScreen} mode={mode} jwt={jwt} orgId={orgId} marketplace={marketplace} onUninstall={onUninstall} onInstall={(data) => {
                            onInstall(data);
                        }} ></ListViewItem>
                    <div key={listing.listingId + "sep"} className={classes.separator}></div>
                </>
            }
        })}
        {listings.filter(listing => {
            let listingIdx = listingOrderJ.findIndex(lo => listing.listingId === lo);
            return listingIdx == -1
        }).map((listing, idx) => {
            if (listing) {
                return <>
                    <ListViewItem workflows={workflows} config={config} key={listing.listingId} listing={listing} configScreen={configScreen} mode={mode} jwt={jwt} orgId={orgId}
                        marketplace={marketplace} apiCallsUsage={apiCallsUsage} integrationUsage={integrationsUsage} onInstall={(data) => {
                            onInstall(data);
                        }} onUninstall={onUninstall}></ListViewItem>
                    <div key={listing.listingId + "sep"} className={classes.separator}></div>
                </>
            }
        })}
        {/*  */}


    </div>

}


const useListingTileViewStyle = makeStyles((theme) => ({
    listingCard: {
        height: '19.3rem',
        width: '16.6rem',
        borderRadius: '0.4rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0.4rem 1rem 0 rgba(76,141,235,0.1)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginRight: '5rem'
    },
    listingCardMoreIconBox: {
        display: 'flex',
        width: '2rem',
        justifyContent: 'flex-end',
        top: '0',
        right: '0',
        height: '1rem',
        marginTop: '0.8rem',
        paddingRight: '0.2rem',
        position: 'absolute',
        zIndex: '100'
    },
    listingCardMoreBox: {
        position: 'absolute',
        top: '3.8rem',
        right: '-3.1rem',
        zIndex: '101',
        height: '7rem',
        width: '81px',
        border: '1px solid #D9D9F4',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 12px 15px 0 rgba(223,223,243,0.34)',
        display: 'flex',
        flexDirection: 'column',
        "&::after": {
            left: '3rem',
            top: '-0.75rem',
            height: '1.5rem',
            width: '1.5rem',
            position: 'absolute',
            backgroundColor: '#FFFFFF',
            zIndex: '102',
            content: '""',
            borderTop: '1px solid #D9D9F4',
            borderRight: '1px solid #D9D9F4',
            transform: 'rotate(-45deg)'
        }
    },
    listingCardMoreEdit: {
        height: '3.5rem',
        width: '100%',
        borderBottom: '1px solid #D9D9F4',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    listingCardMoreEditIcon: {
        marginLeft: '1rem',
        marginRight: '0.6rem'
    },
    listingCardMoreEditText: {
        color: '#888AA9',
        fontFamily: "Muli SemiBold",
        fontSize: '10px',
        letterSpacing: '-0.36px',
        lineHeight: '13px',
    },
    listingCardMoreDelete: {
        height: '3.5rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    listingCardMoreDeleteIcon: {
        marginLeft: '1rem',
        marginRight: '0.6rem'
    },
    listingCardMoreDeleteText: {
        color: '#A0001B',
        fontFamily: "Muli SemiBold",
        fontSize: '10px',
        letterSpacing: '-0.36px',
        lineHeight: '13px',
    },
    listingCardMoreIcon: {
        fontSize: '2rem',
        color: '#B3B3B4',
        cursor: 'pointer'
    },
    listingCardLogBox: {
        width: '100%',
        height: '11.3rem',
        borderBottom: '0.1rem solid #DCE4F8',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    listingCardLogo: {
        height: '9rem',
        width: '9rem'
    },
    listingCardNameBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    listingCardNameText: {
        color: '#303159',
        fontFamily: "Muli ExtraBold",
        fontSize: '1.5rem',
        letterSpacing: '-0.063rem',
        lineHeight: '1.6rem',
    },
    listingCardNameCategory: {
        opacity: 0.4,
        color: '#303159',
        fontFamily: 'Muli Bold',
        fontSize: '11px',
        letterSpacing: '-0.41px',
        lineHeight: '20px',
        marginTop: '0.5rem'
    },
}))

function ListingTileView({ listings = [], configScreen = false, categories = [], mode = 'live', listingOrder = '[]', jwt, orgId, config, marketplace, apiCallsUsage, integrationsUsage, workflows = [] }) {



    const classes = useListingTileViewStyle();
    const listingOrderJ = JSON.parse(listingOrder);


    let sortedListing = [];

    listingOrderJ.map(lo=>{
        let listingIdx = listings.findIndex(li => li.listingId === lo);
        let listing = listings[listingIdx]
        if (listing) {
            sortedListing.push(listing)
        }
        
    })
    listings.filter(listing => {
        let listingIdx = listingOrderJ.findIndex(lo => listing.listingId === lo);
        return listingIdx == -1
    }).map(l=>{
        sortedListing.push(l)
    })


    return <Grid list={sortedListing} renderFunction={(listing, idx) => {
        let categoryTag = 'Uncategorized'
        if (categories.filter(cat => listing.category === cat).length > 0) {
            categoryTag = listing.category;
        }

        return <Link key={listing.listingId} to={`/listing/${listing.listingId}?mode=${mode}&jwt=${jwt}`} style={{ textDecoration: 'none' }}>
            <div key={idx} className={classes.listingCard}>
                <ListingCard key={idx} listing={listing}
                    className={classes.listingCard}
                    config={config} marketplace={marketplace}
                    apiCallsUsage={apiCallsUsage}
                    integrationsUsage={integrationsUsage}
                    workflows={workflows}
                    listingStatus='All'
                />
            </div>
        </Link>

    }}>

    </Grid>

}


const useListViewItemStyle = makeStyles((theme) => ({
    listing: {
        width: '100%',
        height: '11.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        fontFamily: props => props.font || 'Mulish',
        "&:hover": {
            "border": props => props.background ? `1px solid ${props.background}` : "1px solid #4166EA",
            "borderRadius": "4px 4px 0 0",
            "backgroundColor": "#FFFFFF",
            "boxShadow": "0 6px 12px 0 rgba(76,141,235,0.2)"
        },
        position: 'relative'
    },
    listingLogo: {
        height: '9rem',
        width: '9rem',
        marginLeft: '3.2rem'
    },
    listingBody: {
        width: '52.6rem',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '3.8rem',
        marginRight: '3.2rem',

    },
    listingBtns: {
        width: '15.6rem',
        display: 'flex',
        flexDirection: 'column',

    },
    installBtn: {
        borderRadius: '4px',
        height: '34px',
        width: '125px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: props => props.background || 'linear-gradient(224.43deg, #4166EA 0%, #3D42BF 100%)',
        "color": "#FFFFFF",
        "fontSize": "14px",
        "fontWeight": "800",
        "letterSpacing": "-0.14px",
        "lineHeight": "18px",
        cursor: 'pointer',
        '&:hover': {
            "opacity": '0.8'
        },
        '&:active': {
            "opacity": '0.4'
        },
        userSelect: 'none'
    },
    learnMoreBtn: {
        marginTop: '0.8rem',
        borderRadius: '4px',
        height: '34px',
        width: '125px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: props => props.background ? props.background + '44' : '#E3E8F9',
        "color": "#486CBB",
        "fontSize": "14px",
        "fontWeight": "800",
        "letterSpacing": "-0.14px",
        "lineHeight": "18px",
        cursor: 'pointer',
        '&:hover': {
            "opacity": '0.8'
        },
        '&:active': {
            "opacity": '0.4'
        },
        userSelect: 'none'
    },
    listingName: {
        "color": "#303159",
        "fontSize": "20px",
        "fontWeight": "800",
        "letterSpacing": "-0.63px",
        "lineHeight": "25px",
        marginBottom: '5px'
    },
    listingDescription: {
        "color": "#303159",
        "fontSize": "13px",
        "fontWeight": "bold",
        "letterSpacing": "-0.63px",
        "lineHeight": "16px",
        opacity: '0.4',
    },
    dragBtn: {
        width: '7px',
        height: '12.97px',
        position: 'absolute',
        top: '9px',
        left: '9px',
        cursor: 'grabbing',
        display: props => props.configScreen ? 'block' : 'none',
    },
    menuBtn: {
        width: '3px',
        height: '15px',
        position: 'absolute',
        top: '9px',
        right: '9px',
        cursor: 'pointer',
    },
    ribbon: {
        width: '100px',
        height: '124px',
        overflow: 'hidden',
        position: 'absolute',
        top: '0px',
        left: '0px',
        "&::after, &::before": {
            position: 'absolute',
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        "& span": {
            position: 'absolute',
            display: 'block',
            width: '14.5rem',
            right: '0px',
            top: '1rem',
            padding: '0.5rem 0',
            font: 'Mulish',
            fontWeight: 'bold',
            fontSize: '1.3rem',
            backgroundColor: props => props.background ? props.background : "#4166EA",
            color: '#fff',
            textAlign: 'center',
            transform: 'rotate(-45deg)'
        }
    },
    connectionIndicator: {
        color: '#20bb74',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '-0.5px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: '1.225rem',
        left: '15.4rem'
    }
}))

function ListViewItem({ listing, onAddNew, isActive, isCustom, onEdit, onDelete, orgId, mode, jwt, configScreen = false,
    config, marketplace, apiCallsUsage, integrationUsage, workflows = [], onUninstall, onInstall }) {



    const { userActions, integrationLimits,tierPopupConfig } = useCustomerPermissions(marketplace);


    const classes = useListViewItemStyle({ font: config.font, background: config.contentSection?.backgroundColor });
    const [initialLoad, setInitialLoad] = useState(false);

    const [localListing, setLocalListing] = useState(listing)

    const [showAuthScreens, setShowAuthScreens] = useState(false);

    const customerAuthContext = useGetCustomerAuthContext();

    const [workflowTemplate, setWorkflowTemplate] = useState(null);

    const [showRequestForm, setShowRequestForm] = useState(false);

    const [showTierUpgradeNotification, setShowTierUpgradeNotification] = useState(false);

    const [showUninstallPopup, setShowUninstallPopup] = useState(false)

    let alreadyInstalled = false;
    let installedWorkflows = [];

    if (workflows && workflows.length > 0) {
        installedWorkflows = workflows.filter(w => listing.listingId === w.listingId);
        if (installedWorkflows && installedWorkflows.length > 0) {
            alreadyInstalled = true;
        }
    }

    useEffect(() => {
        if (!initialLoad) {
            getListingById(listing.listingId)
                .then(res => {
                    setInitialLoad(true);
                    setLocalListing(res.data.getListingById);
                    if (res.data.getTemplateForListing) {
                        let templates = []
                        res.data.getTemplateForListing.map(t => {
                            if (t.code) {
                                templates.push({ ...t, code: JSON.parse(t.code) })
                            }
                        })
                        setWorkflowTemplate(templates)
                    }
                })
                .catch(err => {
                    setInitialLoad(true);

                }).finally(() => {
                    setInitialLoad(true);
                });
        }
    }, [initialLoad])

    return <>
        {showUninstallPopup && alreadyInstalled && <UninstallListing
            onUninstall={(data) => {
                window.parent?.postMessage(JSON.stringify({"id": "listing_uninstall_complete",listingId:listing.listingId}),"*")
                setShowUninstallPopup(false);
                console.log(localListing.listingId)
                  
                onUninstall(data)
            }}
            installedWorkflows={installedWorkflows}
            onClose={() => setShowUninstallPopup(false)}>
        </UninstallListing>}
        {showTierUpgradeNotification && <TierNotificationPopup notificationConfig={tierPopupConfig} onClose={() => setShowTierUpgradeNotification(false)}></TierNotificationPopup>}
        {showRequestForm && customerAuthContext.isAuthenticated && 
        <RequestThisIntegrationForm listing={localListing} onClose={() => setShowRequestForm(false)}></RequestThisIntegrationForm>}
        
        {showRequestForm && !customerAuthContext.isAuthenticated &&
            <RequestItegrationForm listing={listing} orgId={orgId} onCancel={() => setShowRequestForm(false)} requestedIntegration={listing.name || ''}/>}
        <div key={listing.listingId} className={classes.listing}>
            {(localListing.showComingSoonLabel && localListing.status !== 'available') && <div className={classes.ribbon}>
                <span>Soon</span>
            </div>}


            <div className={classes.dragBtn}>
                <img style={{ height: '100%', width: '100%' }} src="/move-grip-icon.svg"></img>
            </div>
            {configScreen && <div className={classes.menuBtn}>
                <img style={{ height: '100%', width: '100%' }} src="/more-menu-icon.svg"></img>
            </div>}
            <div className={classes.listingLogo}>
                <img style={{ height: '100%', width: '100%' }} src={listing.logo || tileLogoPng}></img>
            </div>
            <div className={classes.listingBody}>
                {alreadyInstalled && <span className={classes.connectionIndicator}><CheckIcon style={{ marginRight: '5px', fontSize: '14px' }}></CheckIcon>Connected</span>}
                <span className={classes.listingName}>{listing.name}</span>
                {localListing?.settings?.description && <span className={classes.listingDescription}>
                    {localListing.settings.description.substr(0, 180) + ((localListing.settings.description.length > 180 && "...") || '')}
                </span>}
            </div>
            <div className={classes.listingBtns}>
                {localListing.status === 'available' && customerAuthContext.isAuthenticated &&  !alreadyInstalled && <span className={classes.installBtn} onClick={() => {
                    let accessToken = sessionStorage.getItem("marketplace_access_token");
                    if (mode !== 'preview' && !customerAuthContext.isAuthenticated) {
                       // alert("Please login to install the integration.");
                         window.parent?.postMessage(JSON.stringify({"id": "user_login_required",listingId:localListing.listingId}),"*")
                    } else {
                        if (userActions.canCreateEditTemplateIntegrations && shouldAllowNewIntegration(integrationLimits, userActions, apiCallsUsage, integrationUsage)) {
                         //   window.parent?.postMessage(JSON.stringify({"id": "listing_uninstall_begin",listingId:localListing.listingId}),"*")
                            sendInstallEventToAnalytics(listing);
                            setShowAuthScreens(true);
                             
                        } else {
                            setShowTierUpgradeNotification(true);
                        }
                    }
                }}>{localListing.settings?.installCTA || 'Install'}</span>}
                {localListing.status === 'available' && alreadyInstalled && <span className={classes.installBtn} onClick={() => {
                    setShowUninstallPopup(true);
                      window.parent?.postMessage(JSON.stringify({"id": "listing_uninstall_begin",listingId:localListing.listingId}),"*")
                }}>{'Uninstall'}</span>}
                {(localListing.status !== 'available' || !customerAuthContext.isAuthenticated) && <span className={classes.installBtn} onClick={() => { 
                    setShowRequestForm(true) 
                }}>{'Request'}</span>}
                <Link key={listing.listingId} to={`/listing/${listing.listingId}?mode=${mode}&jwt=${jwt}`} style={{ textDecoration: 'none' }}>
                    <span className={classes.learnMoreBtn}>Learn more</span>
                </Link>
            </div>

        </div> {showAuthScreens && <MarketplaceWorkflowInstallScreen key={localListing.listingId + "auth"}
            template={workflowTemplate} listingId={localListing.listingId} listingName={localListing.name}  screens={localListing.settings?.authScreens.filter(s => {
                return s.appId !== customerAuthContext.orgConnector || s.type === 'custom';
            })} onClose={() => {
                setShowAuthScreens(false);
            }} onFinish={(result) => {
                if (result.status === 'success') {
                    onInstall(result.workflows);
                    console.log(localListing.listingId)
                      window.parent?.postMessage(JSON.stringify({"id": "listing_installation_complete",listingId:localListing.listingId}),"*")
                }else{
                    console.log(localListing.listingId)
                      window.parent?.postMessage(JSON.stringify({"id": "listing_installation_failed",listingId:localListing.listingId}),"*")
                }
                setShowAuthScreens(false);
            }} preview={mode === 'preview'}></MarketplaceWorkflowInstallScreen>}</>
}



const useCategoriesSidebarStyles = makeStyles((theme) => ({
    hiddenRoot: {
        height: '30px',
        width: '20px',
        borderRadius: '1px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 4px 10px 0 rgba(76,141,235,0.1)',
        display: props => props.showSidebar ? 'none' : 'flex',
        cursor: 'pointer',
        alignItems: 'center',
    },
    chevron: {
        height: '20px',
        width: '20px',
    },
    root: {
        display: props => props.showSidebar ? 'flex' : 'none',
        alignItems: 'center',
        flexDirection: 'column',
        width: '25rem',
        "fontFamily": props => props.font ? props.font : 'Mulish',
        "fontStyle": "normal",
        "fontSize": "1.6rem",
        "lineHeight": "2rem",
        "letterSpacing": "-0.5px",
        "color": props => props.color ? props.color : "#303159",
        marginLeft: '3.2rem'
    },
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        "fontWeight": "800",
        justifyContent: 'space-between',
        marginBottom: '1.2rem',
    },
    row: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
        "fontWeight": "bold",
        "fontSize": "1.4rem",
        "lineHeight": "1.8rem",
        "letterSpacing": "-0.4375px",
        "color": "#303159",
        cursor: 'pointer',
        padding: '0.5rem 1rem',
        borderRadius: '.5rem',
        '& .categoryDeleteBtn': {
            height: '24px',
            width: '24px',
            backgroundColor: '#FFFFFF',
            position: 'relative',
            borderRadius: '50%',
            display: 'none',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&:hover': {
            backgroundColor: '#E3E5EF',
            '& .categoryDeleteBtn': {
                display: props => props.isConfigScreen ? 'flex' : 'none',
            },
            '& .catListingCount': {
                display: props => !props.isConfigScreen ? 'flex' : 'none',
            }
        }
    },
    selected: {
        background: '#E3E5EF',
    }

}))


function CategoriesSideBar({ categories, hide, font, color, listings, selectedCategory, onChange, onDelete, showSidebar, onConfigChange, isConfigScreen }) {

    const classes = useCategoriesSidebarStyles({ font, color, showSidebar, isConfigScreen });

    let localCategories = categories || []
    let locallistings = listings || []


    return <>
        <div className={classes.root}>
            <div className={classes.header}>
                <span className={classes.headerText}>Categories</span>
            </div>
            <div className={classes.row + ' ' + (selectedCategory === 'All' ? classes.selected : '')} onClick={() => {
                if ('All' !== selectedCategory) {
                    onChange && onChange('All');
                }
            }}>
                <span>{'All'}</span>
                <span className={"catListingCount"}>{locallistings.length}</span>


            </div>
            {localCategories.map(category => {
                return <div key={category} className={classes.row + ' ' + (selectedCategory === category ? classes.selected : '')} onClick={() => {
                    if (category !== selectedCategory) {
                        onChange && onChange(category);
                    }
                }}>
                    <span>{category}</span>
                    <span className={"catListingCount"}>{locallistings.filter(listing => listing.category === category).length}</span>

                    {isConfigScreen && <span className={"categoryDeleteBtn"} onClick={(e) => {
                        onDelete && onDelete(category)
                        e.preventDefault();
                        e.stopPropagation();
                    }}><CloseIcon color="#595B85"></CloseIcon></span>}
                </div>
            })}
        </div>
    </>
}








const useOverallIntegrationUsageBarStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '20px'
    },
    bar: {
        height: '32px',
        width: '350px',
        // opacity: '0.1',
        borderRadius: '4px',
        backgroundColor: '#4166EA1A',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    usageBar: {
        height: '28px',
        width: '295px',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 4px 10px 0 rgba(76,141,235,0.1)',
        display: 'flex',
        paddingLeft: '10px',
        alignItems: 'center'
    },
    usageText: {
        color: '#595A86',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '-0.14px',
        lineHeight: '18px',
    }
}))


function OverallIntegrationUsageBar({ limitBy, integrationLimit, apiCallsLimit, apisConsumed, integrationsConsumed }) {

    const classes = useOverallIntegrationUsageBarStyle();


    let total = 0;
    let consumed = 0;
    let item = 'Tasks';

    if (limitBy === 'integrations') {
        total = integrationLimit;
        consumed = integrationsConsumed;
        item = 'Integrations'
    } else {

        total = apiCallsLimit;
        apisConsumed.map(a => consumed += parseInt(a.count || 0));
        item = 'API Calls'
    }


    let usageText = '';

    if (total == 0) {
        usageText = `You have 0 ${item} left!`
    } else if (consumed > total) {
        usageText = `You have exceeded the ${item} limit`
    } else {
        usageText = `You have ${total - consumed} of ${total} ${item} left!`
    }


    let shouldShow = !(total === '' || total === -1)





    return <>
        {shouldShow && <div className={classes.root}>
            <div className={classes.bar} title="Upgrade plan to get higher limit">
                <div className={classes.usageBar} style={{ width: (total <= consumed ? '100%' : undefined) }}>
                    <span className={classes.usageText}>{usageText}</span>
                </div>
            </div>
        </div>}
    </>

}

