import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import axios from 'axios';



const client = new ApolloClient({
    uri: process.env.REACT_APP_PUBLIC_CONNECTION_ENDPOINT,
    request: (operation) => {
        const token = sessionStorage.getItem('marketplace_access_token') || sessionStorage.getItem('access_token');
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : ''
            }
        })
    }
});





function getAllConnections() {

    const getAllConnectionsQuery = gql`
    {
        connections{
            connection_id,
            provider,
            user_id,
            created_at,
            authenticated,
            connection_name
        }
    }
`;
    return client
        .query({
            query: getAllConnectionsQuery
        });
}


function getState(state) {
    const getStateQuery = gql`
    {
        state(state:"${state}"){
            completed,
            connection_id,
            state
        }
    }
`;

    return client
        .query({
            query: getStateQuery
        });
}

function createConnection(app_id, connection_name) {

    const createConnectionQuery = gql`
    mutation createConnection{
        createConnection(connection:{provider:"${app_id}",connection_name:"${connection_name}",user_id:"1"}){ connection_id,
            provider,
            user_id,
            connection_name
        }}
`;

    return client
        .mutate({
            mutation: createConnectionQuery
        });
}

function storeConnectionData(state, connectionData) {

    let mutationString = `mutation storeConnectionData{
        storeConnectionData(state:"${state}",connection_data:[`

    for (let i = 0; i < connectionData.length; i++) {
        let d = connectionData[i];

        mutationString += `{field_name:"${d.field_name}",field_value:"${d.field_value}"}`;

        if ((i + 1) < connectionData.length) {
            mutationString += ',';
        }

    }
    mutationString = mutationString + `]){status,error_desc}}`;

    console.log(mutationString)

    const storeConnectionDataMutation = gql(mutationString);

    return client
        .mutate({
            mutation: storeConnectionDataMutation
        });
}


function authenticateConnection(connection_id) {
    const createConnectionQuery = gql`
    mutation authenticateConnection{
        authenticateConnection(connection_id:"${connection_id}"){
            auth_url,
            state
        }}
`;

    return client
        .mutate({
            mutation: createConnectionQuery
        });
}

function getConnectionsForUserApp() {
    const token = sessionStorage.getItem('access_token') || sessionStorage.getItem('marketplace_access_token');
    const op = {
        headers: {
            Authorization: token ? `Bearer ${token}` : ''
        }
    }
    return axios.get('/user?ID=12345', op);
}

function getAuthUrlForState(state) {
    const token = sessionStorage.getItem('access_token') || sessionStorage.getItem('marketplace_access_token');
    const op = {
        headers: {
            Authorization: token ? `Bearer ${token}` : ''
        }
    }
    return axios.post(process.env.REACT_APP_PUBLIC_CONNECTION_STATE_ENDPOINT, { state }, op);
}




function getPreAuthentication(appId, state) {
    const getPreAuthenticationQuery = gql`
    {
        preAuthentication(state:"${state}",appId:"${appId}"){
            required
            inputs{
                control_type
                label
                name
                optional
                picklist_value{
                    key
                    value
                }
                prefix
                suffix
            }
        }
    }
`;

    return client
        .query({
            query: getPreAuthenticationQuery
        });
}


function completePreAuthentication(state, connectionData) {
    let mutationString = `mutation completePreAuthenticationAndGetAuthUrl{
        completePreAuthenticationAndGetAuthUrl(state:"${state}",input:[`

    for (let i = 0; i < connectionData.length; i++) {
        let d = connectionData[i];

        mutationString += `{field_name:"${d.field_name}",field_value:"${d.field_value}"}`;

        if ((i + 1) < connectionData.length) {
            mutationString += ',';
        }

    }
    mutationString = mutationString + `]){auth_url,state}}`;

    console.log(mutationString)

    const storeConnectionDataMutation = gql(mutationString);

    return client
        .mutate({
            mutation: storeConnectionDataMutation
        });
}


export default {
    getConnectionsForUserApp,
    getAllConnections,
    createConnection,
    authenticateConnection,
    storeConnectionData,
    getState,
    getAuthUrlForState,
    getPreAuthentication,
    completePreAuthentication
}
