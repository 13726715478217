
export function shouldAllowNewIntegration(integrationLimit,userActions,apiCalls,integrations){
    if(integrationLimit?.limitBy==='integrations'){
        if(integrationLimit.maximumIntegrations===-1||integrationLimit.maximumIntegrations===''){
            return true
        }else{
            return integrationLimit.maximumIntegrations>integrations;
        }
    }else{
        if(integrationLimit.maximumAPICalls===-1||integrationLimit.maximumAPICalls===''){
            return true
        }else{
            let consumed =0;
            apiCalls.map(a=>consumed +=parseInt(a.count || 0));
            return integrationLimit.maximumAPICalls>consumed;
        }
       
    }
}

export default {
    shouldAllowNewIntegration
}