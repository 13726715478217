import jwt_decode from "jwt-decode";


export default function useGetCustomerAuthContext() {

    let context={
        isAuthenticated:false,
        email:null,
        orgId:null,
        role:null,
        monetizationPlan:null,
        orgConnector:null,
        connection:null
    }
    
    if (process.browser) {
        const access_token = sessionStorage.getItem("marketplace_access_token");
        if (access_token) {
            try{
            const decoded = jwt_decode(access_token);
            context.email=decoded.email;
            context.orgId=decoded.orgId;
            context.role=decoded.role;
            context.monetizationPlan=decoded.monetizationPlan;
            context.orgConnector = decoded.orgConnector;
            context.connection=decoded.connection;
            context.isAuthenticated=true;
            }catch(err){
                console.log(err)
            }
        }
    }
    return context;
}