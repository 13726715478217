import jwt_decode from "jwt-decode";

export default function useGetUserRole(feature) {
    let roles = { userRole: '', features: {}, feature: {}, isSysAdmin: false, isSudo: false, adminUser: null, orgId: null }
    if (process.browser) {
        const access_token = sessionStorage.getItem("access_token");

        if (access_token) {
            const decoded = jwt_decode(access_token);

            if (decoded.role) {
                roles.userRole = decoded.role;
            }
            if (decoded.orgRole) {
                roles.orgRole = decoded.orgRole;
            }

            if (decoded.role.toUpperCase() === 'SYS_ADMIN') {
                roles.isSysAdmin = true;
            }

            roles.isSudo = decoded.isSudo;
            roles.adminUser = decoded.isSudo;

            roles.orgId = decoded.orgId;


            if (decoded.features) {
                decoded.features.map(f => {
                    roles.features[f.name] = {
                        create: f.create,
                        delete: f.delete,
                        update: f.update,
                        view: f.view
                    }
                })
            }

            if (feature && roles.isSysAdmin) {
                roles[feature] = {
                    create: true,
                    delete: true,
                    update: true,
                    view: true
                }
                roles.isSysAdmin = true;
            } else if (feature && roles.features) {
                roles[feature] = roles.features[Object.keys(roles.features).filter(key => key === feature)[0]];
            } else if (feature) {
                roles[feature] = {
                    create: false,
                    delete: false,
                    update: false,
                    view: false
                };
            }
        }
        return roles;
    } else {
        if (feature) {
            roles[feature] = {}
        }
        return roles
    }



}