//import ApolloClient from 'apollo-boost';
import { ApolloProvider, gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';



const httpLink = new HttpLink({ uri: `https://${process.env.REACT_APP_PUBLIC_API_HOST}/app/apifuse-api/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = sessionStorage.getItem('access_token')
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });

    return forward(operation);
})


const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(authMiddleware, httpLink)
});


function getMarketplaceForSubdomain(subdomain, customDomain) {
    const getMarketplaceQuery = gql`
    {
        marketplacePublicBySubdomain(subdomain:"${subdomain || ''}",customDomain:"${customDomain || ''}"){
            organizationId
           categories
           plans{
             name
             connectors
             userActions{
                canViewIntegrations
                canCreateEditTemplateIntegrations
                canCreateIntegrationWithBuilder
                canViewLogs
             }
             tierPopupConfig{
                title
                infoText
                font
                actionCta
              }
             integrationLimits{
                limitBy
                maximumIntegrations
                maximumAPICalls
             }
           }
           subdomain
           loginRedirectUrl
           authenticatedViewOnly
           disableTemplatableIntegrations
           preview{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
            config{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
         }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}

function getPublicMarketplace(orgId) {
    const getMarketplaceQuery = gql`
    {
        marketplacePublic(orgId:"${orgId}"){
            organizationId
           categories
           plans{
             name
             connectors
             tierPopupConfig{
                title
                infoText
                font
                actionCta
              }
             userActions{
                canViewIntegrations
                canCreateEditTemplateIntegrations
                canCreateIntegrationWithBuilder
             }
             integrationLimits{
                limitBy
                maximumIntegrations
                maximumAPICalls
             }
           }
           subdomain
           loginRedirectUrl
           authenticatedViewOnly
           preview{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
            config{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
         }
         getAllListingsPublic(orgId:"${orgId}"){
            name
            organizationId
            logo
            type
            category
            listingId
            status
            requestInfoText
            requestButtonText
            requestTitle
            showComingSoonLabel
          }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}






function getAllWorkflows() {

    const getAllConnectionsQuery = gql`
    {
        getAllWorkflows{
            workflow_id,
            workflow_name,
            user_id,
            created_at,
            is_published
        }
    }
`;





    return client
        .query({
            query: getAllConnectionsQuery,
            fetchPolicy: 'no-cache'
        });
}

function getAllPublicConnectors() {

    const getAllConnectionsQuery = gql`
    {
        getAllConnectorsPublic{
            name,
            slug,
            logo,
            category,
            status
        }
    }
`;

    return client
        .query({
            query: getAllConnectionsQuery,
            fetchPolicy: 'no-cache'
        });
}

function getAllConnections() {
    const getAllConnectionsQuery = gql`
    {
        getAllConnections{
            connection_id,
            provider,
            user_id,
            authenticated,
            created_at,
            connection_name
          }
    }
`;

    return client
        .query({
            query: getAllConnectionsQuery,
            fetchPolicy: 'no-cache'
        });
}

function getS3SignedUrl(fileName, fileType) {

    const getSignedUrlQuery = gql`
    {
        getSignedUrl(fileType: "${fileType}", fileName: "${fileName}") {
          signedRequest
          url
          fields{key value}
        }
      }
`;
    return client
        .query({
            query: getSignedUrlQuery
        });
}

function getMarketplace() {
    const getMarketplaceQuery = gql`
    {
        marketplace{
            organizationId
           categories
           plans{
             name
           }
           subdomain
           preview{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             showSidebar
             showHeader
             listingViewType
             listingOrder
           }
            config{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             showSidebar
             showHeader
             listingViewType
             listingOrder
           }
           builder{
              preview{
                font
                header{backgroundColor fontColor}
                sideBar{backgroundColor fontColor}
                contentSection{backgroundColor fontColor}
              }
               config{
                font
                header{backgroundColor fontColor}
                sideBar{backgroundColor fontColor}
                contentSection{backgroundColor fontColor}
              }
          }
         }
         getAllListings(organizationId:"1"){
            name
            organizationId
            logo
            type
            category
            listingId
          }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery,
            fetchPolicy: "no-cache"
        });
}

const getConfigScreens = (workflowId) => {

    const GET_CONFIG_SCREENS = gql`
{
    getConfigScreens(workflow_id:"${workflowId}"){
        authScreens{
            app_id
            action_type_id
            step_id
        }
        fieldScreens{
            stepId
            appId
            stepTypeId
            fields{
                fieldId
                isGroupField
                fieldItems{
                    displayName
                    name    
                }
            }           
        }
    }
    getAllConnectors{
        app_logo
        app_id
        app_name
        app_desc
      }
}
`

    return client
        .query({
            query: GET_CONFIG_SCREENS,
            fetchPolicy: "no-cache"
        });
}

function getOrganizationAccountById(orgId) {
    const GET_ORG = gql`
    {
        getOrganizationById(orgId:${orgId}){
            orgId
            name
            subdomain
            customdomain
            users{
              firstname
              lastname
              email
              roleId
            }
            apis{
              apikey
              secret
              isEnabled 
            }
            roles{
              roleId
              name
              isDefault
              permissions{
                name
                create
                delete
                update
                  view
              }
            }
          }
          marketplacePublic(orgId:"${orgId}"){
            integrationLimits{
                connectors
            }
          }
          getAllCustomersForOrg(orgId:${orgId}){
            firstname
            lastname
            email
            externalId
            userId
            monetizationPlan
            connectionId
          }
    }
    `

    return client
        .query({
            query: GET_ORG,
            fetchPolicy: "no-cache"
        });

}

function getListingData(listingId) {
    const getMarketplaceQuery = gql`
    {
        marketplace{
            organizationId
            categories
            plans{
              name
            }
            subdomain
            preview{
              font
              title
              logo
              requestIntegration
              header{backgroundColor fontColor}
              sideBar{backgroundColor fontColor}
              contentSection{backgroundColor fontColor}
              showSidebar
              showHeader
              listingOrder
            }
             config{
              font
              title
              logo
              requestIntegration
              header{backgroundColor fontColor}
              sideBar{backgroundColor fontColor}
              contentSection{backgroundColor fontColor}
              showSidebar
              showHeader
              listingOrder
            }
        }
        getListingById(listingId:"${listingId}"){
            listingId
            name
            logo
            category
            type
            organizationId
            settings{
                description
                slug
                screenshots
                videos
                sourceWorkflow
                description
                installCTA
                monetizationPlan
                pricing
                customize
                externalLink
                authScreens{
                    appId
                    appName
                    appLogo
                    title
                    description
                    buttonCTA
                    type
                    fieldId
                    stepId
                    fieldItems{
                        displayName
                        name
                    }
                }
            }
        }
        getAllWorkflows(is_published:true){
            workflow_id
            workflow_name
            updated_at
        }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery,
            fetchPolicy: "no-cache"
        });
}

function getAllOrgs() {

    const getAllConnectionsQuery = gql`
    {
        getAllOrganization{
            orgId
            name
        }
    }
`;

    return client
        .query({
            query: getAllConnectionsQuery,
            fetchPolicy: 'no-cache'
        });
}

function doQuery(query, fetchPolicy) {
    return client
        .query({
            query: query,
            fetchPolicy: fetchPolicy || 'no-cache'
        });
}

function doMutate(mutation, variables) {
    return client
        .mutate({
            mutation: mutation,
            variables
        });
}



export default {
    doQuery,
    doMutate,
    getS3SignedUrl,
    getMarketplace,
    getConfigScreens,
    getAllWorkflows,
    getListingData,
    getAllOrgs,
    getOrganizationAccountById,
    getAllConnections,
    getAllPublicConnectors,
    getMarketplaceForSubdomain,
    getPublicMarketplace
}