import React, { useState } from 'react';
import Button from '../Button';


export default function NewIntegrationModal({ onSave, onCancel, loading }) {

    const [name, setName] = useState('');

    return (
        <>
            <style jsx>
                {`

               .overlay{
                   width:100vw;
                   height:100vh;
                   opacity: 0.9;
                   background-color: #526E98;
                   display:flex;
                   align-items:center;
                   justify-content:center;
                   position:fixed;
                   top:0;
                   left:0;
                   z-index:10000;
               }
               .modal{
                    width: 50.3rem;
                    border: 0.1rem solid #EDEDED;
                    border-radius: 0.4rem;
                    background-color: #FFFFFF;
                    box-shadow: 0 1.4rem 4.3rem 0 #2D2D68;
                    display:flex;
                    flex-direction:column;

               }
               .header{
                   width:100%;
                   height:7.2rem;
                   display:flex;
                   flex-direction:row;
                   align-items:center;
                   justify-content:flex-start;
                   color: #303159;
                   font-family: "Mulish";
                   font-weight:800;
                   font-size: 2.4rem;
                   letter-spacing: -0.018rem;
                   line-height: 3rem;
                   border-bottom: 1px solid #979797;
                   padding-left: 2.5rem;
               }
               .body{
                    margin-top:2.3rem;
                    width:100%;
                    display:flex;
                    flex-direction:column;
                    align-items:flex-start;
                    justify-content:flex-start;
                    padding:2.5rem;
                }
                .label{
                    color: #57587A;
                    font-family: Mulish;
                    font-size: 1.4rem;
                    letter-spacing: 0;
                    line-height: 1.7rem;
                }
                .input{
                    margin-top:0.75rem;
                    width:45.2rem;
                    height:5rem;
                    border:none;
                    outline:none;
                    padding:1.6rem;
                    display:flex;
                    align-items:center;
                    border: 0.1rem solid #C6CBDF;
                    border-radius: 0.4rem;
                    background-color: #FFFFFF;
                    color: #2C2E4C;
                    font-family: "Muli Bold";
                    font-size: 1.4rem;
                    letter-spacing: -0.05rem;
                    line-height: 1.7rem;
                }
                .action{
                    width:100%;
                    margin-top:3.3rem;
                    margin-bottom:3.3rem;
                    display:flex;
                    align-items:center;
                    justify-content:space-between;
                    padding-left:2.5rem;
                    padding-right:2.5rem;
                }
                .btn{
                    height: 4.4rem;
                    width: 21.3rem;
                    border-radius: 0.4rem;
                    font-family: "Muli Bold";
                    font-size: 1.4rem;
                    letter-spacing: -0.014rem;
                    line-height: 1.8rem;
                    cursor:pointer;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                }
                .cancel{
                    color: #486CBB;
                    background-color: #E3E8F9;
                }
                .save{
                    color: #FFFFFF;
                    background: linear-gradient(224.43deg, #4166EA 0%, #3D42BF 100%)
                }
                
            `}
            </style>
            <div className="overlay">

                <div className="modal">
                    <div className="header">
                        <span>Add new integration</span>
                    </div>
                    <div className="body">
                        <label className="label">Name your integration</label>
                        <input value={name} onChange={e => setName(e.target.value)} type="text" className="input"></input>
                    </div>
                    <div className="action">

                        <Button text={"Cancel"} type={"secondary"} onClick={onCancel}></Button>
                        <Button text={"Save"} type={"primary"} loading={loading} onClick={() => { onSave(name) }}></Button>


                    </div>
                </div>
            </div>

        </>
    );
}