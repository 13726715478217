import React, { useState, useRef } from 'react';
import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';


import useStyle from "./styles";

import useGetCustomerAuthContext from "../../hooks/useGetCustomerAuthContext";
import useOutsideClickAlert from "../../hooks/useOutsideClickAlert";
import service from "../../lib/apifuseGraphQL";
import Overlay from "../Overlay";
import Loader from "../Loader"





export default function RequestThisIntegrationForm({ listing, onClose }) {

    const classes = useStyle({});

    const [loading, setLoading] = useState(false);

    const [reason, setReason] = useState('');

    const boxRef = useRef(null);

    const customerAuthCtx = useGetCustomerAuthContext();

    useOutsideClickAlert(boxRef, () => {
        onClose && onClose();
    })

    async function sendRequestIntegration() {

        setLoading(true)
        if (customerAuthCtx.orgId) {
            try {
                const createLeadRq = gql`
                mutation createLead($reason: String){
                    createLead(
                        lead:{orgId:"${customerAuthCtx.orgId || ''}",
                        email:"${customerAuthCtx.email || ''}",
                        company:"${customerAuthCtx.email || ''}",
                        reason:$reason,
                        requestedIntegration:"${listing.name}"}
                        ){
                           leadId,
                            orgId
                    }
                }
            `;

                const rs = await service.doMutate(createLeadRq, {
                    reason
                });
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
                onClose && onClose();
            }
        } else {
            setLoading(false);
            onClose && onClose();
        }



    }

    return <>
        <Overlay>
            <div className={classes.root} ref={boxRef}>
                <div className={classes.closeBtn} onClick={() => {
                    onClose && onClose();
                }}>
                    <CloseIcon className={classes.closeBtnIcon}></CloseIcon>
                </div>
                <div className={classes.header}>
                    <span>{listing.requestTitle || 'Request this integration'}</span>
                </div>
                <div className={classes.info}>
                    <span>{listing.requestInfoText || 'Please tell us how you plan to use this integration, so we can better plan implementing it.'}</span>
                </div>
                <div className={classes.userInput}>
                    <textarea className={classes.textareaInput} onChange={e => setReason(e.target.value)} value={reason} maxLength={4000}></textarea>
                </div>
                <div className={classes.submit}>
                    <span className={classes.installBtn} onClick={() => {
                        sendRequestIntegration()
                    }}>
                        {listing.requestButtonText || 'Send Request'}
                        {loading && <Loader style={{ marginLeft: '1rem' }}></Loader>}
                    </span>
                </div>
            </div>
        </Overlay>
    </>
}

