import React, { useState, useEffect, useRef } from 'react';
import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import Loader from "../Loader"
import Overlay from '../Overlay';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_PUBLIC_WOKFLOW_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = sessionStorage.getItem('marketplace_access_token');

    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });

    return forward(operation);
})

const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(authMiddleware, httpLink)
});



const useUninstallListingStyle = makeStyles((theme) => ({
    box: {
        minHeight: '300px',
        width: '503px',
        border: '1px solid #EDEDED',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 14px 43px 0 #2D2D68',
        display: 'flex',
        flexDirection: 'column',
        padding: '2rem',
        position: 'relative',
        zIndex: '1000000'
    },
    closeIcon: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        cursor: 'pointer'
    },
    screenHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    screenHeaderText: {
        color: '#303159',
        fontFamily: "Mulish",
        fontSize: '20px',
        fontWeight: '800',
        letterSpacing: '-0.63px',
        lineHeight: '25px',
        flex: 1,
    },
    screenHeaderIndex: {
        color: '#595B85',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '-0.11px',
        lineHeight: '18px',
        marginTop: '50px',
    },
    footer: {
        flex: 1,
        marginTop: '2rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    prevBtn: {
        height: '44px',
        width: '213px',
        color: '#486CBB',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '14px',
        letterSpacing: '-0.14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        backgroundColor: '#E3E8F9',
        cursor: 'pointer'
    },
    finishBtn: {
        marginLeft: '2.7rem',
        height: '44px',
        width: '213px',
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '14px',
        letterSpacing: '-0.14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        background: 'linear-gradient(224.43deg, #4166EA 0%, #3D42BF 100%)',
        cursor: 'pointer'
    },
}))




export default function UninstallListing({ onClose, installedWorkflows, onUninstall }) {

    const classes = useUninstallListingStyle()


    const [deletingWorkflows, setDeletingWorkflows] = useState(false);


    function handleDeleteWorkflows() {
        setDeletingWorkflows(true)
        const deleteWorkflowQuery = `
        mutation deleteListingWorkflow{
            deleteListingWorkflow(workflow_ids:${JSON.stringify(installedWorkflows.map(i => i.workflow_id))}){
                status,
                message,
                data{
                    status
                    message
                    workflowId
                }
            }
        }
    `;

        return client
            .mutate({
                mutation: gql(deleteWorkflowQuery)
            }).then(res => {
                if (res.data.deleteListingWorkflow && res.data.deleteListingWorkflow.status === 'success') {

                    let removedIds = res.data.deleteListingWorkflow.data.filter(w => w.status === 'success').map(w => w.workflowId);
                   
                    onUninstall({
                        status: res.data.deleteListingWorkflow.status,
                        workflowIds: removedIds
                    })
                } else {

                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setDeletingWorkflows(false)
            });
    }


    return <Overlay>
        <div className={classes.box}>
            <div className={classes.screenHeader}>
                <span className={classes.screenHeaderText}>{"Uninstall ?"}</span>
                <span className={classes.screenHeaderIndex}>{"This will stop your integration, are you sure you want to uninstall?"}</span>
            </div>

            <div className={classes.footer}>
                <div className={classes.prevBtn} onClick={() => {
                    onClose && onClose()
                }}>{"Cancel"}</div>

                <div className={classes.finishBtn} onClick={() => {
                    if (!deletingWorkflows) {
                        handleDeleteWorkflows();
                    }
                }}>
                    {!deletingWorkflows && <> {"Uninstall"}</>}
                    {deletingWorkflows && <Loader></Loader>}
                </div>
            </div>
        </div>
    </Overlay>
}