import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import Home from "./pages/Home"
import Listing from "./pages/Listing"
import SlugListing from "./pages/SlugListing"
import Loader from "./components/Loader";
import { useEffect, useState } from "react";

import service from "./lib/apifuseGraphQL"

function App() {

  const [initialLoad,setInitialLoad]=useState(false);
  const [marketplace,setMarketplace]=useState(null);
  const [mode,setMode]=useState('live');



  useEffect(()=>{

    if(!initialLoad){
      let host = window.location.host;
            let subdomain = '';
            let customDomain = ''
            if (!window.location.host.includes("localhost")) {
                if (host.includes(".apifuse.io")) {
                    subdomain = host.replace(".apifuse.io", "")
                } else {
                    customDomain = host;
                }
            } else {
                subdomain = 'n3wlab';
                //customDomain = 'marketplace.n3wlab.com'
            }


           service.getMarketplaceForSubdomain(subdomain, customDomain).then(res => {
              setMarketplace(res.data.marketplacePublicBySubdomain);
            }).catch(err => {
              console.log("err")
            }).finally(()=>{

              if (window.location.search) {
    
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
    
                console.log(params)
    
                if(params && params.mode&& params.mode.toLowerCase()==='preview'){
                  setMode(params.mode.toLowerCase())
                }
              
                if(params && params.jwt && params.jwt !=='null'){
                    doCustomerLogin(params.jwt);
                }else{
                    setInitialLoad(true);
                }
              }else{
                  setInitialLoad(true);
              }
  
            })


           
            

    }else{
     

  }

  },[initialLoad])

  async function doCustomerLogin(token) {
    try {
        const url = `https://${process.env.REACT_APP_PUBLIC_API_HOST}/auth/customer/login`;
        //const url = "/api/auth/login";
        const response = await fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
        });

        const data = await response.json();

        if (data && data.status === 'success') {
            localStorage.setItem("marketplace_access_token", data.token);
            sessionStorage.setItem("marketplace_access_token", data.token);
            //window.location = "/app"
        } else {
            // alert("login failed");
        }
    } catch (err) {
        console.log(err);
        // alert("login failed");
    } finally {
        setInitialLoad(true);
    }
}


  let redirecting=true;

  if(initialLoad){
    if(marketplace && marketplace.authenticatedViewOnly && marketplace.loginRedirectUrl){
      let token=sessionStorage.getItem("marketplace_access_token");
      if(!token){
          window.location = marketplace.loginRedirectUrl+"?return_to="+window.location.href;
      }else{
        redirecting=false;
      }
    }else{
      redirecting=false;
    }
  }



  return (
    <>{!initialLoad && redirecting && <div style={{width:'100vw',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Loader></Loader>
      </div>}
    {initialLoad && !redirecting &&
    <Router forceRefresh={true}>
      <Switch>
        <Route path="/listing/:listingId">
          <Listing marketplace={marketplace} mode={mode}></Listing>
        </Route>
        
        <Route path="/:slug" exact={true}>
           <SlugListing marketplace={marketplace} mode={mode}></SlugListing>
        </Route>
       
        <Home marketplace={marketplace} mode={mode}></Home>
      </Switch>

    </Router>}
    </>
  );
}

export default App;
