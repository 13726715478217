import React, { useState, useRef, useEffect } from 'react';

import produce from "immer"

import { makeStyles } from '@material-ui/core/styles';

import { FormControl, MenuItem,CircularProgress,
    InputLabel, Select, TextField} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Loader from "../Loader"
import SelectCustomerRender from "../SelectCustomRender";
import useGetCustomerAuthContext from "../../hooks/useGetCustomerAuthContext";
import useOutsideAlerter from '../../hooks/useOutsideClickAlert';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import axios from 'axios';



import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';


const CONNECTION_HTTP_LINK = new HttpLink({ uri: process.env.REACT_APP_PUBLIC_CONNECTION_ENDPOINT });


const WORKFLOW_HTTP_LINK = new HttpLink({ uri: process.env.REACT_APP_PUBLIC_WOKFLOW_ENDPOINT });

const AUTH_MIDDLEWARE = new ApolloLink((operation, forward) => {
    const token = sessionStorage.getItem('marketplace_access_token')
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });
    return forward(operation);
})


const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(AUTH_MIDDLEWARE, CONNECTION_HTTP_LINK)
});

const workflowClient = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(AUTH_MIDDLEWARE, WORKFLOW_HTTP_LINK)
});


function loadExtendedSchema(data) {
    const token = sessionStorage.getItem('marketplace_access_token');
    const op = {
        headers: {
            Authorization: token ? `Bearer ${token}` : ''
        }
    }
    return axios.post(process.env.REACT_APP_PUBLIC_EXTENDED_API_ENDPOINT, data, op);
}

function loadPicklist(data) {
    // {
    //     "connectionId":"d7407aed-b9e7-4929-a4ea-f6709f14f0d3",
    //     "pickListName":"gocanvas_app_picklist"
    //     "pickListParams":{}

    // }
    const token = sessionStorage.getItem('access_token') || sessionStorage.getItem('marketplace_access_token');
    const op = {
        headers: {
            Authorization: token ? `Bearer ${token}` : ''
        }
    }
    return axios.post(process.env.REACT_APP_PUBLIC_PICKLIST_ENDPOINT, data, op);
}



function createConnection(app_id, connection_name) {

    const createConnectionQuery = gql`
    mutation createConnection{
        createConnection(connection:{provider:"${app_id}",connection_name:"${connection_name}",user_id:"1"}){ connection_id,
            provider,
            user_id,
            connection_name
        }}
`;

    return client
        .mutate({
            mutation: createConnectionQuery
        });
}


function deleteConnection(connectionId) {

    const deleteConnectionQuery = gql`
    mutation deleteConnection{
        deleteConnection(connectionId:"${connectionId}"){ 
            status
        }
    }
`;

    return client
        .mutate({
            mutation: deleteConnectionQuery
        });
}

function authenticateConnection(connection_id) {
    const createConnectionQuery = gql`
    mutation authenticateConnection{
        authenticateConnection(connection_id:"${connection_id}"){
            auth_url,
            state
        }}
`;

    return client
        .mutate({
            mutation: createConnectionQuery
        });
}

function getAllConnections() {

    const getAllConnectionsQuery = gql`
    {
        connections{
            connection_id,
            provider,
            user_id,
            created_at,
            authenticated,
            connection_name
        }
    }
`;



    return client
        .query({
            query: getAllConnectionsQuery,
            fetchPolicy:'no-cache'
        });
}

function getConnection(connectionId){

    const getAllConnectionsQuery = gql`
    {
        connection(connection_id:"${connectionId}"){
            connection_id,
            provider,
            user_id,
            created_at,
            authenticated,
            connection_name
        }
    }
`;



    return client
        .query({
            query: getAllConnectionsQuery,
            fetchPolicy:'no-cache'
        });
}


function createWorkflow(listingId,appConnections,code) {

    let appStr =appConnections.map(app=>{
        return `{appId:"${app.appId}",connectionId:"${app.connectionId}"}`
    })

    const updateString = `
    mutation createListingWorkflow{
        createListingWorkflow(listingId:"${listingId}",connections:[${appStr.join(",")}],code:${JSON.stringify(code)}){
            code,
            message,
            data{
                workflow_id,
                workflow_name,
                user_id,
                created_at,
                is_published,
                listingId
            }
        }}
`;

    const createWorkflowQuery = gql(updateString);

    return workflowClient
        .mutate({
            mutation: createWorkflowQuery
        })
}






const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw',
        display: 'flex',
        justifyContent:'center',
        padding:'100px',
    },
    box:{
        minHeight:'400px',
        width: '503px',
        border: '1px solid #EDEDED',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 14px 43px 0 #2D2D68',
        display: 'flex',
        flexDirection: 'column',
        padding: '2rem',
        position:'relative'
    },
    previewLabel: {
        color: '#57587A',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '18px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    closeIcon: {
        position:'absolute',
        top:'20px',
        right:'20px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        cursor: 'pointer'
    },
    screenHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection:'column',
        alignItems: 'flex-start',
    },
    screenHeaderText: {
        color: '#303159',
        fontFamily: "Mulish",
        fontSize: '20px',
        fontWeight: '800',
        letterSpacing: '-0.63px',
        lineHeight: '25px',
        flex: 1,
    },
    screenHeaderIndex: {
        color: '#595B85',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '-0.11px',
        lineHeight: '18px',
        marginTop:'10px',
    },
    positionIndicator: {
        marginTop: '4rem',
        marginBottom:'3rem',
        display: 'flex',
    },
    positionTab: {
        height: '5px',
        backgroundColor: '#D8D8D8',
        flex: 1
    },
    positionTabActive: {
        backgroundColor: '#0958E5',
    },
    description: {
        marginTop: '2rem',
        color: '#57587A',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '18px',
    },
    connectionBox: {
        marginTop: '1rem',
        height: '84px',
        width: '453px',
        border: '1px solid #C6CBDF',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer'
    },
    connectionBoxItem: {
        height: '80px',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        position:'relative',
        border: '1px solid #c6cbdf',
    },
    connectionMoreIconWrap:{
        padding:'5px',
        marginRight:'10px',
    },
    connectionMoreIcon:{
        fontSize:'25px'
    },
    logoBox: {
        height: '52px',
        width: '52px',
        marginLeft: '1.6rem'
    },
    logo: {
        height: '100%',
        width: '100%'
    },
    connectionName: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginLeft: '1.5rem',
    },
    connectionLabel: {
        color: '#ACADBD',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '-0.5px',
        lineHeight: '18px',
    },
    connectionNameText: {
        marginTop: '0.5rem',
        color: '#3E405C',
        fontFamily: 'Mulish',
        fontSize: '20px',
        fontWeight: 'bold',
        letterSpacing: '-0.71px',
        lineHeight: '25px',
    },
    footer: {
        flex: 1,
        marginTop:'2rem',
        display: 'flex',
        justifyContent:'space-between',
        alignItems: 'flex-end',
    },
    prevBtn: {
        height: '44px',
        width: '213px',
        color: '#486CBB',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '14px',
        letterSpacing: '-0.14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        backgroundColor: '#E3E8F9',
        cursor: 'pointer'
    },
    finishBtn: {
        marginLeft: '2.7rem',
        height: '44px',
        width: '213px',
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '14px',
        letterSpacing: '-0.14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        background: 'linear-gradient(224.43deg, #4166EA 0%, #3D42BF 100%)',
        cursor: 'pointer'
    },
    completBtn:{
        height: '44px',
        width: '100%',
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '14px',
        letterSpacing: '-0.14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        background: '#20bb86',
        cursor: 'pointer'
    },
    installBtn: {
        marginTop: '3.2rem',
        height: '44px',
        width: '413px',
        borderRadius: '4px',
        marginLeft:'auto',
        marginRight:'auto',
        backgroundColor: '#20BB86',
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: 'bold',
        fontSize: '14px',
        letterSpacing: '-0.14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    tryagainBtn:{
        marginTop: '3.2rem',
        height: '44px',
        width: '413px',
        borderRadius: '4px',
        marginLeft:'auto',
        marginRight:'auto',
        background: 'linear-gradient(224.43deg, #4166EA 0%, #3D42BF 100%)',
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: 'bold',
        fontSize: '14px',
        letterSpacing: '-0.14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    stepInputGroup:{
        marginBottom: '2rem',
        color: '#57587A',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '18px',
        display:'flex',
        flexDirection:'column',
    },
    stepInput:{
        marginTop:'1rem',
        outline:'none',
        width:'100%',
        height:'4rem',
        borderRadius:'4px',
        paddingLeft:'1rem',
        border: '2px solid #C6CBDF',
    },
    selectItem:{
        padding:'10px 10px',
        "fontFamily":props=>props.font||'font',
        "fontStyle":"normal",
        "fontWeight":"bold",
        "fontSize":"1.4rem",
        "lineHeight":"18px",
        "color":"#57587A"
    },
    select:{
        backgroundColor:'#FFFFFF'
    },
    selectAppItemText: {
        marginLeft: "1.6rem",
        color: '#303159',
        fontFamily: props=>props.font||'Mulish',
        fontWeight:'bold',
        fontSize: '1.4rem',
        letterspacing: 0,
        lineHeight: '1.9rem'
    },
}))




const Overlay = React.forwardRef(({ children, zIndex, style }, ref)=>{
    const zIndexOverlay = zIndex || 1000;
    const zIndexChild = zIndexOverlay + 1;

    let defaultStyle = {};

    if (style) {
        defaultStyle = style;
    }

    return   <>
    <style jsx>
        {`
              .overlay{
                width:100vw;
                height:100vh;
                opacity: 0.9;
                background-color: #526E98;
                display:flex;
                align-items:center;
                justify-content:center;
                position:fixed;
                top:0;
                left:0;
                z-index:${zIndexOverlay};
                overflow:auto;
            }
            @media only screen and (min-height: 800px) {
                .overlay{
                    height:100%;
                }
            }
            @media only screen and (min-width: 800px) {
                .overlay{
                    width:100%;
                }
            }
          
            .overlay-child{
                opacity: 1;
                position:fixed;
                top:0;
                left:0;
                width:100vw;
                height:100vh;
                z-index:${zIndexChild};
                overflow:auto;
            }
            `}
    </style>
    <div className="overlay-child">{children}</div>
    <div className="overlay" style={{ ...defaultStyle }} ref={ref}>
    </div>
</>
})


/*
Show Loader
Check the connections in screens object
If the screen 

*/

export default function MarketplaceWorkflowInstallScreen({ screens, preview, onClose, onFinish,listingId, listingName,template}) {


    const classes = useStyles();

    const customerAuthContext = useGetCustomerAuthContext();
    
    const [screenIndex, setScreenIndex] = useState(0);

    const [authorizedScreens, setAuthorizedScreens] = useState(screens);

    const [connectionInProgress, setconnectionInprogress] = useState(false);

    const [connectionWindowRef, setConnectionWindowRef] = useState();

    const [initialLoad, setInitialLoad] = useState(false);

    const [userConnections, setUserConnections] = useState([]);

    const [createWorkflowLoading,setCreateWorkflowLoading]=useState(false);
    const [integrationCompleteWithError,setIntegrationCompleteWithError]=useState(false);

    const [workflow,setWorkflow]=useState(template);


    const [integrationComplete, setIntegrationComplete] = useState(false);

    const [installedWorkflows,setInstalledWorkflows]=useState([]);

    const [connectionPollingId,setConnectionPollingId]=useState();


    function shouldEnableNextBtn(){
        if(authorizedScreens[screenIndex] && authorizedScreens[screenIndex].type==='custom'){
           

            let currentTemplate = workflow.filter(w=>w.templateId===authorizedScreens[screenIndex].templateId)[0];
           
            if(currentTemplate && currentTemplate.code && currentTemplate.code.steps){
              
                let currentStep = currentTemplate.code.steps.filter(s=>s.stepId===authorizedScreens[screenIndex].stepId)[0];

                let allowed=true;
               
                for(let i=0;i< authorizedScreens[screenIndex].fieldItems.length;i++){
                    if(currentStep.stepConfig && currentStep.stepConfig[authorizedScreens[screenIndex].fieldItems[i].name]){
                        allowed= allowed && true;
                    }else{
                        allowed= false;
                    }
                }
                return allowed;
            }else{
                return false;
            }
        }else{
            return  (authorizedScreens[screenIndex]?.connectionId)?true:false;
        }
    }

    useEffect(()=>{
        if(!connectionInProgress && connectionPollingId){
            clearInterval(connectionPollingId)
        }
    },[connectionInProgress,connectionPollingId])


    useEffect(() => {
        if (!initialLoad) {
            if(!preview && authorizedScreens[screenIndex] && authorizedScreens[screenIndex].appId){
                setconnectionInprogress(true);
                createNewConnection({ appId: authorizedScreens[screenIndex].appId,appVersion:authorizedScreens[screenIndex].appVersion||1, connectionName: screens[screenIndex].appName },0);
              
            }
            
            getAllConnections().then((rs) => {
                setUserConnections(rs.data.connections);
            }).catch(err=>{
                
            }).finally(() => {
                setInitialLoad(true);
            })
        }
    }, [initialLoad]);


    function refreshConnectionList(){
        getAllConnections().then((rs) => {
            setUserConnections(rs.data.connections);
        }).finally(() => {
            console.log("refreshed")
        })
    }

    const createNewConnection = (connection,conScreenIndex )=> {

        const { stepId, appId, connectionName } = connection;

        if(connectionPollingId){
            clearInterval(connectionPollingId);
        }

        createConnection(appId, connectionName).then(result => {

            let connectionPollingInterveralId =  setInterval(()=>{
                getConnection(result.data.createConnection.connection_id).then(res=>{
                    if(res?.data?.connection?.authenticated){
                        if (connectionWindowRef && connectionWindowRef.close) {
                            connectionWindowRef.close();
                        }
                        console.log("connection completed")

                        const newauthorizedScreens = produce(authorizedScreens, draftState => {
                            draftState[conScreenIndex].connectionId = result.data.createConnection.connection_id;
                        })

                        const newUserConnections = produce(userConnections, draftState => {
                            draftState.push(result.data.createConnection);
                        })

                        setUserConnections(newUserConnections)
                        setAuthorizedScreens(newauthorizedScreens);

                        setconnectionInprogress(false);
                  
                    }
                }).catch(err=>{
                    console.log(err.response?.data)
                })
            },1000)
            setConnectionPollingId(connectionPollingInterveralId)

            authenticateConnection(result.data.createConnection.connection_id).then(authResult => {

                let host = window.location.host;
                let scheme = window.location.protocol;

                let launchUrl = `${scheme}//${host}/connection/temp/new/${authResult.data.authenticateConnection.state}`

                let localConnectionWindowRef = window.open(launchUrl, 'targetWindow',
                    'toolbar=no,location=no,  status=no,menubar=no,scrollbars=yes, resizable=yes,width=700,height=700');

                setConnectionWindowRef(localConnectionWindowRef);


                window.addEventListener("message", (event) => {
                    if(event){
                        console.log(event.data)
                    }
                    
                    if (event && event.data === 'workflow_new_connection_completed') {
                       
                    }

                }, false);
            }).catch(err => {
                console.log(err)
            }).finally(() => {
               // setconnectionInprogress(false);
            })

        }).catch(err => {
            console.log(err)
        //    setconnectionInprogress(false);
            alert("error occurred")
        }).finally(() => {
            //  setShowProgress(false);
        });
    }

    async function completeListingWorkflow(){
        if(!preview){
            let connectedApps = authorizedScreens.filter(a=>(a.appId!=customerAuthContext.orgConnector && a.type !='custom'))
            .map(a=>({appId:a.appId,connectionId:a.connectionId}))

            if(customerAuthContext.orgConnector && customerAuthContext.connection){
                connectedApps.push({appId:customerAuthContext.orgConnector,connectionId:customerAuthContext.connection})
            }

    
            try{
                setCreateWorkflowLoading(true);
                let res =  await createWorkflow(listingId,connectedApps,JSON.stringify(workflow));
                if(res.data.createListingWorkflow && res.data.createListingWorkflow.code==='success'){
                    setIntegrationComplete(true);
                    setInstalledWorkflows(res.data.createListingWorkflow.data)
                }else{
                    setIntegrationCompleteWithError(true)
                }
                setCreateWorkflowLoading(false);
                window.parent?.postMessage(JSON.stringify({"id": "listing_installation_complete",listingId,listingName}),"*")
              //  onFinish && onFinish({status:'success',workflows:res.data.createListingWorkflow.data});

                
            
            }catch(err){
                console.log(err)
                window.parent?.postMessage(JSON.stringify({"id": "listing_installation_failed",listingId,listingName}),"*")
              //  onFinish && onFinish({status:'failed',workflows:[]});
            }
        } else{
            onFinish && onFinish({status:'success',workflows:[]});
        }

    }

    let screenRender = null;

    if(screens?.[screenIndex]){
        if(screens[screenIndex].type!='custom'){
            screenRender = <>
           
            {/* <div className={classes.description}>
                <span>{screens[screenIndex].description}</span>
            </div> */}
            {userConnections&& !preview 
                && userConnections.filter(uc => { return uc.provider === authorizedScreens[screenIndex].appId}).length > 0 
                && <ConnectionDropDownItem  
                        authorizedScreens={authorizedScreens} 
                        isSelectedItem={true}
                        screens={screens} 
                        screenIndex={screenIndex}
                        item={userConnections.filter(uc => { return uc.provider === authorizedScreens[screenIndex].appId})[0]}
                        onDelete={(item)=>{
                            // if(authorizedScreens[screenIndex]?.connectionId===item.connection_id){
                            //     const newauthorizedScreens = produce(authorizedScreens, draftState => {
                            //         draftState[screenIndex].connectionId = '';
                            //     })
                            //     setAuthorizedScreens(newauthorizedScreens)
                            // }
                            // refreshConnectionList();
                        }}>
                    </ConnectionDropDownItem>
            }
            {preview && <ConnectionDropDownItem  
                        authorizedScreens={authorizedScreens} 
                        isSelectedItem={true}
                        screens={screens} 
                        screenIndex={screenIndex}
                        item={{connection_id:'dummy', connection_name:screens[screenIndex].appName}}
                        onDelete={(item)=>{
                        }}>
                    </ConnectionDropDownItem>
            }
        </>
        }else{

            let connectedApps = [];
            if(!preview){
                connectedApps= authorizedScreens.filter(a=>(a.appId!=customerAuthContext.orgConnector && a.type !=='custom'))
                .map(a=>({appId:a.appId,connectionId:a.connectionId}))
    
                if(customerAuthContext.orgConnector && customerAuthContext.connection){
                    connectedApps.push({appId:customerAuthContext.orgConnector,connectionId:customerAuthContext.connection})
                }
            }
            
            screenRender = <>

            {!preview && <StepInput 
                template={workflow} 
                configScreen={authorizedScreens[screenIndex]} 
                authorizedScreens={connectedApps} 
                onChange={w=>setWorkflow(w)}>
                    </StepInput>}

            {preview && authorizedScreens[screenIndex]?.fieldItems?.map(field=>{
                return <div className={classes.stepInputGroup}>
                    <span>{field.displayName}</span>
                    <input type="text" className={classes.stepInput}></input>
                </div>
            })}
            </>
        }
        
    }
    let allowNextBtn = shouldEnableNextBtn();


    return <>
        <Overlay>
            {/*  Show the loader Initially when creating first connection */}
            { (!initialLoad) && <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100vw',height:'100vh'}}>
                <div style={{transform:'translate(-50%,-50%)',display:'inline-block'}}>
                <Loader></Loader>
                </div>
          
            </div>}

            {initialLoad &&!integrationComplete && <div className={classes.root}>
                <div className={classes.box}>
                <div className={classes.previewLabel}>
                    {preview && <span>Preview mode</span>}
                    <span className={classes.closeIcon}><CloseIcon style={{fontSize:'20px'}} onClick={() => {
                        if(connectionPollingId){
                            clearInterval(connectionPollingId)
                        }
                        onClose && onClose();
                    }}></CloseIcon></span>
                </div>
                <div className={classes.screenHeader}>
                    <span className={classes.screenHeaderText}>{screens[screenIndex].title}</span>
                    <span className={classes.screenHeaderIndex}>{(screenIndex + 1) + " of " + screens.length}</span>
                </div>
                <div className={classes.positionIndicator}>
                    {screens && screens.map((screen, idx) => {
                        if(idx===screens.length-1){
                            return <div className={classes.positionTab + " " + (idx <= screenIndex ? classes.positionTabActive : "")}>
                            </div>
                        }else{
                            return <div style={{marginRight:'1rem'}} className={classes.positionTab + " " + (idx <= screenIndex ? classes.positionTabActive : "")}>
                            </div>
                        }
                    })}
                </div>
                {connectionInProgress && <div style={{height:'100%',width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <span className={classes.previewLabel}>{"Please authenticate into "+screens[screenIndex].appName+"."}</span>
                    {/* <Loader></Loader> */}
                </div>}
                {!connectionInProgress && screenRender}
                <div className={classes.footer}>
                    <div className={classes.prevBtn} onClick={() => {
                        if (screenIndex === 0) {
                            onClose && onClose();
                        } else if (screenIndex <= screens.length - 1) {
                            setScreenIndex(screenIndex - 1);
                        }
                    }}>{screenIndex === 0 ? "Cancel" : "← Previous"}</div>
                  
                    <div className={classes.finishBtn} style={{cursor:allowNextBtn?'pointer':'not-allowed',opacity:allowNextBtn?'1':'0.5'}} onClick={() => {
                        if(shouldEnableNextBtn()){
                            if (screenIndex === screens.length - 1) {
                                completeListingWorkflow();
                                
                            } else if (screenIndex < screens.length - 1) {
                               
                                if(authorizedScreens[screenIndex+1] && authorizedScreens[screenIndex+1].type !=='custom' && !authorizedScreens[screenIndex+1].connectionId){
                                    setconnectionInprogress(true);
                                    createNewConnection({ appId: authorizedScreens[screenIndex+1].appId,appVersion:authorizedScreens[screenIndex+1].appVersion||1, connectionName: screens[screenIndex+1].appName },screenIndex+1);
                                }
                                setScreenIndex(screenIndex + 1);
                               
                            }
                        }
                      
                    }}>
                    {!createWorkflowLoading && <> {screenIndex === screens.length - 1 ? "Finish" : "Next →"}</>} 
                    {createWorkflowLoading&&<Loader></Loader>}
                        </div>
                </div>
                </div>
              

            </div> }
            {integrationComplete && <div className={classes.root}>
                <div className={classes.box}>
                    <div className={classes.previewLabel}>
                        {preview && <span>Preview mode</span>}
                        <span className={classes.closeIcon}><CloseIcon style={{fontSize:'20px'}} onClick={() => {
                            onClose && onClose();
                        }}></CloseIcon></span>
                    </div>
                    <div>
                        <div className={classes.screenHeader}>
                            <span className={classes.screenHeaderText}>{"Integration Successful"}</span>
                            <span className={classes.screenHeaderIndex} style={{marginTop:'3rem',marginBottom:'2rem'}}>{"Your data takes 10 - 15 minutes to populate"}</span>
                        </div>

                        {authorizedScreens && authorizedScreens.map((auth,idx)=>{
                            if(auth.type !=='custom'){
                                return <div style={{marginBottom:'2rem'}}>
                                    {userConnections&& !preview 
                                        && userConnections.filter(uc => { return uc.provider === authorizedScreens[idx].appId}).length > 0 
                                        && <ConnectionDropDownItem  
                                                authorizedScreens={authorizedScreens} 
                                                isSelectedItem={true}
                                                screens={screens} 
                                                screenIndex={idx}
                                                item={userConnections.filter(uc => { return uc.provider === authorizedScreens[idx].appId})[0]}
                                                onDelete={(item)=>{
                                                }}>
                                            </ConnectionDropDownItem>
                                    }
                                    {preview && <ConnectionDropDownItem  
                                                authorizedScreens={authorizedScreens} 
                                                isSelectedItem={true}
                                                screens={screens} 
                                                screenIndex={idx}
                                                item={{connection_id:'dummy', connection_name:screens[idx].appName}}
                                                onDelete={(item)=>{
                                                }}>
                                            </ConnectionDropDownItem>
                                            
                                            }
                                    </div>
                            }else{

                                let connectedApps = [];
                                if(!preview){
                                    connectedApps= authorizedScreens.filter(a=>(a.appId!=customerAuthContext.orgConnector && a.type !=='custom'))
                                    .map(a=>({appId:a.appId,connectionId:a.connectionId}))
                        
                                    if(customerAuthContext.orgConnector && customerAuthContext.connection){
                                        connectedApps.push({appId:customerAuthContext.orgConnector,connectionId:customerAuthContext.connection})
                                    }
                                }
            
                               return <StepInput 
                                        template={workflow} 
                                        configScreen={authorizedScreens[idx]} 
                                        authorizedScreens={connectedApps} 
                                        onChange={w=>{}} readonly={true}>
                                   </StepInput>
                            
                            }
                        })}

                    </div>
                    <div className={classes.footer}>
                        <div className={classes.completBtn} onClick={() => {

                            console.log(installedWorkflows)
                            onFinish && onFinish({
                                status:'success',
                                workflows:installedWorkflows
                            })
                            }}>Close
                        </div>
                    </div>
                </div>
            </div>}
            {integrationCompleteWithError && <div className={classes.root}>
                <div className={classes.box}>
                    <div className={classes.previewLabel}>
                        {preview && <span>Preview mode</span>}
                        <span className={classes.closeIcon}><CloseIcon style={{fontSize:'20px'}} onClick={() => {
                            onClose && onClose();
                        }}></CloseIcon></span>
                    </div>
                    <div>
                        <div className={classes.screenHeader}>
                            <span className={classes.screenHeaderText}>{"Integration Failed"}</span>
                            <span className={classes.screenHeaderIndex} style={{marginTop:'3rem',marginBottom:'2rem',color:'#d52b47'}}>{"Could't install the integration"}</span>
                        </div>
                    </div>
                    <div className={classes.footer}>
                            <div className={classes.tryagainBtn} onClick={() => {
                            onFinish && onFinish()
                            }}>Try Again
                        </div>
                    </div>
                
                </div>

            </div>
            }
        </Overlay>
    </>
}



const useStepInputStyle = makeStyles(theme=>({
    placeholder:{
        fontFamily:'Mulish',
        fontSize:'20px',
        fontWeight:'bold',
        color:'#9e9fad',
        height:'100%',width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start',padding:'20px'
    },
    selectedText:{
        fontFamily:'Mulish',
        fontSize:'20px',
        fontWeight:'bold',
        color:'#000000',
        height:'100%',width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start',padding:'20px',
        userSelect:'none'
    },
    searchInputText:{
        fontFamily:'Mulish',
        fontSize:'20px',
        fontWeight:'bold',
        color:'#000000',
        height:'100%',width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start',padding:'20px',
        userSelect:'none'
    },
    readonlyInputBox:{
        height: '80px',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent:'center',
        flexDirection:'column',
        position:'relative',
        border: '1px solid #c6cbdf',
        paddingLeft:'20px'
    },
    inputLabelReadonly:{
        fontFamily:'Mulish',
        fontSize:'15px',
        fontWeight:'bold',
        color:'#000000',
        userSelect:'none',
        opacity:'0.6',
        marginBottom:'5px'
    },
    selectedTextReadonly:{
        fontFamily:'Mulish',
        fontSize:'20px',
        fontWeight:'bold',
        color:'#000000',
        userSelect:'none'
    },
    description:{
        marginTop:'1rem',
    }
}))

function StepInput({configScreen,template, authorizedScreens, onChange,readonly}){

    const classes = useStyles();

    const inputClasses =useStepInputStyle();

    const [initialLoad,setInitialLoad] = useState(false);
    const [inputSchema,setInputSchema] = useState(null);
    const [outputFields,setOutputFields] = useState(null);

    const [picklistLoading, setPickListLoading]=useState(false);

    const [dynamicPicklistValues,setDynamicPicklistValues]=useState({});



    let tempalteIdx = template.findIndex(t=>t.templateId=== configScreen.templateId);
    
    let workfow=template[tempalteIdx].code;

    let setpIndex = workfow?.steps.findIndex(s=>s.stepId===configScreen.stepId);
    let step =  workfow?.steps.filter(s=>s.stepId===configScreen.stepId)[0];
    // step?.setpConfig
    let stepAuth = authorizedScreens?.filter(a=>a.appId===step?.stepType.application)[0];
    let connectionId =stepAuth?.connectionId

    useEffect(()=>{
       
        let isTrigger=false;
        if(workfow?.trigger.stepId===step?.stepId){
            isTrigger=true;
        }
       let data = {
        connectionId: connectionId,
        stepTypeId: step.stepType.typeId,
        isTrigger,
        appId: step.stepType.application,
        appVersion: step.stepType.appVersion||1,
        inputs: step.stepConfig || {},
        userSchema: step.userSchema
        }

       loadExtendedSchema(data).then(res=>{
            setInitialLoad(true);
            let inputFields = res.data.result.inputFields;
            let outputFields = res.data.result.outputFields;
            // inputFields.filter().map(sch=>{
            //     if(sch.controlType==='select' && sch.pickList){
            //        loadPicklist({
            //             connectionId,
            //             pickListName: sch.pickList,
            //             "pickListParams":{}
            //         })
            //     }
            // })
            let sample = res.data.result.sample;
            setInputSchema(inputFields);
            setOutputFields(outputFields);
       }).catch(err=>{
           console.log(err);
       }).finally(()=>{

       })

      
    },[workfow,initialLoad,setpIndex])

    function loadPicklistValue(pickListName){
        setPickListLoading(true);
             loadPicklist({
                        appId: step.stepType.application,
                        connectionId,
                        pickListName: pickListName,
                        linkedPickList: {}
                    }).then(res=>{
                      //  setDynamicPicklistValue( res.data.result.picklistValue);
                      let newPicV = {...dynamicPicklistValues};
                      newPicV[pickListName]=res.data.result.picklistValue
                        setDynamicPicklistValues( newPicV);
                        setPickListLoading(false);
                    }).catch(err=>{
                        console.log(err)
                        setPickListLoading(false);
                    })
    }

    if(!readonly){
        return  <>
        {configScreen.fieldItems.map(field=>{
            let fieldSchema = inputSchema?.filter(sch=>sch.name===field.name)[0];
            if(fieldSchema){
                let inputRender=null;

                if(fieldSchema.controlType==='select'){
                    let selectItems=[];

                    let isSelectItemsLoading=false;
                    
                    if(fieldSchema.picklistValue){
                        let keys = Object.keys(fieldSchema.picklistValue);
                       selectItems= keys.map((key, idx) => {
                            return {key,value:fieldSchema.picklistValue[key]}
                       })
                    }else if(picklistLoading){
                        isSelectItemsLoading=true;
                    //     selectItems= <MenuItem key={'abcd'} value={''} className={classes.selectAppItemWrap}>
                    //     <CircularProgress size={24} />
                    // </MenuItem>
                    }else if(fieldSchema.pickList ){
                        if(dynamicPicklistValues[fieldSchema.pickList]){
                            let keys = Object.keys(dynamicPicklistValues[fieldSchema.pickList]);
                            selectItems= keys.map((key, idx) => {
                                 return {key,value:dynamicPicklistValues[fieldSchema.pickList][key]}
                                 
                                 
                                //  <MenuItem key={idx} value={key} className={classes.selectAppItemWrap}>
                                //      <span className={classes.selectAppItemText}>{dynamicPicklistValues[fieldSchema.pickList][key]}</span>
                                //  </MenuItem>
                                 
                                })
                        }else if(!picklistLoading){
                            loadPicklistValue(fieldSchema.pickList );
                        }
                       
                    }

                    if(isSelectItemsLoading){
                        inputRender = <FormControl variant="outlined" className={classes.formControl}>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',height:'80px'}}>
                                <Loader></Loader>
                            </div>
                  
                    </FormControl>
                    }else{
                        inputRender = <FormControl variant="outlined" className={classes.formControl}>
                        <SelectCustomerRender 
                            className={classes.connectionBoxItem}
                            inputClass={inputClasses.searchInputText}
                            enableSearch={true}
                            filter={(item,searchText)=>item.value.toLowerCase().includes(searchText.toLowerCase())}
                            placeholder={()=>{
                                return  <div
                                className={inputClasses.placeholder}>
                                <span>{"Pick a value"}</span>
                            </div>
                            }}
                            onChange={item=>{
                                const newTemplate = produce(template, draftState => {
                                    draftState[tempalteIdx].code.steps[setpIndex].stepConfig[fieldSchema.name]=item.key;
                                })
                            
                              onChange(newTemplate)
                            }}
                            items={selectItems}
                            itemRender={item=>{
                                return <>
                                <div className={inputClasses.selectedText}>
                                    <span>{item.value}</span>
                                </div>
                                    
                                </>
                            }}
                            value={step?.stepConfig?.[fieldSchema.name]||''}
                            valueCondition={(i,value)=>i.key===value}
                        >

                        </SelectCustomerRender>
                        <span className={inputClasses.description}>{configScreen.description||''}</span>
                    </FormControl>
                    }
                }else if(fieldSchema.controlType==='multiselect'){

                }else{
                    inputRender= <input type="text" className={classes.stepInput} onChange={e=>{
                        const newTemplate = produce(template, draftState => {
                            draftState[tempalteIdx].code.steps[setpIndex].stepConfig[fieldSchema.name]=e.target.value;
                        })
                    
                      onChange(newTemplate)
                    }}></input> 
                }
                return <div className={classes.stepInputGroup}>  
                    <span>{fieldSchema.displayName}</span>
                    {inputRender}
                </div>
            }else{
                return <></>;
            }
        })}</>
    }else{
        return  <>
            {configScreen.fieldItems.map(field=>{
                 let fieldSchema = inputSchema?.filter(sch=>sch.name===field.name)[0];
                 if(fieldSchema){
                    let inputRender=null;

                    if(fieldSchema.controlType==='select'){
                        let selectItems=[];
                        let isSelectItemsLoading=false;
                        if(fieldSchema.picklistValue){
                            let keys = Object.keys(fieldSchema.picklistValue);
                           selectItems= keys.map((key, idx) => {
                                return {key,value:fieldSchema.picklistValue[key]}
                           })
                        }else if(picklistLoading){
                            isSelectItemsLoading=true;
                        }else if(fieldSchema.pickList ){
                            if(dynamicPicklistValues[fieldSchema.pickList]){
                                let keys = Object.keys(dynamicPicklistValues[fieldSchema.pickList]);
                                selectItems= keys.map((key, idx) => {
                                     return {key,value:dynamicPicklistValues[fieldSchema.pickList][key]}
                                    })
                            }else if(!picklistLoading){
                                loadPicklistValue(fieldSchema.pickList );
                            }  
                        }

                        if(isSelectItemsLoading){
                            inputRender = <FormControl variant="outlined" className={classes.formControl}>
                                 <div className={classes.readonlyInputBox}>
                                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',height:'80px'}}>
                                        <Loader></Loader>
                                    </div>
                                </div>
                            </FormControl>
                        }else{
                            inputRender = <FormControl variant="outlined" className={classes.formControl}>
                                <div className={inputClasses.readonlyInputBox}>
                                    <span className={inputClasses.inputLabelReadonly}>{configScreen.title+"("+fieldSchema.displayName+")"}</span>
                                    <div className={inputClasses.selectedTextReadonly}>
                                        
                                        <span>{selectItems.filter(item=>item.key===step?.stepConfig?.[fieldSchema.name])[0]?.value}</span>
                                    </div>
                                </div>
                            </FormControl>
                        }
                    }else if(fieldSchema.controlType==='multiselect'){
                      
                    }else{
                        inputRender = <FormControl variant="outlined" className={classes.formControl}>
                        <div className={inputClasses.readonlyInputBox}>
                             <span className={inputClasses.inputLabelReadonly}>{configScreen.title+"("+fieldSchema.displayName+")"}</span>
                            <div className={inputClasses.selectedTextReadonly}>
                                <span>{step?.stepConfig?.[fieldSchema.name]}</span>
                            </div>
                        </div>
                    </FormControl>
                    }

                    return <div className={classes.stepInputGroup}>  
                        {inputRender}
                    </div>
                 }
            })}
        </>
    }

 
}



const useConnectionDropDownItemStyle = makeStyles(theme=>({
    connectionBoxItem: {
        height: '80px',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        position:'relative',
        border: '1px solid #c6cbdf',
    },
    moreTray:{
        position:'absolute',
        backgroundColor:'white',
        top:'10px',
        right:'-110px',
        height:'60px',
        zIndex:'100',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 14px 43px 0 #2D2D68',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
        padding:'30px',
        cursor:'auto'
    },
    moreMenu:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer',
        '&:hover':{
            opacity:'0.7',
        }
    },
    moreMenuIcon:{
        fontSize: '18px',
        marginRight:'10px',
        color:'red'
    },
    moreMenuText:{
        color: '#57587A',
        fontFamily: 'Mulish',
        marginRight:'10px',
        fontSize: '14px',
        fontWeight: 'bold',
        color:'red'
    },
    listingCardMoreBox: {
        position: 'absolute',
        top: '5.8rem',
        right: '-15px',
        zIndex: '101',
      //  height: '7rem',
        width: '81px',
        border: '1px solid #D9D9F4',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 12px 15px 0 rgba(223,223,243,0.34)',
        display: 'flex',
        flexDirection: 'column',
        "&::after": {
            left: '3rem',
            top: '-0.75rem',
            height: '1.5rem',
            width: '1.5rem',
            position: 'absolute',
            backgroundColor: '#FFFFFF',
            zIndex: '102',
            content: '""',
            borderTop: '1px solid #D9D9F4',
            borderRight: '1px solid #D9D9F4',
            transform: 'rotate(-45deg)'
        }
    },
    connectionLabel:{
        color:'#20bb74',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '-0.5px',
        lineHeight: '18px',
        display:'flex',
        alignItems:'center'
    },
    listingCardMoreEdit: {
        height: '3.5rem',
        width: '100%',
        borderBottom: '1px solid #D9D9F4',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    listingCardMoreEditIcon: {
        marginLeft: '1rem',
        marginRight: '0.6rem'
    },
    listingCardMoreEditText: {
        color: '#888AA9',
        fontWeight: '600',
        fontSize: '10px',
        letterSpacing: '-0.36px',
        lineHeight: '13px',
    },
    listingCardMoreDelete: {
        height: '3.5rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    listingCardMoreDeleteIcon: {
        marginLeft: '1rem',
        marginRight: '0.6rem'
    },
    listingCardMoreDeleteText: {
        color: '#A0001B',
        fontWeight: '600',
        fontSize: '10px',
        letterSpacing: '-0.36px',
        lineHeight: '13px',
    },
    listingCardMoreIcon: {
        fontSize: '2rem',
        color: '#B3B3B4',
        cursor: 'pointer'
    },
}))

function ConnectionDropDownItem({screens,screenIndex,item,authorizedScreens,isSelectedItem, onDelete}){

    const classes = useStyles();
    const classes1 = useConnectionDropDownItemStyle();
    const [showMoreTray, setShowMoreTray]=useState(false);
    const trayRef = useRef(null);
    const [deleteLoading,setDeleteLoading] = useState(false);

    useOutsideAlerter(trayRef,()=>{
        setShowMoreTray(false);
    })

    function handleDelete(){
        setDeleteLoading(true);
        deleteConnection(item.connection_id).then(res=>{
            onDelete && onDelete(item);
        }).catch(err=>{

        }).finally(()=>{
            setDeleteLoading(false);
        })
    }
    

    return <div className={classes1.connectionBoxItem}>
        {showMoreTray &&
        
        <div  ref={trayRef}  className={classes1.listingCardMoreBox} onClick={e=>{
            e.preventDefault();
            e.stopPropagation();
        }}>
                <div className={classes1.listingCardMoreDelete}  onClick={(e) => {
                         handleDelete()
                         e.preventDefault();
                         e.stopPropagation();
                    }}>
                    <DeleteOutlineIcon className={classes1.listingCardMoreDeleteIcon} style={{ color: '#A0001B' }}></DeleteOutlineIcon>
                    <span className={classes1.listingCardMoreDeleteText} onClick={() => {
                        setShowMoreTray(false);
                    }}>Remove</span>
                     {deleteLoading && <Loader style={{color:'#0958E5'}}></Loader> }
                </div>
            </div>}
            
           
    <div className={classes.logoBox}>
        <img src={screens[screenIndex].appLogo} className={classes.logo}></img>
    </div>
    <div className={classes.connectionName}>
        <span className={classes1.connectionLabel}><CheckIcon style={{marginRight:'5px',fontSize:'14px'}}></CheckIcon> Connected</span>
        <span className={classes.connectionNameText}>{item.connection_name}</span>
    </div>
    {!isSelectedItem &&  <div className={classes.connectionMoreIconWrap} onClick={e=>{
        setShowMoreTray(true);
        e.preventDefault();
        e.stopPropagation();
        }}>
        <MoreVertIcon className={classes.connectionMoreIcon}></MoreVertIcon>
    </div>}
   
</div>

}


