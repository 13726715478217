import jwt_decode from "jwt-decode";

export default function useCustomerPermissions(marketplace) {

    let permissions = {
        userActions: { 
            canViewIntegrations: false, 
            canCreateEditTemplateIntegrations: false, 
            canCreateIntegrationWithBuilder: false 
        },
        integrationLimits:{maximumIntegrations:0, maximumAPICalls:0,limitBy:'api_calls'},
        tierPopupConfig:{title:'',infoText:'',font:'',actionCta:''}
    }
    
    if (process.browser) {
        try{
            const access_token = sessionStorage.getItem("marketplace_access_token");
            if (access_token) {
                const decoded = jwt_decode(access_token);
                if (decoded.monetizationPlan && marketplace.plans) {
                    let index = marketplace.plans.findIndex(plan => plan.name.toLowerCase() === decoded.monetizationPlan.toLowerCase());
                    if (index > -1 && marketplace.plans[index]) {
                        permissions= marketplace.plans[index]
                    }
                }else{
                    permissions = {
                        userActions: { canViewIntegrations: true, canCreateEditTemplateIntegrations: true, canCreateIntegrationWithBuilder: true },
                        integrationLimits:{maximumIntegrations:-1, maximumAPICalls:-1},
                        tierPopupConfig:{title:'',infoText:'',font:'',actionCta:''}
                    }
                }
            }
        }catch(err){
            console.log(err)
        }     

        return permissions;
    } else {
        return permissions;
    }
}