import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root:{
        paddingLeft:'10.5px',
        paddingTop:'1.5px',
        marginRight:'62.5px',
        marginBottom:'62.5px',
        width: 'calc(100% - 125px)',
        maxWidth:'894px',
        display:'flex',
        flexDirection:'column'
    },
    backBtn:{
        display:'flex',
        alignItems:'center',
        marginBottom:'17px',
        cursor:'pointer'
    },
    backBtnText:{
        marginLeft:'10px',
        color: '#595B85',
        fontFamily: "Mulish",
        fontWeight:'800',
        fontSize: '14px',
        letterSpacing: '-0.14px',
        lineHeight: '18px',
    },
    box:{
        height: '616px',
        width: '890px',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 6px 12px 0 rgba(76,141,235,0.2)',
        display:'flex',
        flexDirection:'column'
    },
    header:{
        height:'73px',
        borderBottom:'1px solid #DCE4F8',
        paddingLeft:'19px',
        paddingRight:'19px',
        display:'flex',
        alignItems:'center'
    },
    headerStatus:{
        display:'flex',
        alignItems:'center'
    },
    headerStatusFail:{
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight:'800',
        letterSpacing: 0,
        lineHeight: '18px',
        marginLeft:'10px',
        color: '#D01030'
    },
    headerStatusSuccess:{
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight:'800',
        letterSpacing: 0,
        lineHeight: '18px',
        marginLeft:'10px',
        color: '#20BB86'
    },
    headerStatusInProgress:{
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight:'800',
        letterSpacing: 0,
        lineHeight: '18px',
        marginLeft:'10px',
        color: '#ffac02'
    },
    workflowName:{
        flex:1,
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight:'800',
        letterSpacing: 0,
        lineHeight: '18px',
        marginLeft:'30px',
        display:'flex',
        alignItems:'center'
    },
    workflowNameText:{
        marginRight:'20px'
    },
    logDateTime:{
        display:'flex',
        alignItems:'center',
        marginLeft:'20px',
        marginRight:'40px',
        width:'187px',
    },
    logDateTimeText:{
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight:'800',
        fontSize: '14px',
        letterSpacing: '-0.44px',
        lineHeight: '18px',
        marginLeft:'5px'
    },
    retriggerBtn:{
        height: '38px',
        width: '52px',
        borderRadius: '8px',
        backgroundColor: '#20BB86',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer'
    },
    body:{
        flex:1,
        display:'flex',
        height:'calc(100% - 73px)',
    },
    steps:{
        borderRight:'1px solid #DCE4F8',
        width:'359px',
        height:'100%',
        display:'flex',
        flexDirection:'column'
    },
    step:{
        width:'100%',
        height:'71px',
        borderBottom:'1px solid #DCE4F8',
        display:'flex',
        alignItems:'center',
        paddingRight:'23px',
        paddingLeft:'23px',
    },
    stepBody:{
        flex:1,
        display:'flex',
        flexDirection:'column',
        cursor:'pointer',
    },
    stepRetrigger:{
        marginLeft:'23px',
        cursor:'pointer'
    },
    stepName:{
        display:'flex',
        alignItems:'center',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '18px',
    },
    stepNameSuccess:{
        color: '#20BB86',
        marginLeft:'10px'
    },
    stepDec:{
        fontFamily: 'Mulish',
        fontSize: '12px',
        letterSpacing: 0,
        lineHeight: '18px',
        marginLeft:'30px'
    },
    inputOutput:{
        flex:1,
        height:'100%',
        overflow:'hidden',
        padding:"25px",
        display:'flex',
        flexDirection:'column'
    },
    stepError:{
        width:'100%',
        height:'71px',
        borderBottom:'1px solid #DCE4F8',
        display:'flex',
        alignItems:'center',
        paddingRight:'23px',
        paddingLeft:'23px',
        backgroundColor: '#D01030',
    },
    inputOuputHeader:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        marginBottom:'16px'
    },
    inputOuputBody:{
        flex:1,
        width:'100%',
        border: '1px solid #DCE4F8',
        backgroundColor: '#F4F5F9',
        overflow:'auto',
        padding: '10px',
    },
    codebox:{
        width:'100%',
        height:'100%',
        overflow:'auto',
    },
    inputOuputBtns:{
        display:'flex',
        fontFamily: 'Mulish',
        fontSize: '14px',
        fontWeight:'bold',
        letterSpacing: 0,
        lineHeight: '18px',
    },
    inputBtn:{
        height: '34px',
        width: '72px',
        borderRadius: '4px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer'
    },
    outputBtn:{
        height: '34px',
        width: '72px',
        borderRadius: '4px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer'
    }
}))

export default useStyles;