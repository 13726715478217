import { useEffect, useState } from "react";
//useRouter

import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';

import Marketplace from "./Marketplace";
import Loader from "../../components/Loader";


const HTTP_LINK = new HttpLink({ uri: `https://${process.env.REACT_APP_PUBLIC_API_HOST}/app/apifuse-api/graphql` });
const WORKFLOW_HTTP_LINK = new HttpLink({ uri: process.env.REACT_APP_PUBLIC_WOKFLOW_ENDPOINT });

const AUTH_MIDDLEWARE = new ApolloLink((operation, forward) => {
    const token = sessionStorage.getItem('access_token')
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ' '
        }
    });
    return forward(operation);
})


const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(AUTH_MIDDLEWARE, HTTP_LINK)
});
const workflowClient = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(AUTH_MIDDLEWARE, WORKFLOW_HTTP_LINK)
});

function getMarketplaceForSubdomain(subdomain, customDomain) {
    const getMarketplaceQuery = gql`
    {
        marketplacePublicBySubdomain(subdomain:"${subdomain || ''}",customDomain:"${customDomain || ''}"){
            organizationId
           categories
           plans{
             name
             connectors
             userActions{
                canViewIntegrations
                canCreateEditTemplateIntegrations
                canCreateIntegrationWithBuilder
             }
             tierPopupConfig{
                title
                infoText
                font
                actionCta
              }
             integrationLimits{
                limitBy
                maximumIntegrations
                maximumAPICalls
             }
           }
           subdomain
           loginRedirectUrl
           authenticatedViewOnly
           preview{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
            config{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
         }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}

function getMarketplace(orgId) {
    const getMarketplaceQuery = gql`
    {
        marketplacePublic(orgId:"${orgId}"){
            organizationId
           categories
           plans{
             name
             connectors
             tierPopupConfig{
                title
                infoText
                font
                actionCta
              }
             userActions{
                canViewIntegrations
                canCreateEditTemplateIntegrations
                canCreateIntegrationWithBuilder
             }
             integrationLimits{
                limitBy
                maximumIntegrations
                maximumAPICalls
             }
           }
           subdomain
           loginRedirectUrl
           authenticatedViewOnly
           preview{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
            config{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
             listingViewType
             showSidebar
             showHeader
             listingOrder
           }
         }
         getAllListingsPublic(orgId:"${orgId}"){
            name
            organizationId
            logo
            type
            category
            listingId
            status
            requestInfoText
            requestButtonText
            requestTitle
            showComingSoonLabel
          }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}


function getMarketplaceListings(orgId) {
    const getMarketplaceQuery = gql`
    {
         getAllListingsPublic(orgId:"${orgId}"){
            name
            organizationId
            logo
            type
            category
            listingId
            status
            requestInfoText
            requestButtonText
            requestTitle
            showComingSoonLabel
          }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}






export default function MarketplaceForOrg({marketplace,mode}) {



    const [initialLoad, setInitialLoad] = useState(false);

    const [listings, setListings] = useState(null);

    
    const [userToken, setUserToken] = useState(null);



    useEffect(() => {
        if (!initialLoad && marketplace) {


            getMarketplaceListings(marketplace.organizationId)
            .then(res=>{
                setListings(res.data.getAllListingsPublic)
            })
            .catch(err=>{

            })
            .finally(()=>{

            })
            
            setInitialLoad(true);
   
        }
      
    }, [initialLoad]);

    
    return (<>
        <style jsx>
            {`
            .loaderWrap{
                width:100vw;
                height:100vh;
                display:flex;
                align-items:center;
                justify-content:center;
            } 
            `}
        </style>
        {(!initialLoad || !marketplace) &&<div className={"loaderWrap"}>
            <Loader size='19px' width="3px"></Loader>
        </div>}
        {initialLoad && marketplace && <Marketplace marketplace={marketplace} orgId={marketplace.organizationId} preview={mode === 'preview'} listings={listings||[]} categories={marketplace.categories} onClose={() => {
            window.close();
        }}></Marketplace>}
    </>)
}

