import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";

const client = new ApolloClient({
    uri: process.env.REACT_APP_PUBLIC_CONNECTOR_ENDPOINT,
    request: (operation) => {
        const token =  sessionStorage.getItem('marketplace_access_token') || sessionStorage.getItem('access_token');
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : ''
            }
        })
    }
});

function getAllConnectors() {

    const getAllConnectorsQuery = gql`
    {
        connectors{
            app_name,
            app_id,
            has_trigger,
            app_logo,
            app_desc
        }
    }
`;

    return client
        .query({
            query: getAllConnectorsQuery
        });
}

function getOrganisation(loadNetwork) {
    //loadNetwork = true;
    const getorganisationQuery = gql`
    {
        organisation(orgId:"1"){
            orgId,
            roles{name,default,workflow{create,view,delete,update},roles{create,view,delete,update},monetization{create,view,delete,update},users{create,view,delete,update}},
            users{firstName,lastName,email,role}
        }
    }
`;

    return client
        .query({
            query: getorganisationQuery,
            fetchPolicy: (loadNetwork && 'network-only') || undefined
        });
}

function addUser(user) {

    const createUserQuery = gql`
    mutation addUser{
        addUser(orgId:"1",user:{firstName:"${user.firstName}",lastName:"${user.lastName}",email:"${user.email}",role:"${user.role}",password:"${user.password}"}){ 
            orgId,
            roles{name,default,workflow{create,view,delete,update},roles{create,view,delete,update},monetization{create,view,delete,update},users{create,view,delete,update}},
            users{firstName,lastName,email,role}
        }}
`;

    return client
        .mutate({
            mutation: createUserQuery
        });

}

function deleteUser(email) {
    const createUserQuery = gql`
    mutation deleteUser{
        deleteUser(orgId:"1",email:"${email}"){ 
            orgId,
            roles{name,default,workflow{create,view,delete,update},roles{create,view,delete,update},monetization{create,view,delete,update},users{create,view,delete,update}},
            users{firstName,lastName,email,role}
        }}
`;
    return client
        .mutate({
            mutation: createUserQuery
        });

}

function updateUser(user) {
    const createUserQuery = gql`
    mutation updateUser{
        updateUser(orgId:"1",user:{firstName:"${user.firstName}",lastName:"${user.lastName}",email:"${user.email}",role:"${user.role}",password:"${user.password}"}){ 
            orgId,
            roles{name,default,workflow{create,view,delete,update},roles{create,view,delete,update},monetization{create,view,delete,update},users{create,view,delete,update}},
            users{firstName,lastName,email,role}
        }}
`;
    return client
        .mutate({
            mutation: createUserQuery
        });

}

function addRole(role) {

    const createRoleQuery = gql`
    mutation addRole{
        addRole(orgId:"1",role:{name:"${role.name}",
        workflow:{create:${role.workflow.create},view:${role.workflow.view},delete:${role.workflow.delete},update:${role.workflow.update}},
        roles:{create:${role.roles.create},view:${role.roles.view},delete:${role.roles.delete},update:${role.roles.update}},
        monetization:{create:${role.monetization.create},view:${role.monetization.view},delete:${role.monetization.delete},update:${role.monetization.update}},
        users:{create:${role.users.create},view:${role.users.view},delete:${role.users.delete},update:${role.users.update}}}){ 
            orgId,
            roles{name,default,workflow{create,view,delete,update},roles{create,view,delete,update},monetization{create,view,delete,update},users{create,view,delete,update}},
            users{firstName,lastName,email,role}
        }}
`;

    return client
        .mutate({
            mutation: createRoleQuery
        });

}

function deleteRole(roleName) {

    const createRoleQuery = gql`
    mutation deleteRole{
        deleteRole(orgId:"1",roleName:"${roleName}"){ 
            orgId,
            roles{name,default,workflow{create,view,delete,update},roles{create,view,delete,update},monetization{create,view,delete,update},users{create,view,delete,update}},
            users{firstName,lastName,email,role}
        }}
`;

    return client
        .mutate({
            mutation: createRoleQuery
        });

}


function getConnectorByAppId(appId) {
    const getConnectorQuery = gql`
    {
        connector(app_id:"${appId}"){
            app_name,
            app_id,
            has_trigger,
            app_logo,
            app_desc,
            connection{connection_type,connection_data{name,label,control_type,optional}},
            triggers{name,description,display_name,trigger_type,input_fields,config_fields{code,dynamic,name,control_type,label}},
            actions{name,description,display_name,input_fields{code,dynamic,name,control_type,label},config_fields{code,dynamic,name,control_type,label}},
            pick_lists,
            objects
        }
    }
`;

    return client
        .query({
            query: getConnectorQuery
        });

}

export default {
    getAllConnectors,
    getConnectorByAppId,
    getOrganisation,
    addUser,
    deleteUser,
    addRole,
    updateUser,
    deleteRole
}
