import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    monitor:{
        paddingLeft:'1px',
        marginRight:'1px',
        paddingTop:'1px',
        width: '1100px',
        maxWidth:'1094px',
        display:'flex',
        flexDirection:'column'
    },

    searchbar:{
        height: '69px',
        width: '100%',
        border: '1px solid #AEB9D4',
        borderRadius: '5px',
        backgroundColor: '#F5F7FD',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center'
    },
    searchLabel:{
        color: '#595B85',
        fontFamily: 'Mulish',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '-0.12px',
        lineHeight: '15px',
    },
    select:{
        height: '32px',
        width: '117px',
        maxWidth: '117px',
        border: '1px solid #DCE4F8',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        marginLeft:'15px'
    },
    selectWorkflow:{
        height: '32px',
        width: '217px',
        maxWidth: '217px',
        border: '1px solid #DCE4F8',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        marginLeft:'15px',
        textAlign:'left',
        justifyContent: 'flex-start',
    },
    trayClass:{
        width: '217px',
    },
    itemClass:{
        height: '32px',
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '-0.013rem',
        lineHeight: '16px',
        paddingRight: '1rem',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        justifyContent: 'flex-start',
        textAlign:'left',
        marginBottom:'10px',
        marginTop:'10px',
    },
    inputClass:{
        flex: 1,
        paddingLeft: '1rem',
        display: 'flex',
        height: '100%',
        width: '100%',
        color: '#313E6F',
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '-0.013rem',
        lineHeight: '16px',
        justifyContent: 'flex-start',
        paddingRight: '1rem',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        alignItems:'center'
    },
    itemClassStatus:{
        height: '4.1rem',
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '-0.013rem',
        lineHeight: '16px',
        paddingRight: '1rem',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        justifyContent: 'flex-start',
        textAlign:'left',
        marginBottom:'1px',
        marginTop:'1px', 
    },
    selectDate:{
        position:'relative',
        height: '32px',
        width: '223px',
        maxWidth: '263px',
        border: '1px solid #DCE4F8',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        marginLeft:'15px',
        outline:'none',
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '-0.013rem',
        lineHeight: '16px',
        display:'flex',
        alignItems:'center',
        
    },
    selectDateTextLeft:{
        position:'absolute',
        left:0,
        top:0,
        height:'100%',
        width:'100px',
        zIndex:'10',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    selectDateTextRight:{
        position:'absolute',
        right:0,
        top:0,
        height:'100%',
        width:'100px',
        zIndex:'10',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    selectDateInput:{
        outline:'none',
        border:'none',
        height:'100%',
        borderRadius: '4px',
        opacity:0,
        '&::-webkit-calendar-picker-indicator':{
            cursor:'pointer',
            width:'100%',
            height:'100%',
            opacity:0
        },
        zIndex:'11'
    },
    selecteDateToText:{
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '-0.013rem',
        lineHeight: '16px',
        zIndex:'11'
    },
    selectCustomer:{
        height: '32px',
        width: '212px',
        maxWidth: '212px',
        border: '1px solid #DCE4F8',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        marginLeft:'15px'
    },
    resultLogItems:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        marginTop:'34px',
        marginBottom:'28px',
    },
    resultLogItem:{
        height: '50px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 6px 12px 0 rgba(76,141,235,0.2)',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        paddingLeft:'20px',
        paddingRight:'20px',
        marginBottom:'10px',
    },
    statusIndicator:{
        display:'flex',
        alignItems:'center',
        width:'106px',
    },
    successIndicator:{
        height: '26px',
        width: '26px',
     //   backgroundColor: '#20BB86',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:'50%',
    },
    failureIndicator:{
        height: '26px',
        width: '26px',
        display:'inline-block',
        borderRadius:'50%'
    },
    successIndicatorText:{
        color: '#20BB86',
        fontFamily: "Mulish",
        fontWeight:'800',
        fontSize: '14px',
        letterSpacing: '-0.44px',
        lineHeight: '18px',
        marginLeft:'8px'
    },
    failureIndicatorText:{
        color: '#D01030',
        fontFamily: "Mulish",
        fontWeight:'800',
        fontSize: '14px',
        letterSpacing: '-0.44px',
        lineHeight: '18px',
        marginLeft:'8px'
    },
    inprogressIndicatorText:{
        color: '#ffac02',
        fontFamily: "Mulish",
        fontWeight:'800',
        fontSize: '14px',
        letterSpacing: '-0.44px',
        lineHeight: '18px',
        marginLeft:'8px'
    },
    workflowName:{
        display:'flex',
        alignItems:'center',
        flex:1,
        marginLeft:'20px',
        maxHeight:'100%',   
        overflow:'hidden',
    },
    workflowNameText:{
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight:'800',
        fontSize: '14px',
        letterSpacing: '-0.44px',
        lineHeight: '18px',
        marginRight:'10px'
    },
    logDateTime:{
        display:'flex',
        alignItems:'center',
        marginLeft:'20px',
        width:'187px',
    },
    logDateTimeText:{
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight:'800',
        fontSize: '14px',
        letterSpacing: '-0.44px',
        lineHeight: '18px',
        marginLeft:'5px'
    },
    viewLogBtn:{
        height: '34px',
        width: '114px',
        borderRadius: '4px',
        backgroundColor: '#E3E8F9',
        marginLeft:'20px',
        cursor:'pointer',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        '&:active':{
            opacity:'0.2'
        },
        '&:hover':{
            opacity:'0.9'
        },
    },
    viewLogBtnText:{
        color:'#486CBB',
        fontFamily: "Mulish",
        fontWeight:'800',
        fontSize: '14px',
        letterSpacing: '-0.44px',
        lineHeight: '18px',
        marginLeft:'10px'
    },
    pagesWrap:{
        width:'100%',
        display:'flex',
        justifyContent:'flex-end',  
        marginBottom:'50px' 
    },
    pages:{
        maxWidth: '328px',
        display:'flex',
    },
    pagesNavigation:{
        height: '40px',
        width: '40px',
        borderRadius: '4px',
        backgroundColor: '#E3E8F9',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer',
        marginLeft:'4px',
        marginRight:'4px',
    },
    page:{
        height: '40px',
        width: '40px',
        borderRadius: '4px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor: '#FFFFFF',
        marginLeft:'4px',
        marginRight:'4px',
        boxShadow: '0 6px 12px 0 rgba(76,141,235,0.2)',
        color: '#303159',
        fontFamily: "Mulish",
        fontWeight:'800',
        fontSize: '14px',
        letterSpacing: '-0.44px',
        lineHeight: '18px',
        cursor:'pointer',
    },
    pageActive:{
        backgroundColor: '#4166EA',
        color: '#FFFFFF',
    }
}));


export default useStyles;
