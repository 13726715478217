import React, { useState, useEffect } from 'react';

import { useParams} from "react-router-dom";
import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';

import Loader from "../../components/Loader";
import ListingView from '../Listing/ListingView';


const HTTP_LINK = new HttpLink({ uri: `https://${process.env.REACT_APP_PUBLIC_API_HOST}/app/apifuse-api/graphql` });

const AUTH_MIDDLEWARE = new ApolloLink((operation, forward) => {
    const token = sessionStorage.getItem('marketplace_access_token')
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });
    return forward(operation);
})


const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        resultCaching: false
    }),
    link: concat(AUTH_MIDDLEWARE, HTTP_LINK)
});




function getMarketplaceAndListing(orgId, slug) {
    const getMarketplaceQuery = gql`
    {
        marketplacePublic(orgId:"${orgId}"){
            organizationId
           categories
           plans{
            name
            connectors
            userActions{
               canViewIntegrations
               canCreateEditTemplateIntegrations
               canCreateIntegrationWithBuilder
            }
            tierPopupConfig{
               title
               infoText
               font
               actionCta
             }
            integrationLimits{
               limitBy
               maximumIntegrations
               maximumAPICalls
            }
           }
           subdomain
           preview{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
           }
            config{
             font
             title
             logo
             requestIntegration
             header{backgroundColor fontColor}
             sideBar{backgroundColor fontColor}
             contentSection{backgroundColor fontColor}
           }
         }
         getListingBySlug(slug:"${slug}",orgId:"${orgId}"){
            listingId
            name
            logo
            category
            type
            organizationId
            slug
            status
            requestInfoText
            requestButtonText
            requestTitle
            showComingSoonLabel
            settings{
                description
              screenshots
              videos
              sourceWorkflow
              description
              installCTA
              monetizationPlan
              features
              pricing
              customize
              externalLink
              authScreens{
                appId
                appName
                appLogo
                title
                description
                buttonCTA
                fieldId
                stepId
                type
                templateId
                fieldItems{
                    name
                    displayName
                }
              }
            }
          }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}


function getTemplates(listingId) {
    const getMarketplaceQuery = gql`
    {
          getTemplateForListing(listingId:"${listingId}"){
            code
            templateId
          }
    }
`;
    return client
        .query({
            query: getMarketplaceQuery
        });
}




export default function SlugListing({marketplace,mode}) {


    const {slug} =useParams();



    const [initialLoad, setInitialLoad] = useState(false);


    const [listing, setListing] = useState({});
    const [workflowTemplate, setWorkflowTemplate] =useState(null);
    const [listingId,setListingId] =useState();


    let mediaDisplay = [];

    if (listing && listing.settings && listing.settings.screenshots && listing.settings.videos) {
        listing.settings.screenshots.map(s => {
            mediaDisplay.push({ type: 'image', url: s });
        })
    }


    useEffect(()=>{
        if(initialLoad && listing && listingId){
            let listingName='';
            if(listing && listing.name){
                listingName=listing.name;
            }
            const updateString = `
            mutation addMarketplaceListingVisit{
                addMarketplaceListingVisit(listingId:"${listingId}",listingName:"${listingName}"){ 
                    code,
                    message
                }} `;
    
            const addMarketplaceVisit = gql(updateString);
    
            client
                .mutate({
                    mutation: addMarketplaceVisit
                }).then(res => {
                   // console.log(res.data)
                }).catch(err => {
                    console.log(err)
                })
        }
    },[initialLoad,listing,listingId])

    useEffect(() => {
        if (!initialLoad) {
            getMarketplaceAndListing(marketplace.organizationId,slug).then(res => {
                setListing(res.data.getListingBySlug);
                if(res.data.getListingBySlug){
                    setListingId(res.data.getListingBySlug.listingId)
                }     
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setInitialLoad(true);
            })
        }
    },[initialLoad]);


    useEffect(()=>{
        if(listingId){
            getTemplates(listingId).then(res=>{
                if(res.data.getTemplateForListing ){
                    let templates = []
                    res.data.getTemplateForListing?.map(t=>{
                        if(t.code){
                            templates.push({...t, code:JSON.parse(t.code)})
                        }
                    })
                    setWorkflowTemplate(templates)
                }
            })
        }
    },[listingId])




    return<>
    {!initialLoad && !listing && !listingId && !workflowTemplate && <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100vh',width:'100vw'}}>
        <Loader></Loader>
        </div>}
    {initialLoad && listing  && listingId && workflowTemplate &&  <ListingView marketplace={marketplace} listing={listing} listingId={listingId} mode={mode} orgId={marketplace.organizationId} templates={workflowTemplate}>
        </ListingView>}

    </>
}

