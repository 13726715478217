import React, { useEffect, useState,useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import useOutsideAlerter from '../../hooks/useOutsideClickAlert';


function DownIcon({ color }) {

    return <svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="6.414" viewBox="0 0 10.828 6.414">
        <path id="Path_5_Copy" dataname="Path 5 Copy" d="M0,0,4,4,8,0" transform="translate(1.414 1.414)" fill="none" stroke={color || "#595b85"} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
}



const useStyles = makeStyles((theme) => ({
    root: {
        height: '4.1rem',
        width: '100%',
        border: '1px solid #DCE4F8',
        borderRadius: '2px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        position: 'relative'
    },
    input: {
        flex: 1,
        display: 'flex',
        height: '100%',
        color: '#313E6F',
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '-0.013rem',
        lineHeight: '16px',
        justifyContent: 'flex-end',
        paddingRight: '1rem',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
    },
  
    dropIcon: {
        position:'absolute',
        top:0,
        right:0,
        height: '100%',
        width: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight:'1rem'
    },
    dropIconRotate: {
        transform: 'rotate(180deg)'
    },
    tray: {
        position: 'absolute',
        top: '102%',
        left: 0,
        width: '100%',
        display: 'flex',
        borderRadius: '2px',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        paddingTop:'1rem',
        paddingBottom:'1rem',
      //  boxShadow: '0 4px 10px 0 rgba(76,141,235,0.1)',
        boxShadow: '0 14px 43px 0 #2D2D68',
        zIndex: '5'
    },
    item: {
        height: '4.1rem',
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '-0.013rem',
        lineHeight: '16px',
        textAlign: 'right',
        paddingRight: '1rem',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingLeft: '1rem',
        paddingTop: '2rem'
    },
    renderedItem:{
        flex:1
    },
    itemWrap:{
        '&:hover':{
            backgroundColor:'rgba(76,141,235,0.1)'
        }
    }
}))

export default function Select({ onChange, items, className, value, itemRender, valueCondition, zIndex,inputClass,enableSearch,filter,placeholder }) {

    const classes = useStyles();

    const [showTray, setShowTray] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const trayRef = useRef(null);

    useOutsideAlerter(trayRef,()=>{
        setShowTray(false);
    })

    let selectedValue = null;
    let selectedIndex=-1;



    if (value && items) {
        selectedValue = items.filter(i => { return valueCondition(i, value) })[0];
        selectedIndex = items.findIndex(i => { return valueCondition(i, value) });
    }

    let defaultInputClass = inputClass || classes.input;

    let filteredItems=[];
    let selectedValueRender =null;
    if(selectedValue){
        selectedValueRender = itemRender(selectedValue,selectedIndex,true);
    }
     

    if(items){
        if(filter && searchInput){
            filteredItems=  items.filter((item)=>filter(item,searchInput)).map((item, idx) => {
                return <div style={{ cursor: 'pointer' }} className={classes.itemWrap} onClick={() => {
                    onChange && onChange(item, idx);
                    setShowTray(!showTray)
                }}>{itemRender(item,idx,false)}</div>
            })
        }else{
            filteredItems= items.map((item, idx) => {
                return <div style={{ cursor: 'pointer' }} className={classes.itemWrap} onClick={() => {
                    onChange && onChange(item, idx);
                    setShowTray(!showTray)
                }}>{itemRender(item,idx,false)}</div>
            })
        }
    }



    return (
        <>
            <div className={className||classes.root} ref={trayRef} >
                <div style={{ flex: 1, height: '100%',display:'flex' }} onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();
                        setShowTray(!showTray) 
                        }}>
                    {/* {selectedValue && itemRender(selectedValue)} */}
                    {!showTray  &&selectedValue && <div className={classes.renderedItem}>
                        {selectedValueRender}
                        </div>}
                    {!selectedValue && !showTray && placeholder && placeholder()}
                    {enableSearch && showTray && <input className={defaultInputClass} 
                        onChange={e=>setSearchInput(e.target.value)} 
                        value={searchInput} style={{ border: 'none' }} onClick={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                          //  setShowTray(!showTray);
                    }}></input>}
                
                <span className={classes.dropIcon + " " + (showTray && classes.dropIconRotate)} onClick={(e) => {
                       e.preventDefault();
                       e.stopPropagation();
                        setShowTray(!showTray) }}><DownIcon></DownIcon>
                </span>
                </div>
                
                {showTray && <div className={classes.tray} style={{ zIndex: (zIndex || undefined) }}>
                    {filteredItems}
                </div>}
            </div>
        </>
    )
}