import React, { useState, useEffect, useRef } from 'react';
import { gql, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';


import useStyle from "./styles";
import Button from "../Button";
import useOutsideClickAlert from "../../hooks/useOutsideClickAlert";
import service from "../../lib/apifuseGraphQL";


export default function RequestItegrationForm({ onSend, onCancel, orgId, requestedIntegration }) {

    const classes = useStyle();

    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [request, setRequest] = useState({
        email: '',
        company: '',
        reason: '',
        requestedIntegration: requestedIntegration || ''
    });

    const [highlight, setHighlight] = useState({
        email: false,
        company: false,
        reason: false,
        requestedIntegration: false
    });

    const requestIntegrationFormRef = useRef(null);

    useOutsideClickAlert(requestIntegrationFormRef, () => {
        onCancel && onCancel();
    })



    async function sendRequestIntegration() {

        setLoading(true)
        try {
            const createLeadRq = gql`
            mutation createLead{
                createLead(
                    lead:{orgId:"${orgId}",
                    email:"${request.email}",
                    company:"${request.company}",
                    reason:"${request.reason}",
                    requestedIntegration:"${request.requestedIntegration}"}
                    ){
                       leadId,
                        orgId
                }
            }
        `;

            const rs = await service.doMutate(createLeadRq);
            setShowSuccess(true);
        } catch (err) {
            console.log(err);
        }
        setLoading(false)
    }


    return <div className={classes.overlay}>
        {showSuccess && <div className={classes.requestFromBoxSuccess} ref={requestIntegrationFormRef}>
            <div className={classes.successBanner}>
                <span>Request sent successfully !!!</span>
            </div>
            <div className={classes.successFooter}>
                <Button type="secondary" onClick={() => {
                    onCancel && onCancel();
                }} text="Go back"></Button>
            </div>
        </div>}
        {!showSuccess && <div className={classes.requestFromBox} ref={requestIntegrationFormRef}>
            <div className={classes.requestFromHeader}><span>Request an integration</span></div>
            <div className={classes.requestFromSubTitle}><span>Fill in the form below to request a new integration</span></div>
            <div className={classes.requestFromInputBox}>
                <label className={classes.requestFromInputLabel}>What's the name of the integration you are looking for ? </label>
                <input className={classes.requestFromInput} value={request.requestedIntegration} style={{ borderColor: (highlight.requestedIntegration ? 'red' : undefined) }} onChange={(e) => {
                    setRequest({ ...request, requestedIntegration: e.target.value })
                }} required></input>
            </div>
            <div className={classes.requestFromInputBox}>
                <label className={classes.requestFromInputLabel}>Your email </label>
                <input className={classes.requestFromInput} value={request.email} style={{ borderColor: (highlight.email ? 'red' : undefined) }} onChange={(e) => {
                    setRequest({ ...request, email: e.target.value })
                }}></input>
            </div>
            <div className={classes.requestFromInputBox}>
                <label className={classes.requestFromInputLabel}>Your company </label>
                <input className={classes.requestFromInput} value={request.company} style={{ borderColor: (highlight.company ? 'red' : undefined) }} onChange={(e) => {
                    setRequest({ ...request, company: e.target.value })
                }}></input>
            </div>
            <div className={classes.requestFromInputBox}>
                <label className={classes.requestFromInputLabel}>Why is this integration important for you? What problems would it solve?  </label>
                <textarea className={classes.requestFromInput + " " + classes.requestFromInputTextArea} style={{ borderColor: (highlight.reason ? 'red' : undefined) }} value={request.reason} onChange={(e) => {
                    setRequest({ ...request, reason: e.target.value })
                }}></textarea>
            </div>
            <div className={classes.requestFromFooter}>
                <Button type={'secondary'} text="Cancel" className={classes.cancelBtn} onClick={() => {
                    onCancel && onCancel();
                }}></Button>
                <Button type={'green'} text="Send Request" className={classes.sendRequestBtn} loading={loading} onClick={() => {

                    if (request.email && request.company && request.reason && request.requestedIntegration) {
                        sendRequestIntegration();
                    } else {
                        setHighlight({ company: (request.company ? false : true), reason: (request.reason ? false : true), requestedIntegration: (request.requestedIntegration ? false : true), email: (request.email ? false : true) })
                    }
                }}></Button>
            </div>
        </div>
        }

    </div>
}
