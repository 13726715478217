import React from 'react';

export default React.forwardRef(({ children, zIndex, style }, ref) => {


    const zIndexOverlay = zIndex || 1000;
    const zIndexChild = zIndexOverlay + 1;

    let defaultStyle = {};

    if (style) {
        defaultStyle = style;
    }

    return (
        <>
            <style jsx>
                {`
                      .overlay{
                        width:100vw;
                        height:100vh;
                        opacity: 0.9;
                        background-color: #526E98;
                        display:flex;
                        align-items:center;
                        justify-content:center;
                        position:fixed;
                        top:0;
                        left:0;
                        z-index:${zIndexOverlay};
                        overflow:auto;
                    }
                    @media only screen and (min-height: 800px) {
                        .overlay{
                            height:100%;
                        }
                    }
                    @media only screen and (min-width: 800px) {
                        .overlay{
                            width:100%;
                        }
                    }
                  
                    .overlay-child{
                        opacity: 1;
                        display:flex;
                        align-items:center;
                        justify-content:center;
                        position:fixed;
                        top:10rem;
                        left:50%;
                        z-index:${zIndexChild};
                        transform:translate(-50%);
                        overflow:auto;
                    }
                    `}
            </style>
            <div className="overlay-child">{children}</div>
            <div className="overlay" style={{ ...defaultStyle }} ref={ref}>
            </div>
        </>
    );
})