import styled from "styled-components";



const Loader = styled.div`
    z-index: 1;
    display:${props => props.hide?'none':'block'};
    width: ${props => props.size||'19px'};
    height: ${props => props.size||'19px'};
    
    border: ${props => props.width||'3px'} solid #f3f3f3;
    border-radius: 50%;
    border-top: ${props => props.width||'3px'} solid ${props => props.color||'#4166EA'};
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }
  
  @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  
  @keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }
`

export default Loader;