import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Loader from "../Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '1.4rem',
        letterSpacing: '-0.014rem',
        lineHeight: '1.8rem',
        paddingTop: '0.7rem',
        paddingBottom: '0.7rem',
        paddingRight: '2.5rem',
        paddingLeft: '2.5rem',
        backgroundColor: "#4166EA",
        opacity: "1",
        cursor: 'pointer',
    },
    secondary: {
        color: '#595A86',
        backgroundColor: "#E3E8F9",
        cursor: 'pointer',
    },
    header: {
        background: 'linear-gradient(224.43deg, #4166EA 0%, #3D42BF 100%)',
        paddingTop: '1.3rem',
        paddingBottom: '1.3rem',
        paddingRight: '2.5rem',
        paddingLeft: '2.5rem',
    },
    green: {
        color: '#FFFFFF',
        fontFamily: "Mulish",
        fontWeight: '800',
        fontSize: '1.4rem',
        letterSpacing: '-0.014rem',
        lineHeight: '1.8rem',
        cursor: 'pointer',
        backgroundColor: '#20BB86',
    },
    text: {
        opacity: "1",
    }
}))

function HEXtoHSL(hex) {
    hex = hex.replace(/#/g, '');
    if (hex.length === 3) {
        hex = hex.split('').map(function (hex) {
            return hex + hex;
        }).join('');
    }
    var result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(hex);
    if (!result) {
        return null;
    }
    var r = parseInt(result[1], 16)/255;
    var g = parseInt(result[2], 16)/255;
    var b = parseInt(result[3], 16)/255;
    //r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;
    if (max == min) {
        h = s = 0;
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
        case g:
            h = (b - r) / d + 2;
            break;
        case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
    }
    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    return {
        h: h,
        s: s,
        l: l
    };
}

export default function Button({ text, type, background, textColor, onClick, className, style, loading, disabled, disabledClass, }) {
    const classes = useStyles();

    let defaultClasses = classes.root;

    let defaultStyle = {};

    if (style) {
        defaultStyle = { ...style }
    }


    if (type == 'secondary') {
        defaultClasses += " " + classes.secondary;
        if(background) {
            var val = HEXtoHSL(background);
            background = 'hsl('+val.h+' '+val.s+'% '+val.l+'% / 0.2)';
        }
        
    }

    if (type == 'header') {
        defaultClasses += " " + classes.header;
    }

    if (type == 'green') {
        defaultClasses += " " + classes.green;
    }




    return <>
        <div className={defaultClasses + " " + ((disabled ? disabledClass : className) || "")} style={{ ...defaultStyle, background, color: textColor, cursor: (disabled ? 'not-allowed' : 'pointer') }} onClick={() => {
            if (!disabled) {
                onClick && onClick();
            }
        }}>
            {!loading && <span className={text}>{text}</span>}
            {loading && <Loader style={{ color: (type === "" ? "#595A86" : "#FFFFFF") }}></Loader>}
        </div>
    </>
}