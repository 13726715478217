import React, { useState, useEffect, useRef } from 'react';
import useStyle from "./styles";

import useGetCustomerAuthContext from "../../hooks/useGetCustomerAuthContext";
import useOutsideClickAlert from "../../hooks/useOutsideClickAlert";

import Overlay from "../Overlay";
import CloseIcon from '@material-ui/icons/Close';


export default function TierNotificationPopup({notificationConfig={},onClose,uiConfig={}}){

    console.log(notificationConfig)

    const classes = useStyle({font:notificationConfig?.font});


    const boxRef = useRef(null);

    const customerAuthCtx = useGetCustomerAuthContext();

    useOutsideClickAlert(boxRef,()=>{
        onClose && onClose();
    })

    async function sendRequestIntegration() {

      onClose && onClose();
    }

    return <>
        <Overlay>
            <div className={classes.root} ref={boxRef}>
                <div className={classes.closeBtn} onClick={()=>{
                   onClose && onClose();
                }}>
                    <CloseIcon className={classes.closeBtnIcon}></CloseIcon>
                </div>
                <div className={classes.header}>
                <span>{notificationConfig.title||'Please upgrade to use this feature.'}</span>
                </div>
                <div className={classes.info}>
                <span>{notificationConfig.infoText||''}</span>
                </div>
                <div className={classes.submit}>
                    <span className={classes.installBtn} onClick={()=>{
                        sendRequestIntegration()
                    }}>
                         {notificationConfig.actionCta||'Ok'}
                    </span>
                </div>
            </div>
        </Overlay>
    </>
}

