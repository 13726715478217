import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NewConnection from "./pages/Connection/NewConnection"
import NewConnectionLanding from "./pages/Connection/NewConnectionLandingPage"
import PreAuthentication from "./pages/Connection/PreAuthentication";

ReactDOM.render(
  <React.StrictMode>
     <Router forceRefresh={true}>

      <Switch>
        <Route path="/connection/new/:appId/:state">
            <NewConnection></NewConnection>
          </Route>
          <Route path="/connection/temp/new/:connectionId">
            <NewConnectionLanding></NewConnectionLanding>
          </Route>
          <Route path="/connection/preauth/:appId/:state">
            <PreAuthentication></PreAuthentication>
          </Route>

          <App />
      </Switch>
     </Router>
  
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
