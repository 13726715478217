import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { chunk } from "lodash";

const useStyles = makeStyles((theme) => ({
    integrationItemRow: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '2rem'
    }
}))

export default function Grid({ list, renderFunction, rowStyle }) {

    const classes = useStyles();

    const maxWidth500 = useMediaQuery('(max-width:600px)');
    const minWidth600 = useMediaQuery('(min-width:600px)');
    const minWidth1000 = useMediaQuery('(min-width:1000px)');
    const minWidth1200 = useMediaQuery('(min-width:1200px)');
    const minWidth1600 = useMediaQuery('(min-width:1600px)');

    let defaultRowStyle = {};
    if (rowStyle) {
        defaultRowStyle = { ...rowStyle };
    }


    function getResponsiveTiles(arr) {
        let itemPerRow = 4;
        if (maxWidth500) {
            itemPerRow = 1;
        } else if (minWidth1600) {
            itemPerRow = 4
        } else if (minWidth1200) {
            itemPerRow = 4
        } else if (minWidth1000) {
            itemPerRow = 3
        } else if (minWidth600) {
            itemPerRow = 2
        }

        let arrChunked = chunk(arr, itemPerRow);
        let idx = 0;
        return arrChunked.map((row,index) => {
            let columnrender = row.map(column => {
                idx++;
                return renderFunction(column, idx);
                // <IntegrationTile workflowId={column.workflow_id} name={column.workflow_name} updatedAt={column.updated_at} onEdit={(workflow_id) => {
                //     router.push("/builder/" + column.workflow_id)
                // }} onDelete={() => {
                //     deleteWorkflow(column.workflow_id);
                // }}></IntegrationTile>
            })

            return <>
                <div key={index} className={classes.integrationItemRow} style={{ ...defaultRowStyle }}>
                    {columnrender}
                </div>
            </>
        })
    }


    const arrRender = getResponsiveTiles(list);

    return (
        <>
            {arrRender}
        </>)

}